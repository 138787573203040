import { Input, InputRef } from 'antd';
import cx from 'classnames';
import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from 'react';

import { ReactComponent as EyeHide } from '@/assets/svg/icons/icon-eye-hide.svg';
import { ReactComponent as EyeVisible } from '@/assets/svg/icons/icon-eye-visible.svg';

import styles from './style.module.less';

type InputProps = {
  id?: string;
  type: string;
  label?: string;
  name?: string;
  className?: string;
  containerClassName?: string;
  dataTestId?: string;
  value?: string | number | readonly string[];
  style?: React.CSSProperties;
  enableTogglePassword?: boolean;
  onChange?: React.ChangeEventHandler;
  onBlur?: React.FocusEventHandler;
  onKeyUp?: React.KeyboardEventHandler;
  onKeyDown?: React.KeyboardEventHandler;
  error?: string;
  disabled?: boolean;
  placeholder?: string;
  maxLength?: number;
  autoFocus?: boolean;
  step?: string | number;
  pattern?: string;
  required?: boolean;
  inputMode?:
    | 'search'
    | 'text'
    | 'email'
    | 'tel'
    | 'url'
    | 'none'
    | 'numeric'
    | 'decimal';
  min?: string | number;
  max?: string | number;
  autoFocusDeps?: any[];
};

const CustomInput = forwardRef<InputRef, InputProps>(function CustomInput(
  {
    id,
    dataTestId,
    type,
    label,
    name,
    value,
    style,
    enableTogglePassword = false,
    onChange,
    onBlur,
    onKeyUp,
    onKeyDown,
    step,
    inputMode,
    pattern,
    className,
    containerClassName,
    min,
    max,
    error,
    disabled,
    placeholder,
    maxLength,
    autoFocus,
    required,
    autoFocusDeps = [],
  }: InputProps,
  forwardedRef,
) {
  const [isInputProtected, setInputProtected] = useState(enableTogglePassword);

  const ref = useRef<InputRef>(null);

  useImperativeHandle(forwardedRef, () => ref.current!);

  useEffect(() => {
    if (autoFocus) {
      // This seems like a common solution to this issue:
      // https://blog.maisie.ink/react-ref-autofocus/
      setTimeout(() => {
        ref?.current?.focus();
      }, 50);
    }
  }, [autoFocus, ...autoFocusDeps]);

  return (
    <div
      className={cx(
        styles['custom-input'],
        containerClassName,
        `${error && styles['custom-input__error']}`,
      )}
    >
      <Input
        required={required}
        ref={ref}
        autoFocus={autoFocus}
        id={id}
        value={value}
        data-test-id={dataTestId ?? ''}
        type={isInputProtected ? type : 'text'}
        name={name}
        style={style}
        className={className}
        autoComplete="off"
        onChange={onChange}
        onBlur={onBlur}
        step={step}
        inputMode={inputMode}
        pattern={pattern}
        min={min}
        max={max}
        onKeyUp={onKeyUp}
        onKeyDown={onKeyDown}
        placeholder={placeholder}
        disabled={disabled}
        maxLength={maxLength}
      />
      {label && <label htmlFor={id}>{label}</label>}
      {enableTogglePassword && (
        <button
          tabIndex={-1}
          onClick={() => setInputProtected(!isInputProtected)}
          className={styles['custom-input_wrapper']}
          type="button"
        >
          {isInputProtected ? (
            <EyeHide
              className={cx('icon', styles['custom-input_wrapper--eye'])}
            />
          ) : (
            <EyeVisible
              className={cx('icon', styles['custom-input_wrapper--eye'])}
            />
          )}
        </button>
      )}
      {error && (
        <div className="flex items-center">
          <p className="text-xs mt-0.5 text-red-500">{error}</p>
        </div>
      )}
    </div>
  );
});

export default CustomInput;
