import { Fund, MarketCapBase } from '@aminsights/contract';
import { EMPTY_DATA_POINT, MarketCapLabel } from '@aminsights/shared';
import { FC } from 'react';

import { DataTable } from '@/components';
import { COMPARE_TOOL_DECIMALS } from '@/constants';

import { getFundTitle } from '../utils/shared';
import { extractFundTitle } from './extractFundTitle';

interface CompareMarketCapProps {
  data: {
    types: MarketCapLabel[] | undefined;
    marketCap: (MarketCapBase[] | undefined)[];
  };
  loading?: boolean;
  funds?: Fund[];
}

const CompareMarketCap: FC<CompareMarketCapProps> = ({
  data,
  funds,
  loading = true,
}) => {
  return (
    <DataTable
      loading={loading}
      enableHeaderWrap
      uniqueKey="marketCap"
      className="md:table-fixed"
      columns={[
        {
          title: '',
          render: ({ types }) => {
            return <div className="font-bold">{types}</div>;
          },
          renderType: 'text',
          isColumnFixed: true,
        },
        ...(funds || []).map((f, idx) => ({
          title: (
            <div className="min-w-17">
              {getFundTitle({
                isin: f.shareClassDetails.isin,
                fallBackTitle: `Fund ${idx + 1}`,
                loading,
                fundName: extractFundTitle(f),
              })}
            </div>
          ),
          render: ({ types }: { types: MarketCapLabel }) => {
            const sector = data?.marketCap?.[idx]?.find(i => i.type === types);
            const value = sector?.value?.toFixed(COMPARE_TOOL_DECIMALS);
            return <div>{value ? `${value}%` : EMPTY_DATA_POINT}</div>;
          },
          renderType: 'number' as const,
          idSubText: `fund-${idx + 1}`,
        })),
      ]}
      data={data.types?.map(type => ({ types: type }))}
    />
  );
};

export default CompareMarketCap;
