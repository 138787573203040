import { PortfolioFundForUpdate } from '@aminsights/contract';
import cx from 'classnames';
import React from 'react';

import { ReactComponent as UploadIcon } from '@/assets/svg/icons/icon-upload-funds.svg';
import { UploadModalInfoBase } from '@/pages/app/Watchlist/Buckets/components/ImportFunds/types';

import CsvFundsDropzone from '../CsvFundsDropzone';

type Props = {
  onFundsUploadFailure: (
    failedStatus: UploadModalInfoBase,
    filenames: string[],
  ) => void;
  onFundsUploadSuccess: (
    portfolioFunds: PortfolioFundForUpdate[],
    filenames: string[],
    dates: string[],
  ) => void;
};

const UploadFunds: React.FCWithChild<Props> = ({
  onFundsUploadFailure,
  onFundsUploadSuccess,
}) => {
  return (
    <div
      className={cx(
        'flex flex-col justify-center w-full gap-y-4',
        'bg-white lg:rounded-lg',
      )}
    >
      <div className="flex flex-col gap-y-2">
        <h2 className="text-sm font-bold text-darkest">Import Portfolio</h2>
        <p className="text-neutral-700 font-normal text-sm">
          Add 1 portfolio at a time by uploading a csv file with columns for the
          ISINs & dates, along with weights. View the sample template to ensure
          you are using the correct formats.
        </p>
      </div>

      <CsvFundsDropzone
        onFundsUploadFailure={onFundsUploadFailure}
        onFundsUploadSuccess={onFundsUploadSuccess}
      >
        <div
          className={cx(
            'flex items-center flex-col text-center gap-y-2 py-12',
            'cursor-pointer relative h-full rounded-lg max-md:px-2',
          )}
        >
          <div className="flex gap-x-2 justify-center items-center">
            <UploadIcon />
            <p className="text-primary cursor-pointer underline">
              Browse files
            </p>
          </div>
          <p className="text-neutral-700 font-normal text-sm">
            or drag and drop files here
          </p>
        </div>
      </CsvFundsDropzone>

      <a
        className="underline text-primary text-center"
        href={'/assets/file/sample_portfolio_template.csv'}
        download
      >
        View sample template
      </a>
    </div>
  );
};

export default UploadFunds;
