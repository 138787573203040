// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".K2r_gsn2uufM5yLMQvuZ .ant-table-thead > tr > th,\n.K2r_gsn2uufM5yLMQvuZ .ant-table-tbody > tr > td,\n.K2r_gsn2uufM5yLMQvuZ .ant-table tfoot > tr > th,\n.K2r_gsn2uufM5yLMQvuZ .ant-table tfoot > tr > td {\n  padding: 12px 16px;\n}\n.K2r_gsn2uufM5yLMQvuZ .ant-table table {\n  border: 1px solid #eaeaea;\n}\n", "",{"version":3,"sources":["webpack://./src/partials/Modal/ShareClassModal/style.module.less"],"names":[],"mappings":"AAEA;;;;EAMM,kBAAA;AAHN;AAHA;EASM,yBAAA;AAHN","sourcesContent":["@import '@/less/partials/_variables.less';\n\n.share-class-table {\n  :global {\n    .ant-table-thead > tr > th,\n    .ant-table-tbody > tr > td,\n    .ant-table tfoot > tr > th,\n    .ant-table tfoot > tr > td {\n      padding: 12px 16px;\n    }\n    .ant-table table {\n      border: 1px solid @midlight;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"share-class-table": "K2r_gsn2uufM5yLMQvuZ"
};
export default ___CSS_LOADER_EXPORT___;
