import { Bucket, Fund } from '@aminsights/contract';
import {
  COLOR_MAXIMUM_FEATURED_TABLE_CELL,
  COLOR_MAXIMUM_TABLE_CELL,
  COLOR_MINIMUM_FEATURED_TABLE_CELL,
  COLOR_MINIMUM_TABLE_CELL,
  GlobalSectorFixedIncome,
  SectorWithExtremums,
} from '@aminsights/shared';
import { ReactNode } from 'react';

import { IDataTableColumns } from '@/components/Table/DataTable';
import { roundOrEmpty } from '@/utils/roundOrEmpty';

const generateWatchlistSectorFIColumn = (
  title: string,
  fullName: keyof typeof GlobalSectorFixedIncome,
  sectorsWithExtremums?: SectorWithExtremums[],
  currentBucket?: Bucket | undefined,
): IDataTableColumns => {
  const globalSector: GlobalSectorFixedIncome =
    GlobalSectorFixedIncome[fullName];
  return {
    title: <span className="whitespace-normal text-start">{title}</span>,
    dataTestIdSuffix: title,
    sortKey: `sectorsFixedIncome.${globalSector}`,
    tooltipText: fullName,
    backgroundColor: (item: Fund) => {
      const sectorType = globalSector;
      const sectorWithExtremumsForType = sectorsWithExtremums?.find(
        s => s.type === sectorType,
      );

      let backgroundColor = '';
      const isFeaturedRow = currentBucket?.funds.find(
        f => item.shareClassDetails.isin === f?.isin,
      )?.isFeatured;
      if (sectorWithExtremumsForType) {
        if (
          sectorWithExtremumsForType.isinsOfMaximum.some(
            i => i === item.shareClassDetails.isin,
          )
        ) {
          backgroundColor = isFeaturedRow
            ? COLOR_MAXIMUM_FEATURED_TABLE_CELL
            : COLOR_MAXIMUM_TABLE_CELL;
        }
        if (
          sectorWithExtremumsForType.isinsOfMinimum.some(
            i => i === item.shareClassDetails.isin,
          )
        ) {
          backgroundColor = isFeaturedRow
            ? COLOR_MINIMUM_FEATURED_TABLE_CELL
            : COLOR_MINIMUM_TABLE_CELL;
        }
      }
      return backgroundColor;
    },
    render: (item: Fund): ReactNode => {
      const sectorType = globalSector;
      const sectorValue = item.sectorsFixedIncome?.find(
        s => s.type === sectorType,
      )?.value;

      return <span>{roundOrEmpty(sectorValue, 1, '%')}</span>;
    },
    renderType: 'number',
    width: 140,
  };
};

export default generateWatchlistSectorFIColumn;
