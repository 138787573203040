import { Checkbox } from '@/components';

import {
  checkboxSelectAllData,
  filterDatesCall,
  SELECT_ALL,
} from '../../../hooks/esgSubState';
import useExploreFilters from '../../../hooks/useExploreFilters';
import styles from '../../style.module.less';

type CheckboxValueType = any;

const EsgUnpriFilter = () => {
  const { tentativeFilters, getMutableFilters, updateTentativeFilters } =
    useExploreFilters();
  const esgFilters = tentativeFilters.esgFilters;
  const filterDates = filterDatesCall();
  return (
    <div data-test-id={'ESGLabelUNPRI'}>
      <p>Signed up in:</p>
      <div className={styles['column-checkbox']}>
        <div className="ant-checkbox-group flex">
          <Checkbox
            data={checkboxSelectAllData}
            direction="row"
            onChange={(list: Array<CheckboxValueType>) => {
              const filters = getMutableFilters();
              if (list.includes(SELECT_ALL)) {
                filters.esgFilters = {
                  ...filters.esgFilters,
                  datesUNPRI: filterDates.options.map(option => option.value),
                };
                updateTentativeFilters(filters);
              } else {
                filters.esgFilters = {
                  ...filters.esgFilters,
                  datesUNPRI: undefined,
                };
                updateTentativeFilters(filters);
              }
            }}
            value={
              (esgFilters?.datesUNPRI ?? []).length >=
              filterDates.options.length
                ? [SELECT_ALL]
                : []
            }
          />
          <Checkbox
            data={filterDates.options}
            direction="row"
            onChange={(checkedItems: string[]) => {
              const filters = getMutableFilters();
              filters.esgFilters = {
                ...filters.esgFilters,
                datesUNPRI: checkedItems.length > 0 ? checkedItems : undefined,
              };
              updateTentativeFilters(filters);
            }}
            value={esgFilters?.datesUNPRI}
          />
        </div>
      </div>
    </div>
  );
};

export default EsgUnpriFilter;
