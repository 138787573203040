import useScreenWidth, { screenBreakpoints } from '@/hooks/screenWidth';

export enum ScreenWidthEnum {
  MinSm = 'min-sm',
  MaxSm = 'max-sm',
  MinMd = 'min-md',
  MaxMd = 'max-md',
  MinLg = 'min-lg',
  MaxLg = 'max-lg',
  MinXl = 'min-xl',
  MaxXl = 'max-xl',
  Min2xl = 'min-2xl',
  Max2xl = 'max-2xl',
}

const getScreenWidthMode = () => {
  const { currentWidth } = useScreenWidth();

  const screenMode: Record<ScreenWidthEnum, boolean> = {
    [ScreenWidthEnum.MinSm]: currentWidth >= screenBreakpoints.sm,
    [ScreenWidthEnum.MaxSm]: currentWidth < screenBreakpoints.sm,
    [ScreenWidthEnum.MinMd]: currentWidth >= screenBreakpoints.md,
    [ScreenWidthEnum.MaxMd]: currentWidth < screenBreakpoints.md,
    [ScreenWidthEnum.MinLg]: currentWidth >= screenBreakpoints.lg,
    [ScreenWidthEnum.MaxLg]: currentWidth < screenBreakpoints.lg,
    [ScreenWidthEnum.MinXl]: currentWidth >= screenBreakpoints.xl,
    [ScreenWidthEnum.MaxXl]: currentWidth < screenBreakpoints.xl,
    [ScreenWidthEnum.Min2xl]: currentWidth >= screenBreakpoints['2xl'],
    [ScreenWidthEnum.Max2xl]: currentWidth < screenBreakpoints['2xl'],
  };

  return screenMode;
};

export default getScreenWidthMode;
