import { Slider } from 'antd';

import {
  moreInitialState,
  SLIDER_MAX_YIELD,
  yieldMarks,
} from '../../../hooks/moreFiltersSubState';
import { SLIDER_STEP } from '../../../hooks/moreFiltersSubState';
import useExploreFilters from '../../../hooks/useExploreFilters';
import { adjustMarksToAvoidDuplicates } from '../../../utils/adjustMarksToAvoidDuplicates';
import { getRangeSliderClassName } from '../../../utils/getRangeSliderClassName';
import style from '../../style.module.less';

export const MoreFiltersYield = () => {
  const { tentativeFilters, getMutableFilters, updateTentativeFilters } =
    useExploreFilters();

  const yieldValue =
    tentativeFilters.moreFilters?.yieldRange ?? moreInitialState.yieldRange;

  return (
    <div
      className={style['range-slider']}
      data-test-id={'moreFiltersSliderYield'}
    >
      <Slider
        className={
          style[
            getRangeSliderClassName(!!tentativeFilters.moreFilters?.yieldRange)
          ]
        }
        tooltip={{ open: false }}
        range
        step={SLIDER_STEP}
        max={SLIDER_MAX_YIELD}
        marks={yieldMarks}
        value={yieldValue}
        onChange={value => {
          const filters = getMutableFilters();
          filters.moreFilters = {
            ...filters.moreFilters,
            yieldRange: adjustMarksToAvoidDuplicates(value, yieldMarks),
          };
          updateTentativeFilters(filters);
        }}
      />
    </div>
  );
};

export default MoreFiltersYield;
