import _ from 'lodash';

const ignoreKeys = ['sortKey', 'sortDirection', 'page'];
/**
 * This function checks if an object or array is non-empty deeply within the object.
 */
export default function areFiltersApplied(obj: object | undefined): boolean {
  // If it's undefined, null, or empty object/array, it's considered empty.
  if (obj === undefined || obj === null) return false;
  if (Array.isArray(obj)) return obj.length > 0;
  if (typeof obj === 'object') {
    // If it's an object, check if there are any non-empty values, ignoring specified keys.
    return Object.entries(obj)
      .filter(([key]) => !ignoreKeys.includes(key))
      .some(([, value]) => areFiltersApplied(value));
  }
  // If it's a primitive value, consider it non-empty unless it's an empty string.
  return obj !== '';
}

/**
 * Deeply removes empty values from an object, excluding ignored keys.
 */
function removeEmpty(obj: any): any {
  if (Array.isArray(obj)) {
    const arr = obj
      .map(removeEmpty)
      .filter(val => val != null && !(_.isObject(val) && _.isEmpty(val)));
    return arr.length > 0 ? arr : undefined;
  } else if (_.isObject(obj)) {
    const result = _.transform(
      obj,
      (acc, value, key) => {
        if (ignoreKeys.includes(key)) return;
        const cleanedValue = removeEmpty(value);
        if (cleanedValue !== undefined) {
          acc[key] = cleanedValue;
        }
      },
      {} as any,
    );
    return _.isEmpty(result) ? undefined : result;
  } else {
    return obj;
  }
}

/**
 * Compares two objects after removing empty values and ignoring specified keys.
 * Treats empty arrays/objects and undefined/null values as equal.
 */
export function areFiltersEqual(obj1: any, obj2: any): boolean {
  const cleanObj1 = removeEmpty(obj1);
  const cleanObj2 = removeEmpty(obj2);
  return _.isEqual(cleanObj1, cleanObj2);
}
