import { ExploreModalProps } from '@aminsights/shared';
import React, { useEffect, useState } from 'react';

import ExploreFilterModal from '@/partials/ExploreFilterModal';

import { countBondsFilters } from '../hooks/bondsSubState';
import useExploreFilters from '../hooks/useExploreFilters';
import BondsFilterPanels from './BondsFilterPanels';

export const BondsFilterModal: React.FCWithChild<ExploreModalProps> = ({
  isOpen,
  onClose,
}) => {
  const {
    syncFilterByPath,
    getMutableFilters,
    updateTentativeFilters,
    tentativeFilters,
    filters,
  } = useExploreFilters();

  const handleOnApply = () => {
    syncFilterByPath('bondsFilters');
    onClose();
  };

  const [clearDisabled, setClearDisabled] = useState(true);
  const [applyDisabled, setApplyDisabled] = useState(true);

  useEffect(() => {
    setApplyDisabled(
      JSON.stringify(tentativeFilters.bondsFilters) ===
        JSON.stringify(filters.bondsFilters),
    );
  }, [tentativeFilters, filters]);

  useEffect(() => {
    setClearDisabled(
      applyDisabled && countBondsFilters(filters.bondsFilters) === 0,
    );
  });

  return (
    <ExploreFilterModal
      title="Bonds"
      isVisible={isOpen}
      isDisableApply={applyDisabled}
      isDisableClear={clearDisabled}
      toggleModal={onClose}
      onClear={() => {
        const f = getMutableFilters();
        f.bondsFilters = undefined;
        updateTentativeFilters(f);
      }}
      onApply={handleOnApply}
    >
      <BondsFilterPanels />
    </ExploreFilterModal>
  );
};
