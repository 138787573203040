import { ActivePassiveFilterType } from '@aminsights/shared';

import { Checkbox as MultiCheckBox } from '@/components/Checkbox';

import useExploreFilters from '../../../hooks/useExploreFilters';

export const MoreFiltersActivePassive = () => {
  const { tentativeFilters, getMutableFilters, updateTentativeFilters } =
    useExploreFilters();
  return (
    <div className="flex">
      <MultiCheckBox
        value={tentativeFilters.moreFilters?.filterByActiveOrPassive}
        data={[
          {
            label: 'Active',
            value: ActivePassiveFilterType.Active,
          },
          {
            label: 'Passive',
            value: ActivePassiveFilterType.Passive,
          },
        ]}
        onChange={value => {
          const filters = getMutableFilters();
          filters.moreFilters = filters.moreFilters ?? {};
          filters.moreFilters.filterByActiveOrPassive =
            value.length > 0 ? value : undefined;
          updateTentativeFilters(filters);
        }}
      />
    </div>
  );
};

export default MoreFiltersActivePassive;
