import { useMemo } from 'react';

import { Checkbox } from '@/components';

import { useExploreDefaultFiltersQuery } from '../../../context';
import useExploreFilters from '../../../hooks/useExploreFilters';
import style from '../../style.module.less';

const SfdrEsgFilter = () => {
  const { data: defaultfiltersOptions } = useExploreDefaultFiltersQuery();
  const { tentativeFilters, getMutableFilters, updateTentativeFilters } =
    useExploreFilters();
  const esgFilters = tentativeFilters.esgFilters;

  const sfdr = useMemo(
    () =>
      defaultfiltersOptions.sfdr?.map(i => ({
        label: i.label,
        value: i.label,
      })),
    [defaultfiltersOptions.sfdr],
  );

  return (
    <div data-test-id={'ESGLabelSFDR'} className={style['column-checkbox']}>
      <Checkbox
        data={sfdr}
        direction="row"
        onChange={(checkedItems: string[]) => {
          const filters = getMutableFilters();
          filters.esgFilters = {
            ...filters.esgFilters,
            checkedSFDR: checkedItems.length > 0 ? checkedItems : undefined,
          };
          updateTentativeFilters(filters);
        }}
        value={esgFilters?.checkedSFDR}
      />
    </div>
  );
};

export default SfdrEsgFilter;
