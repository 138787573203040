import {
  AutocompleteResponseSuggestionItem,
  AutocompleteResponseSuggestionItemWithPortfolio,
  PageQueryParametersSortDirectionEnum,
} from '@aminsights/contract';
import { buildFundDetailsPath, minifiedParamKeysMap } from '@aminsights/shared';
import { Dispatch, SetStateAction, useEffect, useMemo, useState } from 'react';
import { useHistory } from 'react-router-dom';

import useSearch, {
  UseSearchReturn,
} from '@/hooks/query-hooks/search-hooks/useSearch';
import { UseSearchWithPortfoliosReturn } from '@/hooks/query-hooks/search-hooks/useSearchWithPortfolio';
import useExploreFilters from '@/pages/app/Explore/hooks/useExploreFilters';

import { getMatchScore } from './utils';

const isResultsWithPortfolio = (
  array:
    | AutocompleteResponseSuggestionItem[]
    | AutocompleteResponseSuggestionItemWithPortfolio[],
): array is AutocompleteResponseSuggestionItemWithPortfolio[] => {
  if (
    (array as AutocompleteResponseSuggestionItemWithPortfolio[])?.[0]?.label
  ) {
    return true;
  }
  return false;
};

type QueryType = UseSearchReturn | UseSearchWithPortfoliosReturn;
type SuggestionReturn =
  | AutocompleteResponseSuggestionItem[]
  | AutocompleteResponseSuggestionItemWithPortfolio[];

export const useSubmitSearch = (term: string) => {
  const {
    getMutableFilters,
    updateTentativeFilters,
    syncFilters,
    tentativeFilters,
  } = useExploreFilters();
  const history = useHistory();
  const hasSearchQuery = term !== '' && term !== undefined;

  const handleSetSearchParameters = () => {
    const filters = getMutableFilters();
    filters.term = term;
    filters.page = 1;
    filters.sortKey = hasSearchQuery ? '_score' : 'fundSize.value';
    filters.sortDirection = !hasSearchQuery
      ? PageQueryParametersSortDirectionEnum.Desc
      : filters.sortDirection;
    updateTentativeFilters(filters);
    syncFilters(queryString => history.push(`/explore?${queryString}`));
  };
  const navigateToFundDetailsPage = () => {
    handleSetSearchParameters();
    history.push(buildFundDetailsPath(term));
  };
  const navigateToExplorePage = () => {
    const searchParams = new URLSearchParams();
    searchParams.set(minifiedParamKeysMap.term, term);
    searchParams.set(
      minifiedParamKeysMap.sortKey,
      hasSearchQuery ? '_score' : 'fundSize.value',
    );
    const sortDirection = !hasSearchQuery
      ? PageQueryParametersSortDirectionEnum.Desc
      : tentativeFilters.sortDirection;
    if (sortDirection) {
      searchParams.set(minifiedParamKeysMap.sortDirection, sortDirection);
    }

    handleSetSearchParameters();
  };

  return {
    handleSetSearchParameters,
    navigateToFundDetailsPage,
    navigateToExplorePage,
  };
};

interface UseSearchBoxReturn<T extends SuggestionReturn> {
  onSearchResults: (value?: string) => void;
  allSuggestions: T;
  onChange: (value: string) => void;
  searchValue: string;
  setSearchValue: Dispatch<SetStateAction<string>>;
  isLoading: boolean;
}
interface UseSearchBoxParams<T> {
  searchConfig?: T;
  onClose?: () => void;
  defaultValue?: string;
}

export const useSearchBox = <T extends QueryType = UseSearchReturn>(
  params?: UseSearchBoxParams<T>,
): UseSearchBoxReturn<T['autoCompleteQuery']['data']> => {
  const useSearchResults = useSearch();
  const { searchConfig, defaultValue, onClose } = params || {};
  const configToUse = searchConfig || useSearchResults;
  const { setFullTerm, autoCompleteQuery, fullTerm: term } = configToUse;
  const results = autoCompleteQuery?.data || [];
  const queryIsLoading = autoCompleteQuery?.isLoading || false;
  const queryIsFetching = autoCompleteQuery?.isFetching || false;

  const [isLoading, setIsLoading] = useState(true);

  const { navigateToExplorePage, navigateToFundDetailsPage } =
    useSubmitSearch(term);

  useEffect(() => {
    setFullTerm(defaultValue ?? '');
  }, [defaultValue]);

  const isTermInResults = () => {
    if (isResultsWithPortfolio(results)) {
      return results.find(r => r.id === term);
    }
    return results.find(r => r.shareClassDetails.isin === term);
  };

  const allSuggestions = useMemo(() => {
    if (isResultsWithPortfolio(results)) {
      return [...results].sort((a, b) => {
        return getMatchScore(b.label, term) - getMatchScore(a.label, term);
      });
    }
    return [...results].sort((a, b) => {
      return getMatchScore(b.fundName, term) - getMatchScore(a.fundName, term);
    });
  }, [configToUse.autoCompleteQuery.data]);

  useEffect(() => {
    if (queryIsLoading || queryIsFetching) {
      setIsLoading(true);
    } else {
      setIsLoading(false);
    }
  }, [queryIsLoading, queryIsFetching]);

  const onSearchResults = () => {
    if (isTermInResults()) {
      navigateToFundDetailsPage();
    } else {
      navigateToExplorePage();
    }

    onClose?.();
  };

  const handleSearch = (value: string) => {
    const regexPattern = /[^a-zA-Z0-9\s\-,.&"]/gi;
    const removeSpecialCharTerm = value.replace(regexPattern, '');
    setFullTerm(removeSpecialCharTerm);
  };

  return {
    onSearchResults,
    allSuggestions,
    onChange: handleSearch,
    searchValue: term,
    setSearchValue: setFullTerm,
    isLoading,
  };
};
