import {
  Investor,
  PageQueryParametersSortDirectionEnum,
} from '@aminsights/contract';
import { getFundOrInvestmentTrustTitle } from '@aminsights/shared';
import React, { useEffect, useState } from 'react';

import { DataTable } from '@/components';
import { useFundIsInvestmentTrust } from '@/hooks/query-hooks/fund-hooks/useFundByIsin';
import { useInvestorsByShareClasses } from '@/hooks/query-hooks/fund-hooks/useInvestorsByIsin';
import { sortArray } from '@/utils/array';

import { InvestorFundDetailsColumns } from '../columns';

interface InvestorsFundTableProps {
  isins: string[];
}
const InvestorsFundTable: React.FCWithChild<InvestorsFundTableProps> = ({
  isins,
}) => {
  const { data, isLoading } = useInvestorsByShareClasses(isins);
  const [investors, setInvestors] = useState<Investor[]>([]);
  const isInvestmentTrust = useFundIsInvestmentTrust();

  useEffect(() => {
    if (data && (data?.length ?? 0) !== 0) {
      setInvestors(
        sortArray(
          data ?? [],
          'allocation',
          PageQueryParametersSortDirectionEnum.Desc,
        ),
      );
    }
  }, [data]);

  const onSortHandler = (
    key: keyof Investor,
    direction: PageQueryParametersSortDirectionEnum,
  ) => {
    const sorted = sortArray(data ?? [], key, direction);
    setInvestors(sorted);
  };

  return (
    <div className="bg-white lg:rounded-lg">
      <DataTable
        uniqueKey="shareClassLink"
        loading={isLoading}
        columns={InvestorFundDetailsColumns(
          getFundOrInvestmentTrustTitle(!!isInvestmentTrust),
        )}
        onSort={(
          key: string,
          direction: PageQueryParametersSortDirectionEnum,
        ) => onSortHandler(key as keyof Investor, direction)}
        data={investors}
        noDataLabel="No investors available"
      />
    </div>
  );
};

export default InvestorsFundTable;
