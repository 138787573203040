import { FC, memo, ReactNode } from 'react';
import AutoSizer from 'react-virtualized-auto-sizer';
import { FixedSizeList } from 'react-window';

interface Props {
  items: Array<{ label: string; value: string }>;

  /**
   * It is better to memoize this function to prevent unnecessary re-renders.
   * It will still work if you don't, but I did because antd checkbox animation
   * would not work without it. Not a big deal.
   */
  renderItemByIndex: (index: number) => ReactNode;
}

/**
 * This component is used to render a list of items with a fixed height.
 * It is mainly used on Explore and would recommend to only use it for explore.
 *
 * Very important to know that this is full height and width of its containing element.
 */
const VirtualizedList: FC<Props> = memo(({ items, renderItemByIndex }) => {
  return (
    <AutoSizer>
      {({ height, width }) => {
        return (
          <FixedSizeList
            height={height}
            width={width}
            itemSize={38}
            itemCount={items.length}
          >
            {({ index, style: fixedSizeItemStyle }) => {
              const i = items[index];
              return (
                <div key={i.label} style={fixedSizeItemStyle}>
                  {renderItemByIndex(index)}
                </div>
              );
            }}
          </FixedSizeList>
        );
      }}
    </AutoSizer>
  );
});

export default VirtualizedList;
