import { useState } from 'react';

import ChartEmptyState from '@/partials/Charts/ChartEmptyState';
import RiskReturnPlotTitle from '@/partials/ScatterPlots/risk-return-scatter-plot/RiskReturnPlotTitle';

import RiskReturnV2WithPortfolio from '../../../../partials/ScatterPlots/risk-return-scatter-plot/RiskReturnV2WithPortfolio';
import useChartTrustFundsFromSearch from '../utils/useChartFundsFromSearch';
import { useChartingContext } from './ChartingContext';

const RiskReturnChartingToolPage = () => {
  const {
    isins,
    benchmark,
    setIsins,
    hasInvestmentTrust,
    portfolios,
    setPortfolios,
  } = useChartTrustFundsFromSearch();
  const [riskReturnLatestDate, setRiskReturnLatestDate] = useState<
    string | undefined
  >();

  const chartingCtx = useChartingContext();
  const legendData = chartingCtx.chartLegend;
  const chartColors = chartingCtx.chartColors;

  if (!(isins.length || portfolios.length)) {
    return (
      <ChartEmptyState
        title="No added funds/trusts/portfolios to chart"
        subtitle="Search funds/portfolios to chart"
        isDashboardPage={true}
      />
    );
  }

  return (
    <>
      <RiskReturnPlotTitle
        hasInvestmentTrust={hasInvestmentTrust}
        // showDropdown={true}
        riskReturnLatestDate={riskReturnLatestDate}
      />
      <RiskReturnV2WithPortfolio
        onRemoveFund={(id, isPortfolio) => {
          if (isPortfolio) {
            setPortfolios([id], true);
            const targetId =
              legendData.find(ld => ld.id?.includes(id))?.id || '';
            const targetColor = chartColors[targetId];
            targetColor && chartingCtx.setColorsRemoved(targetColor);
            return;
          }
          const targetId = legendData.find(ld => ld.isin === id)?.id || '';
          const targetColor = chartColors[targetId];
          targetColor && chartingCtx.setColorsRemoved(targetColor);
          setIsins([id], true);
        }}
        showDropdown={true}
        isins={isins}
        benchmarkId={benchmark}
        hasInvestmentTrust={hasInvestmentTrust}
        resetInvReturnFilterOnChange={false}
        portfolios={portfolios}
        chartColors={chartColors}
        riskReturnLatestDate={riskReturnLatestDate}
        updateRiskReturnLatestDate={setRiskReturnLatestDate}
      />
    </>
  );
};

export default RiskReturnChartingToolPage;
