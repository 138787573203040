import { CurrencyHedgedFilterType } from '@aminsights/shared';

import { Checkbox as MultiCheckBox } from '@/components/Checkbox';

import useExploreFilters from '../../../hooks/useExploreFilters';

const MoreFiltersCurrencyHedged = () => {
  const { tentativeFilters, getMutableFilters, updateTentativeFilters } =
    useExploreFilters();
  return (
    <MultiCheckBox
      data-test-id="currencyHedgedType"
      data={[
        {
          label: 'Fully Hedged',
          value: CurrencyHedgedFilterType.Fully_Hedged,
        },
        {
          label: 'Partial Hedged',
          value: CurrencyHedgedFilterType.Partially_Hedged,
        },
        {
          label: 'No Hedged',
          value: CurrencyHedgedFilterType.No_Hedging_Information,
        },
      ]}
      value={tentativeFilters.moreFilters?.currencyHedgedType}
      onChange={value => {
        const filters = getMutableFilters();
        filters.moreFilters = filters.moreFilters ?? {};
        filters.moreFilters.currencyHedgedType =
          value.length > 0 ? value : undefined;
        updateTentativeFilters(filters);
      }}
    />
  );
};

export default MoreFiltersCurrencyHedged;
