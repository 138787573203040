import { SignificantChangesItem } from '@aminsights/contract';
import { APP_ROUTE_SIGNIFICANT_CHANGES } from '@aminsights/shared';
import { Button } from 'antd';
import cx from 'classnames';
import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';

import { useFeatureSwitchContext } from '@/context/FeatureSwitchContext';
import useDashboardSignificantChanges from '@/hooks/query-hooks/dashboard-hooks/useDashboardSignificantChanges';
import useGetSignificantChangesThreshold from '@/hooks/query-hooks/significant-changes-hooks/useGetSignificantChangesThreshold';
import SignificantChangesEmptyState from '@/partials/SignificantChanges/SignificantChangesEmptyState';

import SignificantChangesTable from './table';

export enum ViewMode {
  mobile = 'mobile',
  desktop = 'desktop',
}

const EMPTY_SC_MESSAGE = 'There have been no changes on funds yet';

const SignificantChangesWidget: React.FCWithChild<{
  mode: ViewMode;
  onSignificantChangeClicked: (data: SignificantChangesItem) => void;
}> = ({ onSignificantChangeClicked }) => {
  const { data: significantChangesThreshold } =
    useGetSignificantChangesThreshold();
  const { state: featureSwitchState } = useFeatureSwitchContext();

  const { data, isLoading, refetch } = useDashboardSignificantChanges(
    featureSwitchState.isScFlowTreatmentEnabled,
  );
  const significantChangesState = data;

  //needs to be removed when we remove the feature switch
  useEffect(() => {
    refetch();
  }, [featureSwitchState.isScFlowTreatmentEnabled, refetch]);

  const handleOnRow = (id: string) => {
    const data = significantChangesState?.data?.find(sg => sg._id === id);
    if (data) {
      onSignificantChangeClicked(data);
    }
  };

  return (
    <div
      className="bg-white lg:rounded-lg mb-6"
      data-test-id="dashboardSignificantChangesWidget"
    >
      <div className="flex justify-between p-4">
        <h5 className="text-sm text-darkest font-bold">
          <span className="mr-5">Significant Changes</span>{' '}
        </h5>
        {!!significantChangesState?.data?.length && (
          <Button
            size="small"
            type="link"
            className="font-semibold text-[#1890ff] text-[#40a9ff]:hover p-0"
          >
            <Link to={APP_ROUTE_SIGNIFICANT_CHANGES}>View All</Link>
          </Button>
        )}
      </div>
      <div className={cx('[&_th>div]:max-lg:px-4', '[&_td>div]:max-lg:px-4')}>
        <SignificantChangesTable
          loading={isLoading}
          loaderSize={3}
          data={significantChangesState?.data ?? []}
          emptyState={
            <SignificantChangesEmptyState
              customMessage={EMPTY_SC_MESSAGE}
              noThresholds={!significantChangesThreshold}
              className="md:py-10"
            />
          }
          onRow={handleOnRow}
        />
      </div>
    </div>
  );
};

export default SignificantChangesWidget;
