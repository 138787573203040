import { BenchmarkSector, FundBroadAssetClass } from '@aminsights/contract';
import { EMPTY_DATA_POINT } from '@aminsights/shared';
import { FC } from 'react';

import { DataTable } from '@/components';
import LegendColor from '@/components/SectorEquityLegend/LegendColor';
import { IDataTableColumns } from '@/components/Table/DataTable';
import { COMPARE_TOOL_DECIMALS } from '@/constants';
import { CompareToolWithIndexProps } from '@/types/compare';
import getScreenWidthMode, {
  ScreenWidthEnum,
} from '@/utils/getScreenWidthMode';
import { sectorLegendList } from '@/utils/sectorLegendList';

import { SectorCompareData } from '../utils/CompareDataCalculations';
import { getFundTitle } from '../utils/shared';
import useGetColWidth from '../utils/useGetColWidth';
import { extractFundTitle } from './extractFundTitle';

type CompareSectorsProps = CompareToolWithIndexProps & {
  data?: SectorCompareData;
  broadAssetClass: FundBroadAssetClass;
  showPadiData: boolean;
  benchmarkSectors?: BenchmarkSector[];
};

const filterSectorsforEqOrPadi = (
  data: SectorCompareData | undefined,
  showPadiData: boolean,
) => {
  return (
    data?.data?.filter(d => {
      const hasEqSectorsFromLegend = sectorLegendList.some(i =>
        i.type.includes(d.type),
      );
      return showPadiData ? !hasEqSectorsFromLegend : hasEqSectorsFromLegend;
    }) ?? []
  );
};

const CompareSectors: FC<CompareSectorsProps> = ({
  funds,
  data,
  broadAssetClass,
  benchmarkSectors,
  showIndexColumn = false,
  loading = true,
  showPadiData = false,
}) => {
  // Manage mobile state here
  const { getColWidth } = useGetColWidth();
  const screenWidthMode = getScreenWidthMode();

  const isEquity = broadAssetClass === FundBroadAssetClass.Equity;
  const isFixedIncome = broadAssetClass === FundBroadAssetClass.FixedIncome;

  const tableData =
    (isFixedIncome
      ? data?.data
      : filterSectorsforEqOrPadi(data, showPadiData)) ?? [];

  return (
    <DataTable
      loading={loading}
      enableHeaderWrap
      uniqueKey="name"
      className="md:table-fixed"
      columns={[
        {
          title: 'Sector',
          render: ({ name }) => {
            const sectorName = data?.flatSectors.find(
              i => i.name === name,
            )?.name;
            const sectorType = data?.flatSectors.find(
              i => i.name === name,
            )?.type;
            return (
              <div className="flex items-center justify-center gap-x-2">
                {sectorType && isEquity && !showPadiData && (
                  <div className="pr-2">
                    <LegendColor sectorType={sectorType} />
                  </div>
                )}
                <div className="font-bold">{sectorName}</div>
              </div>
            );
          },
          renderType: 'text',
          idSubText: 'sector',
          width: screenWidthMode[ScreenWidthEnum.MinLg] ? 200 : 130,
          isColumnFixed: true,
        },
        ...(funds || []).map((f, idx) => ({
          title: (
            <div className="min-w-17">
              {getFundTitle({
                isin: f.shareClassDetails.isin,
                fallBackTitle: `Fund ${idx + 1}`,
                loading,
                fundName: extractFundTitle(f),
              })}
            </div>
          ),
          render: ({ name }: SectorCompareData['data'][number]) => {
            const sector = data?.sectors[idx].find(i => i.name === name);
            const value = sector?.value.toFixed(COMPARE_TOOL_DECIMALS);
            return <div>{value ? `${value}%` : EMPTY_DATA_POINT}</div>;
          },
          renderType: 'number' as const,
          idSubText: `fund-${idx}`,
        })),
        ...(showIndexColumn
          ? [
              {
                title: 'Index',
                render: ({ type }) => {
                  const totalWeight =
                    benchmarkSectors?.find(b => b.sector == type)?.weighting ??
                    0;
                  const value = totalWeight?.toFixed(COMPARE_TOOL_DECIMALS);
                  return (
                    <div>
                      {totalWeight !== 0 ? `${value}%` : EMPTY_DATA_POINT}
                    </div>
                  );
                },
                width: getColWidth(100, 80, 65),
                renderType: 'number',
                idSubText: 'index',
              } as IDataTableColumns,
            ]
          : []),
      ]}
      // We'll use the data as keys since this table different data types per row
      data={tableData}
    />
  );
};

export default CompareSectors;
