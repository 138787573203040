export const openJiraForm = () => {
  const iframe = document.querySelector('#jsd-widget') as HTMLIFrameElement;
  const iframeContent = iframe?.contentDocument as Document;
  const button = iframeContent.querySelector('#help-button') as HTMLElement;
  button?.click();
};

export const toggleJiraButton = (show: boolean) => {
  const iframe = document.querySelector('#jsd-widget') as HTMLIFrameElement;
  const iframeContent = iframe?.contentDocument as Document;
  const button = iframeContent.querySelector(
    '#button-container',
  ) as HTMLElement;
  if (button?.style) {
    button.style.display = show ? 'block' : 'none';
  }
};
