import { useEffect, useRef } from 'react';

const useAutoFocusRef = <T>(deps: any[] = [], autofocus = true) => {
  const ref = useRef<T>(null);
  useEffect(() => {
    if (ref.current && autofocus) {
      setTimeout(() => {
        (ref.current as unknown as HTMLInputElement).focus();
      }, 50);
    }
  }, [...deps, autofocus]);
  return ref;
};

export default useAutoFocusRef;
