import { Tabs } from 'antd';
import { useEffect, useMemo, useState } from 'react';
import { Link, useHistory, useLocation, useParams } from 'react-router-dom';

import { Dropdown } from '@/components';
import { WATCHLIST } from '@/hooks/query-hooks/watchlist-hooks/query-keys';
import { useCurrentWatchlist } from '@/hooks/query-hooks/watchlist-hooks/useWatchlists';
import queryClient from '@/queryClient';
import { convertNameAndIDToSlug } from '@/utils';
import getScreenWidthMode, {
  ScreenWidthEnum,
} from '@/utils/getScreenWidthMode';
import { stopPropogationOnRightClick } from '@/utils/StopPropogationOnRightClick';

import style from './style.module.less';

const TABS_ALL = 'All';

// TODO: Must be inside router component, that uses param:slug for watchlist
// move BucketsScrollTab to partials since it is used in Watchlist and SC Pages
export const BucketsScrollTab: React.FCWithChild = () => {
  const screenWidthMode = getScreenWidthMode();
  const [activeTab, setActiveTab] = useState<string | undefined>();
  const location = useLocation();
  const history = useHistory();
  const { slug } = useParams<{ slug: string }>();
  const currentWatchlist = useCurrentWatchlist();
  const buckets = currentWatchlist.data?.buckets;
  const isLoading = currentWatchlist.isLoading;

  useEffect(() => {
    if (buckets?.length) {
      const currentTab = [
        TABS_ALL,
        ...buckets?.map(bucket =>
          convertNameAndIDToSlug({ id: bucket?.id, name: bucket?.name }),
        ),
      ].find(word => location.pathname.includes(word));
      setActiveTab(currentTab);
    }
  }, [location, buckets]);

  const onNavigateTab = () => {
    queryClient.invalidateQueries([WATCHLIST]);
  };

  const handleSelectBucket = (tab?: string) => {
    queryClient.invalidateQueries([WATCHLIST]);
    history.push(`${tab ?? TABS_ALL}`);
  };

  const tabItems = useMemo(() => {
    const tabs = [];

    tabs.push({
      label: (
        <Link to={TABS_ALL}>
          <span data-test-id={`bucketTab-${TABS_ALL}`}>{TABS_ALL}</span>
        </Link>
      ),
      key: TABS_ALL,
    });
    isLoading
      ? tabs.push({
          label: 'Loading...',
          key: 'loading',
        })
      : buckets?.map(bucket =>
          tabs.push({
            label: (
              <Link to={convertNameAndIDToSlug(bucket)}>
                <span data-test-id={`bucketTab-${bucket.id}`}>
                  {bucket.name}
                </span>
              </Link>
            ),
            key: convertNameAndIDToSlug(bucket),
          }),
        );
    return tabs;
  }, [buckets, isLoading]);

  return (
    <>
      {screenWidthMode[ScreenWidthEnum.MaxSm] ? (
        <Dropdown
          defaultValue={slug}
          className={style['bucket-dropdown']}
          rounded
          label="All"
          onSelect={handleSelectBucket}
          items={[
            {
              label: (
                <Link
                  to={'All'}
                  onClick={event => {
                    stopPropogationOnRightClick(event);
                  }}
                >
                  All
                </Link>
              ),
              value: 'All',
            },
            ...(buckets?.length
              ? buckets?.map(bucket => ({
                  label:
                    (
                      <Link
                        to={convertNameAndIDToSlug(bucket)}
                        onClick={event => {
                          stopPropogationOnRightClick(event);
                        }}
                      >
                        {bucket.name}
                      </Link>
                    ) || '',
                  value: convertNameAndIDToSlug(bucket),
                }))
              : []),
          ]}
          placement="bottom"
        />
      ) : (
        <Tabs
          activeKey={activeTab}
          onChange={onNavigateTab}
          defaultActiveKey="All"
          items={tabItems}
        />
      )}
    </>
  );
};
