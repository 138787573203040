import {
  NotificationItem,
  PortfolioInviteStatusEnum,
} from '@aminsights/contract';
import { Modal, Skeleton } from 'antd';
import SkeletonButton from 'antd/lib/skeleton/Button';
import { FC, useEffect } from 'react';
import { useHistory } from 'react-router-dom';

import Button from '@/components/Button';
import { PORTFOLIOS } from '@/hooks/query-hooks/portfolio-hooks/query-keys';
import {
  useAcceptPortfolioInvite,
  useDeclinePortfolioInvite,
  usePortfolioInviteDetail,
} from '@/hooks/query-hooks/portfolio-hooks/usePortfolioInvite';
import queryClient from '@/queryClient';

import style from './style.module.less';

interface NotificationDetailModalProps {
  isVisible: boolean;
  toggleModal: () => void;
  notification?: NotificationItem;
}

const NotificationDetailModal: FC<NotificationDetailModalProps> = ({
  isVisible,
  toggleModal,
  notification,
}) => {
  const history = useHistory();
  const { title, subtitle, navigationLink } = notification ?? {};
  const token = navigationLink?.split('?token=')[1];
  const { data, isLoading, refetch } = usePortfolioInviteDetail(token);
  const acceptPortfolioInvite = useAcceptPortfolioInvite();
  const declinePortfolioInvite = useDeclinePortfolioInvite();

  const { status } = data || {};

  useEffect(() => {
    if (token) refetch();
  }, [token]);

  const handlePrimaryButtonClick = async () => {
    if (!status || !navigationLink) return;

    if (status === PortfolioInviteStatusEnum.Pending) {
      await acceptPortfolioInvite.mutateAsync({ inviteLink: navigationLink });
    } else if (status === PortfolioInviteStatusEnum.Accepted) {
      history.push(navigationLink?.split('?')[0] || '/portfolios');
    } else {
      history.push('/portfolios');
    }
    handleClose();
  };
  const handleSecondaryButtonClick = async () => {
    if (!status || !navigationLink) return;

    if (status === PortfolioInviteStatusEnum.Pending) {
      await declinePortfolioInvite.mutateAsync({ inviteLink: navigationLink });
    }
    handleClose();
  };

  const handleClose = () => {
    queryClient.invalidateQueries([PORTFOLIOS, token]);
    toggleModal();
  };

  const getModalHeadingText = () => {
    switch (data?.status) {
      case PortfolioInviteStatusEnum.Pending:
        return `${title} ${subtitle}`;
      case PortfolioInviteStatusEnum.Accepted:
        return `You already accepted the ‘${data.portfolioName}’ portfolio.`;
      case PortfolioInviteStatusEnum.Declined:
        return `You already declined the ‘${data.portfolioName}’ portfolio.`;
      case PortfolioInviteStatusEnum.Deleted:
        return `This portfolio has already been deleted.`;
      default:
        return '';
    }
  };

  const getModalDescriptionText = () => {
    switch (data?.status) {
      case PortfolioInviteStatusEnum.Pending:
        return `To receive ‘${
          data?.userRole === 'editor' ? 'Edit' : 'View'
        }’ access to
          this portfolio, click the accept button below.`;
      case PortfolioInviteStatusEnum.Accepted:
        return `To view portfolio, click the button below.`;
      default:
        return '';
    }
  };

  const getModalFooter = () => {
    if (isLoading || !data?.status) {
      return [
        <div className="flex justify-end gap-x-4">
          {[1, 2].map(item => (
            <SkeletonButton key={item} active />
          ))}
        </div>,
      ];
    }

    const footer = [
      <Button
        className="font-medium text-sm p-0"
        size="large"
        type="link"
        key="secondary"
        disabled={acceptPortfolioInvite.isLoading}
        onClick={handleSecondaryButtonClick}
      >
        {data.status === PortfolioInviteStatusEnum.Pending
          ? 'Decline'
          : 'Close'}
      </Button>,
    ];

    if (
      [
        PortfolioInviteStatusEnum.Pending,
        PortfolioInviteStatusEnum.Accepted,
      ].includes(data.status)
    ) {
      footer.push(
        <Button
          className="h-10 w-20 font-medium"
          type="primary"
          size="large"
          key="primary"
          htmlType="submit"
          onClick={handlePrimaryButtonClick}
          loading={acceptPortfolioInvite.isLoading}
        >
          {data?.status === PortfolioInviteStatusEnum.Pending
            ? 'Accept'
            : 'View'}
        </Button>,
      );
    }

    return footer;
  };

  return (
    <Modal
      className={style['notification-detail-modal']}
      width={400}
      open={isVisible}
      onCancel={handleClose}
      footer={getModalFooter()}
      centered={true}
    >
      {isLoading ? (
        <div className="flex flex-col gap-y-6">
          {[1, 2].map(item => (
            <Skeleton key={item} paragraph={{ rows: 2 }} title={false} active />
          ))}
        </div>
      ) : (
        <div className="flex flex-col gap-4">
          <h2 className="text-xl font-bold text-neutral-200">
            {getModalHeadingText()}
          </h2>
          <p className="text-sm text-neutral">{getModalDescriptionText()}</p>
        </div>
      )}
    </Modal>
  );
};

export default NotificationDetailModal;
