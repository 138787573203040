import cx from 'classnames';
import { useEffect, useRef, useState } from 'react';

import { ReactComponent as IconClose } from '@/assets/svg/icons/icon-close.svg';
import { ReactComponent as IconNotifBell } from '@/assets/svg/icons/icon-notif-bell.svg';
import { Button } from '@/components';
import useCountUnseenNotifications from '@/hooks/query-hooks/notification-hooks/useCountUnseenNotifications';
import useScreenWidth from '@/hooks/screenWidth';

export const USER_NOTIFICATIONS_CONSENT_KEY = 'userNotificationsConsent';
export enum NOTIFICATIONS_CONSENT {
  ALLOWED = 'Allowed',
  DENIED = 'Denied',
  CLOSED = 'Closed',
}

const NotificationPermissionModal = () => {
  const { data: unseenNotifications, isLoading } =
    useCountUnseenNotifications();
  const [modalOpen, setModalOpen] = useState(false);
  const modalRef = useRef<HTMLDivElement>(null);
  const { currentWidth } = useScreenWidth();

  const getNotificationPermission = async () => {
    if ('Notification' in window) {
      return Notification.requestPermission().then(state => {
        if (state === 'granted') {
          // eslint-disable-next-line
          console.log('Notification permission granted.');
          return true;
        } else {
          console.warn(
            'Notifications are not permitted. Please allow notifications to stay updated.',
          );
          return false;
        }
      });
    }
  };

  useEffect(() => {
    if (isLoading) return;

    const storedNotificationConsent = localStorage.getItem(
      USER_NOTIFICATIONS_CONSENT_KEY,
    );
    const isStandaloneApp = window.matchMedia(
      '(display-mode: standalone)',
    ).matches;

    const isMobileDevice = /iPhone|iPad|iPod|Android/i.test(
      navigator.userAgent,
    );

    const unseenNotificationsAndModalClosed =
      unseenNotifications &&
      storedNotificationConsent === NOTIFICATIONS_CONSENT.CLOSED;

    if (
      isStandaloneApp &&
      isMobileDevice &&
      (!storedNotificationConsent || unseenNotificationsAndModalClosed)
    ) {
      setTimeout(() => {
        setModalOpen(true);
      }, 5000);
    }
  }, [isLoading]);

  //this gets the height of the modal and moves the body down by that height
  useEffect(() => {
    const rootElement = document.getElementById('root');
    if (!rootElement) return;

    if (modalOpen && modalRef?.current) {
      rootElement.style.transition = `transform 0.5s ease-in-out`;
      rootElement.style.transform = `translateY(${modalRef.current.offsetHeight}px)`;
    } else {
      rootElement.style.transform = ``;
      if (modalRef?.current) modalRef.current.style.top = '0px';
    }
  }, [modalOpen, currentWidth]);

  return (
    <div
      ref={modalRef}
      className={cx(
        'fixed top-0 left-0 right-0 z-[1000] bg-[#e6fbff] w-full p-5 transition-transform ease-in-out duration-500 sm:flex sm:justify-center sm:items-center',
        { 'hidden sm:hidden': !modalOpen },
      )}
      id="notification-permission-toast"
    >
      <div className="flex items-center pt-4 pb-4 sm:pt-0 sm:pb-0">
        <IconNotifBell className="fill-current text-neutral h-6 w-6 flex-grow-0 flex-shrink-0" />
        <h1 className="text-neutral-700 px-2">
          Allow AM Insights to send you notifications?
        </h1>
      </div>
      <div className="grid grid-cols-2">
        <Button
          type="primary"
          onClick={async () => {
            const isPermissionGranted = await getNotificationPermission();
            if (isPermissionGranted) {
              localStorage.setItem(
                USER_NOTIFICATIONS_CONSENT_KEY,
                NOTIFICATIONS_CONSENT.ALLOWED,
              );
            } else {
              localStorage.setItem(
                USER_NOTIFICATIONS_CONSENT_KEY,
                NOTIFICATIONS_CONSENT.CLOSED,
              );
            }
            setModalOpen(false);
          }}
        >
          Allow
        </Button>
        <Button
          type="default"
          onClick={() => {
            localStorage.setItem(
              USER_NOTIFICATIONS_CONSENT_KEY,
              NOTIFICATIONS_CONSENT.DENIED,
            );
            setModalOpen(false);
          }}
          className="bg-transparent border-none shadow-none hover:bg-transparent"
        >
          Deny
        </Button>
      </div>
      <button
        onClick={() => {
          localStorage.setItem(
            USER_NOTIFICATIONS_CONSENT_KEY,
            NOTIFICATIONS_CONSENT.CLOSED,
          );
          setModalOpen(false);
        }}
        className="fill-[#8c92b1] h-3 w-3 absolute right-5 top-5 sm:top-[2.2em] text-neutral"
      >
        <IconClose />
      </button>
    </div>
  );
};

export default NotificationPermissionModal;
