import { Checkbox } from '@/components';

import {
  checkboxSelectAllData,
  filterDatesACTCall,
  SELECT_ALL,
} from '../../../hooks/esgSubState';
import useExploreFilters from '../../../hooks/useExploreFilters';
import styles from '../../style.module.less';

type CheckboxValueType = any;

const EsgActFilter = () => {
  const { tentativeFilters, getMutableFilters, updateTentativeFilters } =
    useExploreFilters();
  const esgFilters = tentativeFilters.esgFilters;
  const filterDates = filterDatesACTCall();
  return (
    <div className={styles['column-checkbox']}>
      <div className="ant-checkbox-group flex">
        <Checkbox
          data={checkboxSelectAllData}
          direction="row"
          onChange={(list: Array<CheckboxValueType>) => {
            const filters = getMutableFilters();
            filters.esgFilters = filters.esgFilters ?? {};
            if (list.includes(SELECT_ALL)) {
              filters.esgFilters.actDates = filterDates.options.map(
                option => option.value,
              );
              updateTentativeFilters(filters);
            } else {
              filters.esgFilters.actDates = undefined;
              updateTentativeFilters(filters);
            }
          }}
          value={
            (esgFilters?.actDates ?? []).length >= filterDates.options.length
              ? [SELECT_ALL]
              : []
          }
        />
        <Checkbox
          data={filterDates.options}
          direction="row"
          onChange={(checkedItems: string[]) => {
            const filters = getMutableFilters();
            filters.esgFilters = filters.esgFilters ?? {};
            filters.esgFilters.actDates =
              checkedItems.length > 0 ? checkedItems : undefined;
            updateTentativeFilters(filters);
          }}
          value={esgFilters?.actDates}
        />
      </div>
    </div>
  );
};

export default EsgActFilter;
