import { isInvestmentTrust } from '@aminsights/shared';
import cx from 'classnames';
import React, { useMemo } from 'react';

import { useBenchmarkOptions } from '@/hooks/query-hooks/benchmark-hooks/useManageBenchmarks';
import { useFundByIsInParam } from '@/hooks/query-hooks/fund-hooks/useFundByIsin';
import AbsoluteChartTitle from '@/partials/Charts/absolute/AbsoluteChartTitle';
import AbsoluteChartV2 from '@/partials/Charts/absolute/AbsoluteChartV2';
import { RipsReturnTypeProvider } from '@/partials/Charts/Context';
import RelativeChartTitle from '@/partials/Charts/relative/RelativeChartTitle';
import RelativeChartV2 from '@/partials/Charts/relative/RelativeChartV2';

import style from './style.module.less';

export const FundCharts: React.FCWithChild = () => {
  const { data: state } = useFundByIsInParam();
  const { data: benchmarkOptionsResponse } = useBenchmarkOptions();

  const matchingBenchmark = benchmarkOptionsResponse?.find(
    b => b.secId === state?.fund?.performanceBenchmarkId,
  )?.name;

  const AbsoluteChartComponentV2 = useMemo(() => {
    return (
      state?.fund?.shareClassDetails.isin && (
        <AbsoluteChartV2
          isins={
            state?.fund?.shareClassDetails.isin
              ? [state?.fund?.shareClassDetails.isin]
              : []
          }
          showFundLinks={false}
          benchmarkId={state?.fund?.performanceBenchmarkId}
          hasInvestmentTrust={isInvestmentTrust(state?.fund?.legalStructure)}
        />
      )
    );
  }, [
    state?.fund?.shareClassDetails.isin,
    state?.fund?.performanceBenchmarkId,
  ]);

  const RelativeChartComponentV2 = useMemo(() => {
    return (
      state?.fund?.shareClassDetails.isin && (
        <RelativeChartV2
          showFundLinks={false}
          isins={[state?.fund?.shareClassDetails.isin]}
          considerIsFeatured={false}
          benchmarkId={state?.fund?.performanceBenchmarkId}
          hasInvestmentTrust={isInvestmentTrust(state?.fund?.legalStructure)}
        />
      )
    );
  }, [
    state?.fund?.shareClassDetails.isin,
    state?.fund?.performanceBenchmarkId,
  ]);

  return (
    <RipsReturnTypeProvider>
      <div
        className={cx(
          'grid gap-4',
          state?.fund?.performanceBenchmarkId ? 'grid-cols-2' : 'grid-cols-1',
        )}
      >
        <div className="col-span-2 lg:col-span-1">
          <div
            className={cx('p-4 bg-white lg:rounded-lg', style.chartContainer)}
            data-test-id="fundDetailsPerfRiskTabAbsoluteChart"
          >
            <AbsoluteChartTitle />
            {AbsoluteChartComponentV2}
          </div>
        </div>
        {state?.fund?.performanceBenchmarkId && (
          <div className="col-span-2 lg:col-span-1">
            <div
              className={cx('p-4 bg-white lg:rounded-lg', style.chartContainer)}
              data-test-id="fundDetailsPerfRiskTabRelativeChart"
            >
              <RelativeChartTitle benchmarkName={matchingBenchmark} />
              {RelativeChartComponentV2}
            </div>
          </div>
        )}
      </div>
    </RipsReturnTypeProvider>
  );
};
