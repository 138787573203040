import { Dropdown, Menu } from 'antd';
import cx from 'classnames';
import { Dayjs } from 'dayjs';
import React, { useEffect, useRef, useState } from 'react';

import { ReactComponent as MeatballMenu } from '@/assets/svg/meatball-menu.svg';
import ConfirmationModalDanger from '@/partials/Modal/ConfirmationModalDanger';

interface Props {
  date: Dayjs;
  disabledDates: string[];
  onUpdateDate?: (oldDate: Dayjs, newDate: Dayjs) => boolean;
  onDeleteDate: (date: Dayjs) => Promise<void>;
  disableEdit?: boolean;
}

const PortfolioFundHeaderOptions: React.FCWithChild<Props> = ({
  date,
  onDeleteDate,
  disableEdit,
}) => {
  const containerRef = useRef(null);
  const [isDeleteItemModalOpen, setIsDeletePortfolioModalOpen] =
    useState(false);

  const divRef = useRef<HTMLDivElement>(null);

  const handleRemoveNewBucketModalOpen = () => {
    setIsDeletePortfolioModalOpen(prev => !prev);
  };

  const bucketMenu = (
    <Menu
      className="custom-dropdown-menu"
      items={[
        {
          label: <span style={{ color: '#e64236' }}>Delete</span>,
          key: 'removePortfolioColumn',
          onClick: () => handleRemoveNewBucketModalOpen(),
        },
      ]}
    />
  );

  useEffect(() => {
    if (divRef) {
      // TEMPORARILY COMMENTED OUT, need to confirm with designers
      //We need to show the MeatBall on hover over the whole header cell
      // const tableHeaderNode =
      //   divRef?.current?.parentNode?.parentNode?.parentNode;
      // if (tableHeaderNode) {
      //   tableHeaderNode.addEventListener(
      //     'mouseover',
      //     () => date.isValid() && !disableEidt && setIsMeatballVisible(true),
      //   );
      //   tableHeaderNode.addEventListener(
      //     'mouseleave',
      //     () => date.isValid() && setIsMeatballVisible(false),
      //   );
      // }
    }
  }, [divRef]);

  return (
    <div ref={divRef}>
      <Dropdown
        overlay={bucketMenu}
        className={cx(
          disableEdit
            ? 'cursor-not-allowed fill-[#BAC0D0]'
            : 'fill-[#0000008a] cursor-pointer hover:bg-[#d0d0d3]',
          'rounded-full h-6 w-6 p-1 rotate-90',
        )}
        placement="bottomLeft"
        trigger={['click']}
      >
        <MeatballMenu ref={containerRef} />
      </Dropdown>
      <ConfirmationModalDanger
        modalInfo={{
          title: 'Delete table column?',
          description: `This column will be permanently deleted.`,
          primaryActionLabel: 'Delete',
          succesMessage: `Successfully deleted column`,
          errorMessage: `Column not deleted`,
        }}
        isVisible={isDeleteItemModalOpen}
        toggleModal={handleRemoveNewBucketModalOpen}
        onConfirm={() => onDeleteDate(date)}
      />
    </div>
  );
};

export default PortfolioFundHeaderOptions;
