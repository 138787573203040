import { Bucket, Fund, Performance } from '@aminsights/contract';
import {
  calculatePerformanceTableBackgroundColor,
  isFund,
  LIMIT_FUNDS_FOR_CHARTING,
  TotalReturnPeriod,
} from '@aminsights/shared';
import { Checkbox, Skeleton } from 'antd';

import { IDataTableColumns } from '@/components/Table/DataTable';
import { UseOnCheckedRowReturn } from '@/hooks/useOnCheckedRow';
import FundInvestmentTrustColumn from '@/partials/columns/FundInvestmentTrustColumn';
import { switchTableRowColor } from '@/utils/generate-color';
import getScreenWidthMode, {
  ScreenWidthEnum,
} from '@/utils/getScreenWidthMode';
import { roundOrEmpty } from '@/utils/roundOrEmpty';

type WatchlistAnnualisedPerformanceColumnsProps = {
  rowSelectionState: UseOnCheckedRowReturn<Fund>;
  currentBucket?: Bucket | undefined;
  onSelectAll: (isChecked: boolean) => void;
};

export const WatchlistAnnualisedPerformanceColumns = ({
  rowSelectionState,
  onSelectAll,
  currentBucket,
}: WatchlistAnnualisedPerformanceColumnsProps): Array<IDataTableColumns> => {
  const { onCheckedRow, checkedRows, isAllCheckboxChecked } = rowSelectionState;
  const screenWidthMode = getScreenWidthMode();
  const columnDefinitions: Array<IDataTableColumns> = [
    {
      title: (
        <div className="whitespace-normal text-start">
          Fund/Investment Trust
        </div>
      ),
      dataTestIdSuffix: 'Fund/Investment Trust',
      sortKey: 'fundName',
      idSubText: 'fundName',
      backgroundColor: (item: Fund | Performance) => {
        if (isFund(item)) {
          const isFeaturedRow = !!currentBucket?.funds.find(
            f => item.shareClassDetails?.isin === f?.isin,
          )?.isFeatured;
          return switchTableRowColor(isFeaturedRow, false, isFeaturedRow) ?? '';
        } else {
          return switchTableRowColor(false, true) ?? '';
        }
      },
      headerCheckbox: (): React.ReactNode => (
        <div className="mr-2" onClick={e => e.stopPropagation()}>
          <Checkbox
            onClick={(event: React.MouseEvent<HTMLInputElement>) => {
              const isChecked = event.currentTarget.checked;
              onSelectAll(isChecked);
              event.stopPropagation();
            }}
            checked={isAllCheckboxChecked}
          />
        </div>
      ),
      render: (item: Fund | Performance): React.ReactNode => {
        return isFund(item) ? (
          <div className="flex items-center">
            <div className="mr-2 md:mr-7" onClick={e => e.stopPropagation()}>
              <Checkbox
                value={item.shareClassDetails.isin}
                onChange={e => onCheckedRow([e.target.value])}
                onClick={e => e.stopPropagation()}
                checked={checkedRows.includes(item.shareClassDetails.isin)}
                disabled={
                  checkedRows.length >= LIMIT_FUNDS_FOR_CHARTING &&
                  !checkedRows.includes(item.shareClassDetails.isin)
                }
              />
            </div>
            <div>
              <FundInvestmentTrustColumn
                data={{
                  id: item.shareClassDetails.isin,
                  name: item.shareClassDetails.code,
                  type: item.legalStructure,
                  assetClassCode: item.assetClassCode,
                  fundId: item.fundId,
                  legalStructure: item.legalStructure,
                }}
              />
            </div>
          </div>
        ) : (
          // empty ant-avatar-sm is just for to mock avatar
          <div className="flex flex-row">
            <div className="hidden mr-4 ant-avatar-sm sm:block" />
            <div className="font-bold table-cell-value text-darkest">
              {item.name}
            </div>
          </div>
        );
      },
      renderType: 'custom',
      align: 'between',
      width: screenWidthMode[ScreenWidthEnum.MaxMd] ? 176 : 320,
      isColumnFixed: true,
      loader: (
        <Skeleton
          avatar={{ size: 'small' }}
          title={false}
          paragraph={{ rows: 2 }}
          active
        />
      ),
    },
    {
      title: 'YTD',
      idSubText: 'ytd',
      renderType: 'custom',
      align: 'center',
      children: [
        {
          title: 'Return',
          sortKey: 'totalReturnYtdPct',
          render: (item: Fund | Performance): string => {
            const fundValue = item?.totalReturnYtdPct;
            const value = roundOrEmpty(fundValue, 1, '%');
            return value;
          },
          renderType: 'number',
          width: 85,
        },
        {
          title: 'Pcl',
          sortKey: 'totalReturnYtdMorningstarCategoryPcl',
          render: (item: Fund | Performance): string => {
            const fundValue = isFund(item)
              ? item?.annualisedPerformancesPcl?.find(
                  a => a.type === TotalReturnPeriod.YTD,
                )?.value
              : null;
            null;
            const value = roundOrEmpty(fundValue, 0);
            return value;
          },
          renderType: 'number',
          width: 85,
          backgroundColor: (item: Fund | Performance) => {
            return isFund(item)
              ? calculatePerformanceTableBackgroundColor(
                  item,
                  'annualisedPerformancesPcl',
                  TotalReturnPeriod.YTD,
                )
              : '';
          },
        },
      ],
    },
    {
      title: '3 mos',
      idSubText: '3mos',
      renderType: 'custom',
      align: 'center',
      children: [
        {
          title: 'Return',
          sortKey: 'totalReturn3MonthPct',
          render: (item: Fund | Performance): string => {
            const fundValue = item?.totalReturn3MonthPct;
            const value = roundOrEmpty(fundValue, 1, '%');
            return value;
          },
          renderType: 'number',
          width: 85,
        },
        {
          title: 'Pcl',
          sortKey: 'totalReturn3MonthMorningstarCategoryPcl',
          render: (item: Fund | Performance): string => {
            const fundValue = isFund(item)
              ? item?.annualisedPerformancesPcl?.find(
                  a => a.type === TotalReturnPeriod.THREE_MONTHS,
                )?.value
              : null;
            const value = roundOrEmpty(fundValue, 0);
            return value;
          },
          renderType: 'number',
          width: 85,
          backgroundColor: (item: Fund | Performance) => {
            return isFund(item)
              ? calculatePerformanceTableBackgroundColor(
                  item,
                  'annualisedPerformancesPcl',
                  TotalReturnPeriod.THREE_MONTHS,
                )
              : '';
          },
        },
      ],
    },
    {
      title: '6 mos',
      idSubText: '6mos',
      renderType: 'custom',
      align: 'center',
      children: [
        {
          title: 'Return',
          sortKey: 'totalReturn6MonthPct',
          render: (item: Fund | Performance): string => {
            const fundValue = item?.totalReturn6MonthPct;
            const value = roundOrEmpty(fundValue, 1, '%');
            return value;
          },
          renderType: 'number',
          width: 85,
        },
        {
          title: 'Pcl',
          sortKey: 'totalReturn6MonthMorningstarCategoryPcl',
          render: (item: Fund | Performance): string => {
            const fundValue = isFund(item)
              ? item?.annualisedPerformancesPcl?.find(
                  a => a.type === TotalReturnPeriod.SIX_MONTHS,
                )?.value
              : null;
            const value = roundOrEmpty(fundValue, 0);
            return value;
          },
          renderType: 'number',
          width: 85,
          backgroundColor: (item: Fund | Performance) => {
            return isFund(item)
              ? calculatePerformanceTableBackgroundColor(
                  item,
                  'annualisedPerformancesPcl',
                  TotalReturnPeriod.SIX_MONTHS,
                )
              : '';
          },
        },
      ],
    },
    {
      title: '1 yr',
      idSubText: '1yr',
      renderType: 'custom',
      align: 'center',
      children: [
        {
          title: 'Return',
          sortKey: 'totalReturn1YearPct',
          render: (item: Fund | Performance): string => {
            const fundValue = item?.totalReturn1YearPct;
            const value = roundOrEmpty(fundValue, 1, '%');
            return value;
          },
          renderType: 'number',
          width: 85,
        },
        {
          title: 'Pcl',
          sortKey: 'totalReturn1YearMorningstarCategoryPcl',
          render: (item: Fund | Performance): string => {
            const fundValue = isFund(item)
              ? item?.annualisedPerformancesPcl?.find(
                  a => a.type === TotalReturnPeriod.ONE_YEAR,
                )?.value
              : null;
            const value = roundOrEmpty(fundValue, 0);
            return value;
          },
          renderType: 'number',
          width: 85,
          backgroundColor: (item: Fund | Performance) => {
            return isFund(item)
              ? calculatePerformanceTableBackgroundColor(
                  item,
                  'annualisedPerformancesPcl',
                  TotalReturnPeriod.ONE_YEAR,
                )
              : '';
          },
        },
      ],
    },
    {
      title: '3 yrs',
      idSubText: '3yrs',
      renderType: 'custom',
      align: 'center',
      children: [
        {
          title: 'Return',
          sortKey: 'totalReturn3YearPct',
          render: (item: Fund | Performance): string => {
            const fundValue = item?.totalReturn3YearPct;
            const value = roundOrEmpty(fundValue, 1, '%');
            return value;
          },
          renderType: 'number',
          width: 85,
        },
        {
          title: 'Pcl',
          sortKey: 'totalReturn3YearMorningstarCategoryPcl',
          render: (item: Fund | Performance): string => {
            const fundValue = isFund(item)
              ? item?.annualisedPerformancesPcl?.find(
                  a => a.type === TotalReturnPeriod.THREE_YEARS,
                )?.value
              : null;
            const value = roundOrEmpty(fundValue, 0);
            return value;
          },
          renderType: 'number',
          width: 85,
          backgroundColor: (item: Fund | Performance) => {
            return isFund(item)
              ? calculatePerformanceTableBackgroundColor(
                  item,
                  'annualisedPerformancesPcl',
                  TotalReturnPeriod.THREE_YEARS,
                )
              : '';
          },
        },
      ],
    },
    {
      title: '5 yrs',
      idSubText: '5yrs',
      renderType: 'custom',
      align: 'center',
      children: [
        {
          title: 'Return',
          sortKey: 'totalReturn5YearPct',
          render: (item: Fund | Performance): string => {
            const fundValue = item?.totalReturn5YearPct;
            const value = roundOrEmpty(fundValue, 1, '%');
            return value;
          },
          renderType: 'number',
          width: 85,
        },
        {
          title: 'Pcl',
          sortKey: 'totalReturn5YearMorningstarCategoryPcl',
          render: (item: Fund | Performance): string => {
            const fundValue = isFund(item)
              ? item?.annualisedPerformancesPcl?.find(
                  a => a.type === TotalReturnPeriod.FIVE_YEARS,
                )?.value
              : null;
            const value = roundOrEmpty(fundValue, 0);
            return value;
          },
          renderType: 'number',
          width: 85,
          backgroundColor: (item: Fund | Performance) => {
            return isFund(item)
              ? calculatePerformanceTableBackgroundColor(
                  item,
                  'annualisedPerformancesPcl',
                  TotalReturnPeriod.FIVE_YEARS,
                )
              : '';
          },
        },
      ],
    },
  ];

  return columnDefinitions;
};
