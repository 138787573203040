import { PortfolioFundForUpdate } from '@aminsights/contract';
import { DATE_FORMAT_DASHED } from '@aminsights/shared';
import { Upload } from 'antd';
import dayjs from 'dayjs';
import React from 'react';

import { UPLOAD_FAILED } from '@/constants/text-messages';
import { UploadModalInfoBase } from '@/pages/app/Watchlist/Buckets/components/ImportFunds/types';

import style from './style.module.less';

const { Dragger } = Upload;

type Props = {
  onFundsUploadFailure: (
    failedStatus: UploadModalInfoBase,
    filenames: string[],
  ) => void;
  onFundsUploadSuccess: (
    portfolioFunds: PortfolioFundForUpdate[],
    filenames: string[],
    dates: string[],
  ) => void;
};

const CsvFundsDropzone: React.FCWithChild<Props> = ({
  onFundsUploadSuccess,
  onFundsUploadFailure,
  children,
}) => {
  const handleUploadChange = (info: any) => {
    const fileList = info.fileList.slice(-1);
    if (fileList.length === 0) {
      onFundsUploadSuccess([], [], []);
    }
  };

  const handleBeforeUpload = (file: File) => {
    const reader = new FileReader();
    reader.onload = e => {
      if (e.target && e.target.result) {
        try {
          const uploadResult = e.target.result.toString().split('\n');
          if (uploadResult.length < 2) {
            throw new Error(
              'CSV format is invalid or has no data, except headers.',
            );
          }
          const headerRow = uploadResult[0];
          const headerStrings = headerRow.split(',');
          if (
            headerStrings.length === 0 ||
            headerStrings[0].trim() !== 'ISIN'
          ) {
            throw new Error('CSV header format is invalid.');
          }
          const dates = headerStrings.slice(1).map(d => d.trim());
          for (const [i, d] of dates.entries()) {
            if (dates.lastIndexOf(d) !== i) {
              throw new Error(
                `CSV header date has a duplicate: ${d}. At position ${i + 1}`,
              );
            }
            if (!dayjs(d, DATE_FORMAT_DASHED).isValid()) {
              throw new Error('CSV header date format is invalid: ' + d);
            }
          }
          const fundsToBeMapped = uploadResult.slice(1);
          const resultFunds: PortfolioFundForUpdate[] = [];

          for (const dataRow of fundsToBeMapped) {
            const data = dataRow.trim().split(',');
            if (data.length === 0) {
              continue;
            }
            const isin = data[0];
            const weightings = data.slice(1);

            for (const w of weightings) {
              if (w !== '' && isNaN(+w)) {
                throw new Error(
                  `Weighting ${w} for isin ${isin} must be a decimal point number`,
                );
              }
            }

            if (isin) {
              const weightingsParsed = weightings
                .filter((_w, i) => !!dates[i])
                .map((weight, i) => ({
                  value: weight === '' ? 0 : +weight,
                  date: dates[i],
                }));

              resultFunds.push({
                isin: isin,
                weightings: weightingsParsed,
              });
            }
          }

          //Exclude output dates duplicates, after we process csv with possible duplicates
          onFundsUploadSuccess(
            resultFunds,
            [file.name],
            dates.sort((a, b) =>
              dayjs(b, DATE_FORMAT_DASHED).diff(dayjs(a, DATE_FORMAT_DASHED)),
            ),
          );
        } catch (err) {
          onFundsUploadFailure(
            {
              title: UPLOAD_FAILED,
              description: err as string,
            },
            [file.name],
          );
        }
      } else {
        onFundsUploadFailure(
          {
            title: UPLOAD_FAILED,
            description: 'Your file is empty. Please add ISINs',
          },
          [file.name],
        );
      }
    };
    reader.readAsText(file);
    return false;
  };

  const uploadProps = {
    name: 'file',
    multiple: false,
    showUploadList: false,
    onChange: handleUploadChange,
    accept: '.csv',
    beforeUpload: handleBeforeUpload,
  };

  return (
    <Dragger {...uploadProps} className={style['dragger']}>
      {children}
    </Dragger>
  );
};

export default CsvFundsDropzone;
