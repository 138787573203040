import { ChartV2DataResponseWithPortfolioLegendData } from '@aminsights/contract';
import {
  CHART_COLOR_FOR_INDEX_LINE,
  ChartingToolColors,
  GenerateChartColorsType,
  LegendData,
} from '@aminsights/shared';
import {
  createContext,
  Dispatch,
  SetStateAction,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';

import useGetAbsoluteChartDataWithPortfolio from '@/hooks/query-hooks/chart-hooks/useGetAbsoluteChartDataWithPortfolio';
import { useMultipleFundsByIsins } from '@/hooks/query-hooks/fund-hooks/useFundByIsin';
import { useDropDownContext } from '@/partials/Charts/Context';
import { UseChartColorsBaseProps } from '@/partials/Charts/utils/useChartColors';
import { parseRangeValues, useDatePickerContext } from '@/partials/DatePicker';

import useChartTrustFundsFromSearch from '../utils/useChartFundsFromSearch';

interface IChartingContext {
  chartColors: Record<string, string>;
  setColorsRemoved: (color: string) => void;
  setRecentId: Dispatch<SetStateAction<string>>;
  chartLegend: ChartV2DataResponseWithPortfolioLegendData[];
}
interface UseChartColorsForChartingTool<T> extends UseChartColorsBaseProps<T> {
  isins: string[];
  // Format should be {id}_{name}
  portfolioLegendIds?: string[];
  availableColors: { transparent: string; bright: string }[];
  recentId?: string;
  idsSortOrder: string[];
  onSetIdsSortOrder: Dispatch<SetStateAction<string[]>>;
  sortByOrder?: boolean;
}
type GenerateChartColorsTypeExtended<T extends LegendData> =
  GenerateChartColorsType<T> & {
    colors: { transparent: string; bright: string }[];
  };
const ChartingContext = createContext<IChartingContext>({
  chartColors: {},
  setRecentId: () => '',
  chartLegend: [],
  setColorsRemoved: () => {},
});
export const useChartingContext = () => useContext(ChartingContext);

export const generateChartColors = <T extends LegendData>({
  legendData,
  ids,
  sortedIsins,
  colors,
}: GenerateChartColorsTypeExtended<T>) => {
  const fundColors = (ids || sortedIsins).reduce((prev, i, idx) => {
    const legendPoint = ids
      ? legendData.find(ld => ld.id === i)
      : legendData.find(ld => ld.isin === i);
    const color = colors[idx]['bright'];
    if (!legendPoint?.id) {
      return prev;
    }
    const duplicateColor = legendPoint?.isin
      ? { [legendPoint.isin]: color }
      : {};
    return { ...prev, ...duplicateColor, [legendPoint.id]: color };
  }, {} as Record<string, string>);
  const benchmark = legendData.find(ld => ld.isBenchmark);
  return {
    ...fundColors,
    ...(benchmark?.id
      ? {
          [benchmark.id]: CHART_COLOR_FOR_INDEX_LINE,
        }
      : {}),
  };
};

export const useChartColorsForChartingTool = <T extends LegendData>({
  isins,
  portfolioLegendIds,
  legend,
  availableColors,
  onSetIdsSortOrder,
  idsSortOrder,
  recentId,
  sortByOrder,
}: UseChartColorsForChartingTool<T>) => {
  const { data: funds } = useMultipleFundsByIsins(isins);
  const sortedIds = useMemo(() => {
    const portfolioLabels = legend
      .filter(l => portfolioLegendIds?.find(f => l.id?.includes(f)))
      .map(p => {
        return {
          label: p.label || '',
          id: p.id || '',
        };
      });
    const fundLabels = (funds || []).map(f => ({
      id: legend.find(l => l.isin === f.shareClassDetails.isin)?.id,
      label: f.fundName,
    }));
    const sortedLabels = [...portfolioLabels, ...fundLabels].sort((a, b) =>
      a.label.localeCompare(b.label),
    );
    const sortedIds = sortedLabels
      .map(({ id }) => id)
      .filter(Boolean) as string[];
    if (sortByOrder && sortedIds.length) {
      const setOfIds = new Set(idsSortOrder);
      if (recentId) {
        const recentIdLocal =
          legend?.find(l => l.id?.includes(recentId))?.id ||
          legend.find(l => l.isin === recentId)?.id;
        if (recentIdLocal) {
          setOfIds.add(recentIdLocal);
        }
      }
      const ids = Array.from(setOfIds).filter(id =>
        sortedIds.find(sid => sid === id),
      );
      return ids;
    }
    return sortedIds;
  }, [funds, isins, portfolioLegendIds, legend, recentId]);

  useEffect(() => {
    if (sortedIds.length) {
      onSetIdsSortOrder(sortedIds);
    }
  }, [JSON.stringify(sortedIds)]);

  const colors = useMemo(
    () =>
      generateChartColors({
        legendData: legend,
        ids: sortedIds,
        colors: availableColors,
      }),
    [sortedIds, legend],
  );
  return colors;
};

const ChartingContextProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const loadedFromUrlRef = useRef(true);
  const [availableColors, setAvailableColors] = useState(ChartingToolColors);
  const [idsSortOrder, setIdsSortOrder] = useState<string[]>([]);
  const [recentId, setRecentId] = useState('');
  const { value: investmentTrustFilter } = useDropDownContext();
  const { value: datePickerValue } = useDatePickerContext();
  const { isins, benchmark, portfolios } = useChartTrustFundsFromSearch();
  const period = datePickerValue.range
    ? parseRangeValues(datePickerValue.range, datePickerValue.mode)
    : [];
  const absoluteData = useGetAbsoluteChartDataWithPortfolio({
    isins,
    period,
    portfolios,
    benchmarkId: benchmark,
    investmentTrustReturn: investmentTrustFilter.absolute,
  });
  const legendData = absoluteData?.data?.legendData || [];
  const chartColors = useChartColorsForChartingTool({
    isins,
    portfolioLegendIds: portfolios,
    legend: legendData,
    availableColors,
    onSetIdsSortOrder: setIdsSortOrder,
    recentId,
    idsSortOrder,
    sortByOrder: !loadedFromUrlRef.current,
  });
  const handleRemoveColors = (color: string) => {
    if (loadedFromUrlRef.current) {
      loadedFromUrlRef.current = false;
    }
    setAvailableColors(p => {
      const target = p.find(c => c.bright === color);
      if (!target) return p;
      const filteredColors = p.filter(c => c.bright !== color);
      return [...filteredColors, target];
    });
  };
  useEffect(() => {
    if (loadedFromUrlRef.current && Object.values(chartColors).length) {
      loadedFromUrlRef.current = false;
    }
  }, [isins, portfolios]);
  return (
    <ChartingContext.Provider
      value={{
        setColorsRemoved: handleRemoveColors,
        setRecentId,
        chartColors,
        chartLegend: absoluteData?.data?.legendData || [],
      }}
    >
      {children}
    </ChartingContext.Provider>
  );
};

export default ChartingContextProvider;
