import { InputRef, Tooltip } from 'antd';
import cx from 'classnames';
import React, { useState } from 'react';

import { ReactComponent as IconError } from '@/assets/svg/icons/icon-error-v3.svg';
import { Input } from '@/components';
import useAutoFocusRef from '@/hooks/useAutoFocusRef';

import style from './style.module.less';

interface EditableInputColumnProps<T> {
  id: string;
  onBlurEvent: (value: number) => void;
  onEnterKeyDown: (value: number) => void;
  value?: T;
  disableEdit?: boolean;
  isInvalid?: boolean;
  isInvalidTooltip?: string;
}

const EditableNumberInputColumn: React.FCWithChild<
  EditableInputColumnProps<number>
> = ({
  id,
  value,
  onEnterKeyDown,
  onBlurEvent,
  disableEdit,
  isInvalid,
  isInvalidTooltip,
}) => {
  const [isInputShown, setIsInputShown] = useState(false);
  const [inputValue, setInputValue] = useState(value?.toString() ?? '');
  const toggleInputShow = () => {
    !disableEdit && setIsInputShown(!isInputShown);
  };

  const inputRef = useAutoFocusRef<InputRef>([], isInputShown);

  const checkValue = (value: string) => {
    if (value.indexOf('-') > -1) {
      return false;
    }
    const regex = /^\d{0,3}(\.\d{0,3})?$/;
    if ((regex.test(value) || value === '') && +value >= 0) {
      return true;
    }
    return false;
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    if (checkValue(value)) {
      setInputValue(value);
    }
  };

  const padding = isInvalid ? 'pl-2' : 'pl-2 pr-4';

  return (
    <div
      data-id="FOO"
      className={cx(
        'h-full grid w-full',
        isInvalid && 'bg-[#FFF8F2]',
        'grid grid-cols-[1fr_min-content] items-center min-h-10',
      )}
    >
      {isInputShown ? (
        <div className={cx('py-1 w-full', padding)}>
          {/* Cannot replace with CustomInput - it has a lot of validation and number related logic
            we must put more effort to apply it to CustomInput and make sure styling is not modified
          */}
          <Input
            autoFocus
            ref={inputRef}
            id={`input-${id}`}
            name={`input-${id}`}
            className={cx(
              style['editable-input'],
              'focus:outline-none !text-xs',
              'tracking-[1px] py-1 border border-primary rounded max-w-30 text-right !w-30',
            )}
            containerClassName="flex justify-end !mb-0"
            placeholder="0.000 %"
            step={0.001}
            type="text"
            inputMode="decimal"
            pattern="/^\d{0,3}(\.\d{0,3})?$/"
            min={0}
            max={100}
            onChange={handleInputChange}
            onBlur={(e: React.FocusEvent<HTMLInputElement>) => {
              const val = +(e.currentTarget.value || 0);
              if ((value || 0) === val) {
                toggleInputShow();
                return;
              }
              onBlurEvent(val);
              setIsInputShown(false);
            }}
            onKeyDown={(e: React.KeyboardEvent<HTMLInputElement>) => {
              if (!checkValue(e.currentTarget.value)) {
                return;
              }
              const val = +(e.currentTarget.value || 0);
              if (e.key === 'Enter') {
                setIsInputShown(false);
                if ((value || 0) === val) {
                  return;
                }
                onEnterKeyDown(val);
              }
            }}
            value={inputValue}
          />
        </div>
      ) : (
        <button
          data-test-id={id}
          onClick={toggleInputShow}
          className={cx(
            'bg-transparent w-full min-h-12 cursor-pointer hover:bg-neutral-hover',
            !value && 'p-[calc(1rem+8px)_1rem]',
            'text-right',
            padding,
          )}
        >
          {/* Min-w prevents layout shift */}
          <p className="min-w-30">{value ? `${value} %` : ''}</p>
        </button>
      )}
      {isInvalid && (
        <Tooltip title={isInvalidTooltip} placement="bottom" className="pr-4">
          <div className="w-fit p-1">
            <IconError className="w-4 h-4 text-danger" />
          </div>
        </Tooltip>
      )}
    </div>
  );
};

export default EditableNumberInputColumn;
