import { WATCHLIST_TAB_KEY } from '@aminsights/shared';
import { useContext } from 'react';

import RenderStyleBox from '../Watchlist/RenderStyleBox';
import { TabContext } from '../Watchlist/WatchlistTabWrapper';
import WatchlistCreditQualityTable from '../WatchlistTables/WatchlistCreditQuality/WatchlistCreditQualityTable';
import WatchlistGeographyFITable from '../WatchlistTables/WatchlistGeography/GeographyFixedIncome/WatchlistGeographyFITable';
import WatchlistMaturityTable from '../WatchlistTables/WatchlistMaturity/WatchlistMaturityTable';
import WatchlistSectorsFITable from '../WatchlistTables/WatchlistSectors/SectorsFixedIncome/WatchlistSectorsFITable';

const childrenList = [
  {
    label: (
      <span
        className="is-watchlist-single-tab-item"
        data-test-id="tabFixedIncomeStyle"
      ></span>
    ),
    key: WATCHLIST_TAB_KEY.STYLE_FI,
    children: <RenderStyleBox />,
  },
  {
    label: (
      <span
        className="is-watchlist-single-tab-item"
        data-test-id="tabSectorsEquityFi"
      ></span>
    ),
    key: WATCHLIST_TAB_KEY.SECTORS_FI,
    children: <WatchlistSectorsFITable isActive={true} />,
  },
  {
    label: (
      <span
        className="is-watchlist-single-tab-item"
        data-test-id="tabGeographyFi"
      ></span>
    ),
    key: WATCHLIST_TAB_KEY.GEOGRAPHY_FI,
    children: <WatchlistGeographyFITable isActive={true} />,
  },
  {
    label: (
      <span
        className="is-watchlist-single-tab-item"
        data-test-id="tabMaturity"
      ></span>
    ),
    key: WATCHLIST_TAB_KEY.MATURITY,
    children: <WatchlistMaturityTable isActive={true} />,
  },
  {
    label: (
      <span
        className="is-watchlist-single-tab-item"
        data-test-id="tabCreditQuality"
      ></span>
    ),
    key: WATCHLIST_TAB_KEY.CREDIT_QUALITY,
    children: <WatchlistCreditQualityTable isActive={true} />,
  },
];

const FixedIncomeChildren = () => {
  const { activeTabMenuItem } = useContext(TabContext);
  return childrenList.find(child => child.key === activeTabMenuItem)?.children;
};

export default FixedIncomeChildren;
