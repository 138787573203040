import dayjs, { Dayjs } from 'dayjs';
import Decimal from 'decimal.js';
import { cloneDeep } from 'lodash';
import type { SetRequired } from 'type-fest';

import { MS_DATE_FORMAT } from '../../constants';
import { findClosestPastDate } from './dateUtils';

/**
 * README - while these functions are written with minimal assumption on what RIPS looks like
 * we know still what properties they need from RIPS. Avoid changing the function signature
 * or implementation unless you are confident that it will not break those that use it.
 */

/**
 * We define the type in isolation instead importing from the contracts package
 * If the type/contract changes these functions will not fail to compile rather
 * the usages of the function have to be adjusted. This loosley couples it but
 * it does not break due to changes outside of its own concern.
 */
export type RipsDataLike = {
  secId: string;
  unitGbp: number;
  performanceId: string;
  date: string; // YYYY-MM-DD

  // Properties that are going to be added by the functions
  parsedDate?: Dayjs;
  parsedValue?: Decimal;

  // Depending on the function this may be added
  isFillerDate?: boolean; // Added by equalizeRipsDataArrayWithNaNValues
  isPatchedValue?: boolean; // Added by findAndPatchAndTrimRipsDataAnomalies
};
export type FundModelLike = {
  shareClassDetails: {
    isin: string;
  };
  shareClassMsId: string;
  performanceIdDetails: {
    isBaseCurrency: boolean;
    performanceId: string;
  }[];
};
/**
 * Segregates RIPS data by ISIN based on the provided funds.
 *
 * @param funds An array of FundModelLike objects representing the funds.
 * @param ripsData An array of RipsDataLike objects containing the RIPS data to be segregated.
 * @returns A Map where the keys are ISINs and the values are arrays of RipsDataLike objects.
 *
 * @description This function segregates RIPS data points into arrays based on the ISIN of each fund.
 *              It initializes an object with keys corresponding to the ISINs and empty arrays as values.
 *              Each RIPS data point is then pushed into the array of its corresponding ISIN which is matched
 *              by a fund[`shareClassMsId`] === ripsData[`secId`].
 *
 * @throws {Error} If a RIPS data point is missing any required properties, an error is thrown.
 */
export const segregateRipsDataAndParseByIsin = (
  funds: FundModelLike[],
  ripsData: Partial<RipsDataLike>[],
): Map<string, RipsDataLike[]> => {
  const ripsDataByIsin = new Map<string, RipsDataLike[]>();
  funds.forEach(f => ripsDataByIsin.set(f.shareClassDetails.isin, []));

  const fundsBySid = new Map(funds.map(f => [f.shareClassMsId, f]));

  // p for ripsDataPoint
  for (const p of ripsData) {
    if (!p.secId) {
      throw new Error('secId is undefined');
    }
    const fund = fundsBySid.get(p.secId);

    if (fund && p.unitGbp && p.date && p.performanceId) {
      // p.parsedDate = dayjs(p.date);
      // p.parsedValue = new Decimal(p.unitGbp);
      const isin = fund.shareClassDetails.isin;
      const ripsDataForIsin = ripsDataByIsin.get(isin);

      if (ripsDataForIsin) {
        ripsDataForIsin.push(
          p as SetRequired<
            RipsDataLike,
            'unitGbp' | 'date' | 'performanceId' | 'secId'
          >,
        );
      } else {
        ripsDataByIsin.set(isin, [
          p as SetRequired<
            RipsDataLike,
            'unitGbp' | 'date' | 'performanceId' | 'secId'
          >,
        ]);
      }
    } else {
      // Unlikely to happen but if it does we do not want to show any potentially invalid data
      throw new Error(
        `A RIPS data point is missing required properties (secId, unitGbp, date, performanceId) or fund not found - ${JSON.stringify(
          p,
          null,
          2,
        )}`,
      );
    }
  }

  return ripsDataByIsin;
};

/**
 * Finds the farthest common date among the date properties of objects in arrays.
 *
 * @param data An object containing arrays of objects with a 'date' property.
 * @returns The farthest common date as a string in MS_DATE_FORMAT format, or null if no common date is found.
 *
 * @description Finds the latest start date among all arrays and checks if it exists in each array.
 *              Returns the latest common date if found, or null otherwise.
 *
 * @note The function assumes that each array in the input object is sorted in ascending order by date.
 *       It uses the Dayjs library for date comparison and formatting.
 */
export const findFarthestCommonDate = <T extends { date: string }>(data: {
  [isin: string]: T[];
}) => {
  let latestStartDate: dayjs.Dayjs | null = null;

  // Find the latest start date across all ISINs
  for (const arr of Object.values(data)) {
    const startDate = dayjs(arr[0].date); // Assuming the data is sorted in ascending order
    if (!latestStartDate || startDate.isAfter(latestStartDate)) {
      latestStartDate = startDate;
    }
  }

  // Check if the latest start date is present in all arrays
  for (const arr of Object.values(data)) {
    if (!arr.some(obj => dayjs(obj.date).isSame(latestStartDate, 'day'))) {
      return null;
    }
  }

  return latestStartDate?.format(MS_DATE_FORMAT);
};

/**
 * Equalizes the RIPS data arrays by filling missing dates with NaN values.
 *
 * @param ripsDataByIsin An object containing arrays of RipsDataLike objects, keyed by ISIN.
 * @param farthestDate The farthest date to equalize the arrays to, as a Dayjs object.
 * @returns A Map with the same keys as ripsDataByIsin, but with equalized arrays.
 *
 * @description This function takes an object of RIPS data arrays, keyed by ISIN, and a farthest date.
 *              It creates a new object (updatedRipsDataByIsin) to store the equalized arrays.
 *              For each ISIN, it checks if the first date of the RIPS data matches the farthest date.
 *              If they match, the original array is used without modification.
 *              If they don't match, missing dates are created with NaN values and added to the beginning
 *              of the array until the farthest date is reached. The equalized array is then stored in
 *              the updatedRipsDataByIsin object. The original input object remains unmodified.
 *
 */
export const equalizeRipsDataArrayWithNaNValues = (
  funds: FundModelLike[],
  ripsDataByIsin: Map<string, RipsDataLike[]>,
  range: {
    from: Dayjs;
    to: Dayjs;
  },
): Map<string, Array<RipsDataLike>> => {
  for (const [isin, ripsData] of ripsDataByIsin) {
    if (ripsData.length === 0) {
      /**
       * If there is no data for the ISIN, we need to fill the range with NaN values
       * for the data fix and calculations to proceed.
       * We expect this to be a very rare case.
       */
      const matchingFund = funds.find(f => f.shareClassDetails.isin === isin);

      if (!matchingFund) {
        throw new Error(`No matching fund found for the ISIN ${isin}`);
      }

      const performanceId = matchingFund.performanceIdDetails.find(
        p => p.isBaseCurrency,
      )?.performanceId;

      if (!performanceId) {
        throw new Error(
          `No base currency performanceId found for the ISIN ${isin}`,
        );
      }

      const daysInRange = range.to.diff(range.from, 'days') + 1;
      const missingDataForRange = Array.from(
        { length: daysInRange },
        (_, i) => ({
          secId: matchingFund.shareClassMsId,
          unitGbp: NaN,
          date: range.from.add(i, 'days').format(MS_DATE_FORMAT),
          performanceId, // or some default value, since there's no reference performanceId
          isFillerDate: true,
        }),
      );

      ripsDataByIsin.set(isin, missingDataForRange);
      continue;
    }

    const referenceSecId = ripsData[0].secId;
    const referencePerformanceId = ripsData[0].performanceId;

    const firstDate = dayjs(ripsData[0].date);
    let missingFartherDates: typeof ripsData = [];
    if (range.from.isBefore(firstDate)) {
      // Create missing dates if the first date does not match the farthestDate
      const count = firstDate.diff(range.from, 'days');
      missingFartherDates = Array.from({ length: count }, (_, i) => ({
        secId: referenceSecId,
        unitGbp: NaN,
        date: range.from.add(i, 'days').format(MS_DATE_FORMAT),
        performanceId: referencePerformanceId,
        isFillerDate: true,
      }));
    }

    const lastDate = dayjs(ripsData[ripsData.length - 1].date);
    let missingCloserDates: typeof ripsData = [];
    if (range.to.isAfter(lastDate)) {
      const count = range.to.diff(lastDate, 'days');
      missingCloserDates = Array.from({ length: count }, (_, i) => ({
        secId: referenceSecId,
        unitGbp: NaN,
        date: lastDate.add(i + 1, 'days').format(MS_DATE_FORMAT),
        performanceId: referencePerformanceId,
        isFillerDate: true,
      }));
    }

    ripsData.unshift(...missingFartherDates);
    ripsData.push(...missingCloserDates);
  }

  return ripsDataByIsin;
};

export const equalizeRipsDataArrayWithNaNValuesV2 = (
  ripsDataByIsin: Map<string, RipsDataLike[]>,
  range: {
    from: Dayjs;
    to: Dayjs;
  },
): Map<string, Array<RipsDataLike>> => {
  for (const [, ripsData] of ripsDataByIsin) {
    const referenceSecId = ripsData[0].secId;
    const referencePerformanceId = ripsData[0].performanceId;

    const firstDate = dayjs(ripsData[0].date);
    let missingFartherDates: typeof ripsData = [];
    if (range.from.isBefore(firstDate)) {
      // Create missing dates if the first date does not match the farthestDate
      const count = firstDate.diff(range.from, 'days');
      missingFartherDates = Array.from({ length: count }, (_, i) => ({
        secId: referenceSecId,
        unitGbp: NaN,
        date: range.from.add(i, 'days').format(MS_DATE_FORMAT),
        performanceId: referencePerformanceId,
        isFillerDate: true,
      }));
    }

    const lastDate = dayjs(ripsData[ripsData.length - 1].date);
    let missingCloserDates: typeof ripsData = [];
    if (range.to.isAfter(lastDate)) {
      const count = range.to.diff(lastDate, 'days');
      missingCloserDates = Array.from({ length: count }, (_, i) => ({
        secId: referenceSecId,
        unitGbp: NaN,
        date: lastDate.add(i + 1, 'days').format(MS_DATE_FORMAT),
        performanceId: referencePerformanceId,
        isFillerDate: true,
      }));
    }

    ripsData.unshift(...missingFartherDates);
    ripsData.push(...missingCloserDates);
  }

  return ripsDataByIsin;
};

/**
 * Trims the RIPS data for each ISIN based on the specified dates.
 *
 * @param ripsDataByIsin - An object containing RIPS data grouped by ISIN.
 *   - The keys of the object represent the ISINs.
 *   - The values are arrays of RipsDataLike objects.
 * @param datesForMatchingWeekday - An array of dates as strings representing the desired weekdays to keep.
 * @returns - The updated ripsDataByIsin object with trimmed RIPS data for each ISIN.
 *
 * The function creates a copy of the input ripsDataByIsin object to avoid modifying the original.
 * It then iterates over each ISIN in the copied object.
 * For each ISIN, the function filters the corresponding RIPS data array.
 * The filtering is based on the datesForMatchingWeekday array.
 * Only the RIPS data objects whose date matches any of the dates in datesForMatchingWeekday are kept.
 * The filtered RIPS data array is then assigned back to the corresponding ISIN in the updatedRipsDataByIsin object.
 * Finally, the function returns the updatedRipsDataByIsin object with the trimmed RIPS data for each ISIN.
 *
 * Note: The function assumes that the RIPS data objects have a 'date' property of type string.
 */
export const trimRipsDataByDates = <T extends { date: string }>(
  ripsDataByIsin: Map<string, T[]>,
  datesForMatchingWeekday: string[],
  shouldClone: boolean = false,
) => {
  // Clone the data if shouldClone is true
  const dataToProcess = shouldClone
    ? cloneDeep(ripsDataByIsin)
    : ripsDataByIsin;
  const datesSet = new Set(datesForMatchingWeekday);

  for (const [, ripsDataOfIsin] of dataToProcess) {
    let i = 0;
    while (i < ripsDataOfIsin.length) {
      if (!datesSet.has(ripsDataOfIsin[i].date)) {
        ripsDataOfIsin.splice(i, 1);
      } else {
        i++;
      }
    }
  }

  return dataToProcess;
};

/**
 * Finds, patches, and trims anomalies in RIPS data for each ISIN.
 *
 * @param ripsDataByIsin An object containing arrays of RipsDataLike objects, keyed by ISIN.
 * @param datesForMatchingWeekday An array of dates to match and patch anomalies for.
 * @returns A Map with the same keys as ripsDataByIsin, but with patched RIPS data.
 *
 * @description For each ISIN, the function checks if any expected date from datesForMatchingWeekday is missing in the RIPS data.
 *              If a missing date is found, it patches the anomaly by adding a new RIPS data point with the closest available past data.
 *              The patched data point is marked with an isPatchedValue flag.
 *              If no closest past data is found, an error is thrown.
 *              After patching, the RIPS data for ISINs with anomalies is sorted by date.
 */
export const findAndPatchAndTrimRipsDataAnomalies = (
  ripsDataByIsin: Map<string, RipsDataLike[]>,
  datesForMatchingWeekday: string[],
): Map<string, Array<RipsDataLike>> => {
  const anomalies: Array<{
    missingDate: string;
    isin: string;
    message: string;
    closestDataInThePast: RipsDataLike;
  }> = [];

  for (const [isin, ripsData] of ripsDataByIsin) {
    datesForMatchingWeekday.forEach(date => {
      // If a rips data point is missing a date that should exist
      if (!ripsData.find(d => d.date === date) && ripsData.length > 0) {
        const closestDate = findClosestPastDate(ripsData, date);
        if (closestDate) {
          anomalies.push({
            missingDate: date,
            isin,
            message: `Missing data for the ${date} for rips data of isin ${isin} - utilizing the closest available data point which is ${closestDate?.date}. Additionally please check close dates to the missing one.`,
            closestDataInThePast: closestDate,
          });

          ripsData.push({
            secId: closestDate.secId,
            unitGbp: closestDate.unitGbp,
            date,
            performanceId: closestDate.performanceId,
            isPatchedValue: true,
          });
        } else {
          throw new Error(
            `No data found to use as a fillter for missing date - ${date} of isin ${isin}`,
          );
        }
      }
    });
  }

  const isinsWithAnomalies = anomalies.map(a => a.isin);
  for (const isin of isinsWithAnomalies) {
    const patchedIsin = ripsDataByIsin.get(isin);
    if (patchedIsin) {
      ripsDataByIsin.set(
        isin,
        patchedIsin.sort((a, b) => dayjs(a.date).diff(dayjs(b.date))),
      );
    }
  }

  return ripsDataByIsin;
};

export const findAndPatchAndTrimRipsDataAnomaliesV2 = (
  ripsDataByIsin: Map<string, RipsDataLike[]>,
  datesForMatchingWeekday: string[],
): Map<string, Array<RipsDataLike>> => {
  const anomalies: Array<{
    missingDate: string;
    isin: string;
    message: string;
    closestDataInThePast: RipsDataLike;
  }> = [];

  for (const [isin, ripsData] of ripsDataByIsin) {
    datesForMatchingWeekday.forEach(date => {
      // If a rips data point is missing a date that should exist
      if (!ripsData.find(d => d.date === date) && ripsData.length > 0) {
        const closestDate = findClosestPastDate(ripsData, date);
        if (closestDate) {
          anomalies.push({
            missingDate: date,
            isin,
            message: `Missing data for the ${date} for rips data of isin ${isin} - utilizing the closest available data point which is ${closestDate?.date}. Additionally please check close dates to the missing one.`,
            closestDataInThePast: closestDate,
          });

          ripsData.push({
            secId: closestDate.secId,
            unitGbp: closestDate.unitGbp,
            date,
            performanceId: closestDate.performanceId,
            isPatchedValue: true,
          });
        } else {
          throw new Error(
            `No data found to use as a fillter for missing date - ${date} of isin ${isin}`,
          );
        }
      }
    });
  }

  const isinsWithAnomalies = anomalies.map(a => a.isin);
  for (const isin of isinsWithAnomalies) {
    const patchedIsin = ripsDataByIsin.get(isin);
    if (patchedIsin) {
      ripsDataByIsin.set(
        isin,
        patchedIsin.sort((a, b) => dayjs(a.date).diff(dayjs(b.date))),
      );
    }
  }

  return ripsDataByIsin;
};

/**
 * This function takes in a segregated RIPS data map. It trims ONLY the last
 * day. This is important because there is a strong assumption that it'll be
 * only 1 day lag in the data.
 */
export const trimLastDayIfMismatch = (
  ripsDataByIsin: Map<string, RipsDataLike[]>,
): {
  trimmedIsins: string[];
  hasTrimmed: boolean;
} => {
  let mostRecentDate: string | undefined = undefined;

  // Determine the most recent date in any of the ISIN data arrays
  for (const entries of ripsDataByIsin.values()) {
    if (entries.length === 0) {
      continue;
    }
    const lastEntryDate = entries[entries.length - 1].date;
    if (!mostRecentDate || lastEntryDate > mostRecentDate) {
      mostRecentDate = lastEntryDate;
    }
  }

  if (!mostRecentDate) {
    return { trimmedIsins: [], hasTrimmed: false };
  }

  // Check if all ISINs have this most recent date
  let isMostRecentDatePresentForAll = true;
  for (const entries of ripsDataByIsin.values()) {
    const entry: RipsDataLike | undefined = entries[entries.length - 1];
    if (entry?.date !== mostRecentDate) {
      isMostRecentDatePresentForAll = false;
      break;
    }
  }

  const trimmedIsins: string[] = [];

  // If any ISIN does not include the most recent date, remove that date from all ISINs
  if (!isMostRecentDatePresentForAll) {
    ripsDataByIsin.forEach((entries, isin) => {
      const entry: RipsDataLike | undefined = entries[entries.length - 1];
      if (entry?.date === mostRecentDate) {
        entries.pop(); // Remove the last entry if it is the most recent date
        trimmedIsins.push(isin);
      }
    });
  }

  return {
    trimmedIsins: trimmedIsins,
    hasTrimmed: trimmedIsins.length > 0,
  };
};
