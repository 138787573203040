import { FieldPath } from 'react-hook-form';

import { Field } from '@/types/form';

export type LoginFormProps = {
  email: string;
  password: string;
};

export type ForgotPasswordFormProps = {
  email: string;
};

export type ResetPasswordFormProps = {
  password: string;
  confirmPassword: string;
};

export type RequestAccountFormProps = {
  firstName: string;
  lastName: string;
  phone: string;
  email: string;
  company: string;
};

export type WelcomeFormProps = {
  firstName: string;
  lastName: string;
  email: string;
  password: string;
  confirmPassword: string;
};

export type ChangePasswordFormProps = {
  currentPassword: string;
  newPassword: string;
  confirmNewPassword: string;
};

export type UpdateProfileFormProps = {
  firstName: string;
  lastName: string;
  phone: string;
  email: string;
  company: string;
};

interface LoginField extends Field {
  name: FieldPath<LoginFormProps>;
}

interface ForgotPasswordField extends Field {
  name: FieldPath<ForgotPasswordFormProps>;
}

interface ResetPasswordField extends Field {
  name: FieldPath<ResetPasswordFormProps>;
}

interface ChangePasswordField extends Field {
  name: FieldPath<ChangePasswordFormProps>;
}

interface UpdateProfileField extends Field {
  name: FieldPath<UpdateProfileFormProps>;
}

export const forgotPasswordFields: Array<ForgotPasswordField> = [
  {
    type: 'email',
    name: 'email',
    label: 'Email',
    validation: {
      required: 'Email address is required',
      pattern: {
        value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
        message: 'Invalid email address',
      },
    },
  },
];

export const resetPasswordFields: Array<ResetPasswordField> = [
  {
    type: 'password',
    name: 'password',
    label: 'Password',
    validation: {
      required: 'Password is required',
      pattern: {
        value:
          /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?([^\w\s]|[_])).{8,}$/,
        message: 'Make sure it follows the criteria below',
      },
    },
  },
  {
    type: 'password',
    name: 'confirmPassword',
    label: 'Confirm Password',
    validation: {
      required: 'Confirm password is required',
    },
  },
];

export const loginFields: Array<LoginField> = [
  {
    type: 'email',
    name: 'email',
    label: 'Email',
    validation: {
      required: 'Email address is required',
      pattern: {
        value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
        message: 'Invalid email address',
      },
    },
  },
  {
    type: 'password',
    name: 'password',
    label: 'Password',
    validation: {
      required: 'Password is required',
    },
  },
];

export const requestAccountFields = [
  {
    type: 'text',
    name: 'firstName',
    label: 'First Name',
    validation: {
      required: 'First Name is required',
    },
  },
  {
    type: 'text',
    name: 'lastName',
    label: 'Last Name',
    validation: {
      required: 'Last Name is required',
    },
  },
  {
    type: 'email',
    name: 'email',
    label: 'Work Email',
    validation: {
      required: 'Email address is required',
      pattern: {
        value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
        message: 'Invalid email address',
      },
    },
  },
  {
    type: 'text',
    name: 'phone',
    label: 'Phone',
    validation: {
      required: 'Phone is required',
    },
  },
  {
    type: 'text',
    name: 'company',
    label: 'Company',
    validation: {
      required: 'Company is required',
    },
  },
] as const;

export const welcomeFields = [
  {
    type: 'text',
    name: 'firstName',
    label: 'First Name',
    validation: {
      required: 'First Name is required',
    },
  },
  {
    type: 'text',
    name: 'lastName',
    label: 'Last Name',
    validation: {
      required: 'Last Name is required',
    },
  },
  {
    type: 'email',
    name: 'email',
    label: 'Email',
    validation: {
      required: 'Email address is required',
      pattern: {
        value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
        message: 'Invalid email address',
      },
    },
  },
  {
    type: 'password',
    name: 'password',
    label: 'Password',
    validation: {
      required: 'Password is required',
      pattern: {
        value:
          /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?([^\w\s]|[_])).{8,}$/,
        message: 'Make sure it follows the criteria below',
      },
    },
  },
  {
    type: 'password',
    name: 'confirmPassword',
    label: 'Confirm Password',
    validation: {
      required: 'Confirm password is required',
    },
  },
] as const;

export const changePasswordFields: ChangePasswordField[] = [
  {
    type: 'password',
    name: 'currentPassword',
    label: 'Current Password',
    placeholder: 'Enter current password',
    validation: {
      required: 'Current password is required',
    },
    maxLength: 128,
    autoFocus: true,
  },
  {
    type: 'password',
    name: 'newPassword',
    label: 'New Password',
    placeholder: 'Enter new password',
    validation: {
      required: 'New Password is required',
      pattern: {
        value:
          /^(?!.*?(.)\1\1)(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?([^\w\s]|[_])).\S{9,}$/,
        message: 'New password does not meet the requirements above',
      },
    },
    maxLength: 128,
  },
  {
    type: 'password',
    name: 'confirmNewPassword',
    label: 'Confirm Password',
    placeholder: 'Confirm password',
    validation: {
      required: 'Confirm new password is required',
    },
    maxLength: 128,
  },
];

export const updateProfileFields: UpdateProfileField[][] = [
  [
    {
      name: 'firstName',
      type: 'text',
      label: 'First Name',
      placeholder: 'First Name',
      validation: {
        required: 'First name is required',
      },
      maxLength: 20,
      autoFocus: true,
    },
    {
      name: 'lastName',
      type: 'text',
      label: 'Last Name',
      placeholder: 'Last Name',
      validation: {
        required: 'Last name is required',
      },
      maxLength: 20,
    },
  ],
  [
    {
      type: 'email',
      name: 'email',
      label: 'Email',
      placeholder: 'email address',
      validation: {
        required: 'Email address is required',
        pattern: {
          value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
          message: 'Invalid email address',
        },
      },
      maxLength: undefined,
    },
  ],
  [
    {
      type: 'text',
      name: 'phone',
      label: 'Phone Number',
      placeholder: '[+] [country code] [subscriber number]',
      validation: {
        required: 'Phone number is required.',
        pattern: {
          value: /^\+[0-9]{1,15}$/,
          message: 'Format [+] [country code] [subscriber number]',
        },
      },
      maxLength: 16,
    },
  ],
  [
    {
      type: 'text',
      name: 'company',
      label: 'Company',
      placeholder: 'Company Name',
      validation: {
        required: false,
      },
      maxLength: undefined,
    },
  ],
];

export const AUTH0_BROADCAST_CHANNEL = 'auth0:broadcastchannel';
export const AUTH0_LOGIN_EVENT = 'auth0:login';
