import { Tooltip } from 'antd';
import React from 'react';

import { ReactComponent as InfoIcon } from '@/assets/svg/icons/icon-info.svg';
import getScreenWidthMode, {
  ScreenWidthEnum,
} from '@/utils/getScreenWidthMode';

import style from './style.module.less';

interface InfoTooltipProps {
  title: string;
  className?: string;
  placement?: 'rightTop' | 'leftTop';
}

const InfoTooltip: React.FC<InfoTooltipProps> = ({
  title,
  placement = 'rightTop',
}) => {
  const screenWidthMode = getScreenWidthMode();
  const isMobile = screenWidthMode[ScreenWidthEnum.MaxMd];

  return (
    <Tooltip
      title={title}
      placement={placement}
      color="#3E414B"
      overlayClassName={style['tooltip-rounded']}
      arrow={{ pointAtCenter: true }}
      align={{ offset: [10, -16] }}
      trigger={isMobile ? 'click' : 'hover'}
      className="text-neutral"
      openClassName={'text-neutral-100'}
    >
      <InfoIcon className={'text-inherit'} />
    </Tooltip>
  );
};

export default InfoTooltip;
