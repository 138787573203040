// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".e80FAZLeTL7cVxq4yRWD {\n  display: flex;\n  flex-direction: column;\n}\n@media (min-width: 576px) {\n  .e80FAZLeTL7cVxq4yRWD {\n    flex-direction: row;\n    align-items: center;\n    justify-content: space-between;\n  }\n}\n.e80FAZLeTL7cVxq4yRWD {\n  margin-top: 0.5rem;\n  margin-bottom: 0.5rem;\n}\n.e80FAZLeTL7cVxq4yRWD .dropdown {\n  width: 100%;\n  padding-top: 1rem;\n}\n@media (min-width: 768px) {\n  .e80FAZLeTL7cVxq4yRWD .dropdown {\n    width: auto;\n    padding-top: 0px;\n  }\n}\n.ldUi4EuIHxa8mae1khMm {\n  display: flex;\n  flex-direction: row;\n  align-items: center;\n}\n.mO7_Y3Ya1QAONsTpv7yX {\n  margin: 30px 0 30px;\n  border-bottom: 1px dashed #ccc;\n}\n.KkMi5b4yOckPXwhI8Iyk.dropdown-button {\n  width: 100%;\n}\n@media (min-width: 768px) {\n  .KkMi5b4yOckPXwhI8Iyk.dropdown-button {\n    width: 9.5rem;\n  }\n}\n", "",{"version":3,"sources":["webpack://./src/partials/ScatterPlots/risk-return-scatter-plot/style.module.less"],"names":[],"mappings":"AACE;EAAA,aAAA;EAAA;AAAA;AAAA;EAAA;IAAA,mBAAA;IAAA,mBAAA;IAAA;EAAA;AAAA;AACA;EAAA,kBAAA;EAAA;AAAA;AAGI;EAAA,WAAA;EAAA;AAAA;AAAA;EAAA;IAAA,WAAA;IAAA;EAAA;AAAA;AAMJ;EAAA,aAAA;EAAA,mBAAA;EAAA;AAAA;AAGF;EACE,mBAAA;EACA,8BAAA;AAHF;AASM;EAAA;AAAA;AAAA;EAAA;IAAA;EAAA;AAAA","sourcesContent":[".header {\n  @apply flex flex-col sm:flex-row sm:items-center sm:justify-between;\n  @apply my-2;\n  :global {\n    .dropdown {\n      @apply pt-4 w-full md:w-auto md:pt-0;\n    }\n  }\n}\n\n.subHeader {\n  @apply flex flex-row items-center;\n}\n\n.divider {\n  margin: 30px 0 30px;\n  border-bottom: 1px dashed #ccc;\n}\n\n:global {\n  :local(.dropdown) {\n    &.dropdown-button {\n      @apply w-full md:w-38;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"header": "e80FAZLeTL7cVxq4yRWD",
	"subHeader": "ldUi4EuIHxa8mae1khMm",
	"divider": "mO7_Y3Ya1QAONsTpv7yX",
	"dropdown": "KkMi5b4yOckPXwhI8Iyk"
};
export default ___CSS_LOADER_EXPORT___;
