import { ChartV2DataResponseLegendData } from '@aminsights/contract';

import notEmpty from '@/utils/notEmpty';

export const getIdOrderByLegendData = (
  legendData: ChartV2DataResponseLegendData[] | undefined,
) => {
  const d = legendData ?? [];

  const sortedData = d.sort((a, b) => {
    const aValue = parseFloat(a.value ?? '0');
    const bValue = parseFloat(b.value ?? '0');
    return aValue < bValue ? 1 : -1;
  });

  const mappedIds = sortedData.map(f => f.id).filter(notEmpty);

  return mappedIds;
};
