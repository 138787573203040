import { ButtonCheckbox } from '@/components';
import { ICheckboxData } from '@/components/Checkbox/types';

import { useExploreDefaultFiltersQuery } from '../../../context';
import useExploreFilters from '../../../hooks/useExploreFilters';

const EsgActionSurveyRatingsFilter = () => {
  const { data: defaultfiltersOptions } = useExploreDefaultFiltersQuery();
  const { tentativeFilters, getMutableFilters, updateTentativeFilters } =
    useExploreFilters();

  const esgFilters = tentativeFilters.esgFilters;

  const sortRatings = (a: string, b: string) => {
    if (a.includes(b) || b.includes(a)) return b.length - a.length;
    return a.localeCompare(b);
  };
  return (
    <ButtonCheckbox
      data={defaultfiltersOptions.shareActionSurveyRatings
        .map(i => ({
          label: i.label,
          value: i.label,
        }))
        .sort((a, b) => sortRatings(a.label, b.label))}
      onChange={(
        onChangeHandler: (prevValues: Array<ICheckboxData>) => ICheckboxData[],
      ) => {
        const changedValues: ICheckboxData[] = onChangeHandler(
          tentativeFilters.esgFilters?.shareActionRatings ?? [],
        );
        const filters = getMutableFilters();
        filters.esgFilters = filters.esgFilters ?? {};
        filters.esgFilters.shareActionRatings =
          changedValues.length > 0 ? changedValues : undefined;
        updateTentativeFilters(filters);
      }}
      value={esgFilters?.shareActionRatings}
    />
  );
};

export default EsgActionSurveyRatingsFilter;
