import { AutocompleteResponseSuggestionItem } from '@aminsights/contract';
import cx from 'classnames';
import { Dispatch, SetStateAction } from 'react';

import useSearch from '@/hooks/query-hooks/search-hooks/useSearch';
import ExploreSearchV2, {
  renderOptions,
} from '@/partials/ExploreSearchBox/ExploreSearchBoxV2';
import { useSearchBox } from '@/partials/ExploreSearchBox/useExploreSearchbox';

const CompareSearchBox = ({
  onClose,
  onAdd,
  onChange,
  dataTestId,
  defaultValue,
  onClear,
  excludedIsins,
}: {
  onClose: () => void;
  onAdd: (fund: AutocompleteResponseSuggestionItem) => void;
  dataTestId?: string;
  onChange: (term: string) => void;
  defaultValue?: string;
  onClear: () => void;
  excludedIsins: string[];
}) => {
  const searchConfig = useSearch();
  const searchBoxOpts = useSearchBox({
    onClose: () => {},
    defaultValue,
    searchConfig,
  });
  const resultOpts = searchBoxOpts.allSuggestions
    .map(opt =>
      renderOptions({
        value: opt.shareClassDetails.isin,
        title: opt.shareClassDetails.code,
        highlightTerm: searchBoxOpts.searchValue,
        primaryShareClass: opt.primaryShareClass,
      }),
    )
    .filter(opt => !excludedIsins.includes(opt.value));
  const handleOnSearchResults = async (
    term?: string,
    onError?: Dispatch<SetStateAction<string | null>>,
  ) => {
    if (term) {
      if (term) {
        const selectedFund = searchBoxOpts.allSuggestions.find(
          f => f.shareClassDetails.isin === term,
        );
        if (!selectedFund) {
          onError?.('Something went wrong while selecting fund');
          return;
        }
        onAdd(selectedFund);
      }
    }
  };
  const handleChange = (value: string) => {
    onChange(value);
    searchBoxOpts.onChange(value);
  };
  return (
    <ExploreSearchV2
      className={cx(
        '[&_input]:pr-6 [&_input]:text-ellipsis [&_input]:overflow-x-hidden',
        '[&_.explore-search-clear]:!mr-0',
      )}
      autoFocus={false}
      dataTestId={dataTestId}
      value={searchBoxOpts.searchValue}
      onChange={handleChange}
      onBlur={onClose}
      onSelect={handleOnSearchResults}
      onKeyEnter={handleOnSearchResults}
      onSubmit={handleOnSearchResults}
      options={resultOpts}
      placeholder="Search Fund Name/ISIN"
      onClear={onClear}
      showArrow={false}
      loading={searchBoxOpts.isLoading}
    />
  );
};

export default CompareSearchBox;
