import { GenderFilterType } from '@aminsights/shared';

import { Checkbox as MultiCheckBox } from '@/components/Checkbox';

import useExploreFilters from '../../../hooks/useExploreFilters';

export const MoreFiltersManagerGender = () => {
  const { tentativeFilters, getMutableFilters, updateTentativeFilters } =
    useExploreFilters();
  return (
    <div className="flex">
      <MultiCheckBox
        value={tentativeFilters.moreFilters?.genders}
        data={[
          {
            label: 'Male',
            value: GenderFilterType.Male,
          },
          {
            label: 'Female',
            value: GenderFilterType.Female,
          },
        ]}
        onChange={value => {
          const filters = getMutableFilters();
          filters.moreFilters = filters.moreFilters ?? {};
          filters.moreFilters.genders = value.length > 0 ? value : undefined;
          updateTentativeFilters(filters);
        }}
      />
    </div>
  );
};

export default MoreFiltersManagerGender;
