export class RangePair {
  from = '';
  to = '';
}

export class RangePairSerializer {
  static serialize(valuePair: RangePair): string {
    return `${valuePair.from}-${valuePair.to}`;
  }
  static deserialize(valuePairUrl: string): RangePair {
    const [from, to] = valuePairUrl.split('-');
    return { to, from };
  }
}
