import { FundBroadAssetClass, Stylebox } from '@aminsights/contract';
import {
  BoxLabels,
  BroadAssetClassColor,
  buildFundDetailsPath,
  getLongHumanReadableStyleboxName,
  getShortHumanReadableStyleboxName,
} from '@aminsights/shared';
import { Modal } from 'antd';
import cx from 'classnames';
import React, { useEffect, useMemo, useState } from 'react';
import { Link } from 'react-router-dom';

import {
  useCurrentBucketId,
  useCurrentWatchlist,
} from '@/hooks/query-hooks/watchlist-hooks/useWatchlists';
import useScreenWidth, { EScreenSize } from '@/hooks/screenWidth';

import style from './style.module.less';

interface IStyleBoxWrapperProps {
  styleBoxData: Stylebox[];
  fundTypeTitle: FundBroadAssetClass;
  fundBoxBGcolor: { [key: number]: string };
  XandYLabels: BoxLabels;
  boxBackgroundColor: BroadAssetClassColor;
}

const StyleBoxWrapper: React.FCWithChild<IStyleBoxWrapperProps> = ({
  fundTypeTitle,
  styleBoxData,
  fundBoxBGcolor,
  XandYLabels,
  boxBackgroundColor,
}) => {
  const STYLE_BOX_COUNT = 9;
  const { isCurrentWidth } = useScreenWidth();
  const isMobile =
    isCurrentWidth(EScreenSize.xs) || isCurrentWidth(EScreenSize.sm);
  const [hoverIndex, setHoverIndex] = useState<number>(0);

  const [selectedIndex, setSelectedIndex] = useState<number>(0);
  const [fundDisplayList, setFundDisplayList] = useState<Stylebox[]>([]);
  const [fundListModal, setFundListModal] = useState<boolean>(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedFundBox, setSelectedFundBox] = useState<Stylebox[]>([]);
  const currentWatchlist = useCurrentWatchlist();
  const currentBucketId = useCurrentBucketId();
  const handleCancel = (): void => {
    setIsModalOpen(false);
  };

  const filterFundByIndex = (index: number, fund: Stylebox[]): Stylebox[] => {
    const filterCondition = (item: Stylebox) =>
      fundTypeTitle === FundBroadAssetClass.Equity
        ? item.equityStyleBox === index
        : item.bondStatistics?.styleBox === index;

    return fund.filter(filterCondition);
  };

  const countStyleBoxByIndexValue = (index: number): number => {
    const filterCondition = (item: Stylebox) =>
      fundTypeTitle === FundBroadAssetClass.Equity
        ? item.equityStyleBox === index
        : item.bondStatistics?.styleBox === index;

    return styleBoxData.filter(filterCondition).length;
  };

  const onClickSetFundListDisplay = (index: number): void => {
    const filterFundBox = filterFundByIndex(index, styleBoxData);
    setSelectedFundBox(filterFundBox);
    setSelectedIndex(index);
    setIsModalOpen(isMobile);
  };

  const showFundList = (index: number): void => {
    const filteredData = filterFundByIndex(index, styleBoxData);
    if (filteredData.length > 0) {
      setHoverIndex(index);
    }
    setFundListModal(true);
    setFundDisplayList(filteredData);
  };

  const boxBGcolor = (index: number): number => {
    return countStyleBoxByIndexValue(index) % 2 == 0
      ? countStyleBoxByIndexValue(index)
      : countStyleBoxByIndexValue(index) - 1;
  };

  useEffect(() => {
    setSelectedFundBox([]);
    setSelectedIndex(0);
    setIsModalOpen(false);
  }, [currentBucketId]);

  const renderBox = (): JSX.Element[] => {
    const boxes = [];
    for (let i = 0; i < STYLE_BOX_COUNT; i += 3) {
      boxes.push(
        <div className={style['box-row-wrapper']} key={i}>
          {[i + 1, i + 2, i + 3].map(index => (
            <div
              key={index}
              className={cx(
                style['box'],
                fundTypeTitle === FundBroadAssetClass.Equity && style['box-eq'],
                fundTypeTitle === FundBroadAssetClass.FixedIncome &&
                  style['box-fi'],
              )}
              style={{
                backgroundColor: boxBackgroundColor,
              }}
            >
              <div
                className={cx(
                  'grid w-full h-full place-items-center',
                  countStyleBoxByIndexValue(index) !== 0 && 'cursor-pointer',
                  countStyleBoxByIndexValue(index) !== 0 && style['box-hover'],
                  fundTypeTitle === FundBroadAssetClass.Equity &&
                    style['box-eq-hover'],
                  fundTypeTitle === FundBroadAssetClass.FixedIncome &&
                    style['box-fi-hover'],
                  selectedIndex === index &&
                    fundTypeTitle === FundBroadAssetClass.Equity &&
                    style['box-eq-selected'],
                  selectedIndex === index &&
                    fundTypeTitle === FundBroadAssetClass.FixedIncome &&
                    style['box-fi-selected'],
                  countStyleBoxByIndexValue(index) === 0 &&
                    'pointer-events-none',
                  'text-white',
                )}
                style={{
                  backgroundColor:
                    countStyleBoxByIndexValue(index) === 0
                      ? 'unset'
                      : fundBoxBGcolor[boxBGcolor(index)],
                }}
                onClick={() => onClickSetFundListDisplay(index)}
                onMouseOver={() => {
                  showFundList(index);
                }}
                onMouseOut={() => {
                  setFundDisplayList([]);
                  setHoverIndex(selectedIndex);
                }}
              >
                {countStyleBoxByIndexValue(index) === 0
                  ? ''
                  : countStyleBoxByIndexValue(index)}
              </div>
            </div>
          ))}
        </div>,
      );
    }
    return boxes;
  };

  const XorYLabel = (labelData: string[], axis: string): JSX.Element[] => {
    const labelClassName =
      axis === 'y' ? style['y-per-box-text'] : style['x-per-box-text'];

    return labelData.map((value, index) => (
      <div key={index} className={labelClassName}>
        {value}
      </div>
    ));
  };
  const bucket = useMemo(() => {
    return currentWatchlist.data?.buckets.find(
      bucket => bucket.id === currentBucketId,
    );
  }, [currentWatchlist.data, currentBucketId]);

  const isFeatured = (isin: string): boolean => {
    return bucket
      ? bucket.funds.some(fund => fund.isin === isin && fund.isFeatured)
      : false;
  };

  const FundGroupList = (selectedFundBox: Stylebox[]): JSX.Element => {
    return (
      <div className={cx('mb-2', style['fund-group'])}>
        <div className={cx('mt-14', style['selected-box-fund-list'])}>
          <div className={cx('mb-3', style['title'])}>
            {fundTypeTitle === FundBroadAssetClass.Equity
              ? getShortHumanReadableStyleboxName(hoverIndex, XandYLabels)
              : getLongHumanReadableStyleboxName(hoverIndex, XandYLabels)}
          </div>
          {selectedFundBox?.map((_item: Stylebox, index: number) => (
            <p
              className={cx('text-xs leading-5 p-2', style['item'], {
                [style['featured']]: isFeatured(_item.shareClassDetails.isin),
              })}
              key={index}
            >
              <Link to={buildFundDetailsPath(_item.shareClassDetails.isin)}>
                {_item.fundName}
              </Link>
            </p>
          ))}
        </div>
      </div>
    );
  };

  return (
    <>
      {fundListModal && (
        <Modal
          title={
            fundTypeTitle === FundBroadAssetClass.Equity
              ? `${getShortHumanReadableStyleboxName(
                  hoverIndex,
                  XandYLabels,
                )} (${selectedFundBox.length})`
              : `${getLongHumanReadableStyleboxName(
                  hoverIndex,
                  XandYLabels,
                )} (${selectedFundBox.length})`
          }
          className="max-sm:full-page-modal action-modal [&_.ant-modal-body]:pb-10"
          open={isModalOpen}
          onCancel={handleCancel}
          footer={null}
          centered={true}
        >
          <div
            onClick={e => {
              e.stopPropagation();
            }}
          >
            {selectedFundBox?.map((_item: Stylebox, index: number) => (
              <p
                className={cx('leading-5 pb-3', style['item'], {
                  [style['featured']]: isFeatured(_item.shareClassDetails.isin),
                })}
                key={index}
              >
                <Link to={buildFundDetailsPath(_item.shareClassDetails.isin)}>
                  {_item.fundName}
                </Link>
              </p>
            ))}
          </div>
        </Modal>
      )}
      <div className={cx(style['style-wrapper'], 'flex gap-4')}>
        <div>
          <div className={style['title']}>
            Morningstar {fundTypeTitle} Style Box
          </div>
          <div
            className={cx(
              style['box-container'],
              style['box-container-fund-details'],
            )}
          >
            <div
              className={cx(
                fundTypeTitle === FundBroadAssetClass.Equity &&
                  style['box-wrapper-eq'],
                fundTypeTitle === FundBroadAssetClass.FixedIncome &&
                  style['box-wrapper-fi'],
              )}
            >
              {renderBox()}
            </div>
            <div className="grid grid-row-3 w-6">
              {XorYLabel(XandYLabels.yName, 'y')}
            </div>
            <div
              style={{ writingMode: 'vertical-lr' }}
              className={cx(style['outer-labels'], 'pl-1')}
            >
              {XandYLabels.outerX}
            </div>
          </div>
          <div className="pl-5 flex items-center">
            {XorYLabel(XandYLabels.xName, 'x')}
          </div>
          <div className={cx(style['outer-labels'], 'pr-7')}>
            {XandYLabels.outerY}
          </div>
        </div>
        {!isMobile && (
          <div className={style['fund-group-wrapper']}>
            {fundDisplayList?.length > 0 && FundGroupList(fundDisplayList)}
            {selectedFundBox.length > 0 &&
              fundDisplayList.length === 0 &&
              FundGroupList(selectedFundBox)}
          </div>
        )}
      </div>
    </>
  );
};
export default StyleBoxWrapper;
