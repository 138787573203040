import { getFundType, USER_PERMISSIONS } from '@aminsights/shared';
import { Tabs } from 'antd';
import React, { useContext, useEffect, useMemo, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';

import { BACK_BUTTON_HEIGHT, NAVBAR_HEIGHT, TAB_HEIGHT } from '@/constants';
import { AxiosAuthContext } from '@/context/AxiosAuthContext';
import FundType from '@/enums/FundType';
import { useFundByIsInParam } from '@/hooks/query-hooks/fund-hooks/useFundByIsin';
import { useCurrentWatchlist } from '@/hooks/query-hooks/watchlist-hooks/useWatchlists';
import Portfolio from '@/pages/app/FundAndInvestmentTrust/components/Portfolio';
import ShareClasses from '@/pages/app/FundAndInvestmentTrust/components/ShareClasses';
import SignificantChanges from '@/pages/app/FundAndInvestmentTrust/components/SignificantChanges';
import style from '@/pages/app/FundAndInvestmentTrust/style.module.less';
import { validatePermissions } from '@/utils/auth';
import getScreenWidthMode, {
  ScreenWidthEnum,
} from '@/utils/getScreenWidthMode';

import Internal from './Internal';
import Investors from './Investors';
import PerfRisk from './PerfRisk';
import Summary from './Summary';

interface IFundTabsProps {
  sectionHeight?: number;
}

interface IFundTabs {
  title: React.ReactNode;
  component: React.ReactNode;
  fundType?: FundType;
  disabled?: boolean;
  tabId: string;
  dataTestId?: string;
}

const EXTRA_PADDING = 16;
const HEIGHT = BACK_BUTTON_HEIGHT + TAB_HEIGHT + NAVBAR_HEIGHT + EXTRA_PADDING;

export const FundDetailTabs: React.FCWithChild<IFundTabsProps> = ({
  sectionHeight,
}) => {
  const history = useHistory();
  const { data: fundDetailsState, isLoading } = useFundByIsInParam();
  const currentWatchlist = useCurrentWatchlist();
  const buckets = currentWatchlist.data?.buckets;
  const [activeTab, setActiveTab] = useState<string>('summary');
  const { state: authState } = useContext(AxiosAuthContext);
  const currentUser = authState.decodedToken;
  const screenWidthMode = getScreenWidthMode();

  const { tab } = useParams<{ tab: string }>();

  enum FUND_DETAILS_DATA_TEST_ID {
    SUMMARY = 'fundDetailsTabSummary',
    PERFORMANCE_AND_RISK = 'fundDetailsTabPerfAndRisk',
    PORTFOLIO = 'fundDetailsTabPortfolio',
    SIGNIFICANT_CHANGES = 'fundDetailsTabSignificantChanges',
    ANNOUNCEMENTS = 'fundDetailsTabAnnouncements',
    SHARE_CLASSES = 'fundDetailsTabShareClasses',
    INVESTORS = 'fundDetailsTabInvestors',
    INTERNAL = 'fundDetailsTabInternal',
  }

  enum FUND_DETAILS_TAB {
    SUMMARY = 'summary',
    PERFORMANCE_AND_RISK = 'performance-and-risk',
    PORTFOLIO = 'portfolio',
    SIGNIFICANT_CHANGES = 'significant-changes',
    ANNOUNCEMENTS = 'announcements',
    SHARE_CLASSES = 'share-classes',
    INVESTORS = 'investors',
    INTERNAL = 'internal',
  }

  const evaluateFundDetailsSubRoute = (tab?: string) => {
    return tab && Object.values(FUND_DETAILS_TAB).some(p => p === tab)
      ? tab
      : FUND_DETAILS_TAB.SUMMARY;
  };

  useEffect(() => {
    setActiveTab(evaluateFundDetailsSubRoute(tab));
  }, [tab]);

  const hasPermissionsForInternal = useMemo(() => {
    return validatePermissions(
      [USER_PERMISSIONS.internal.read],
      currentUser.permissions || [],
    );
  }, [currentUser]);

  const FUND_TABS: IFundTabs[] = useMemo(() => {
    const result: IFundTabs[] = fundDetailsState?.fund?.shareClassDetails.isin
      ? [
          {
            title: 'Summary',
            component: <Summary />,
            tabId: FUND_DETAILS_TAB.SUMMARY,
            dataTestId: FUND_DETAILS_DATA_TEST_ID.SUMMARY,
          },
          {
            title: 'Perf & Risk',
            component: <PerfRisk />,
            tabId: FUND_DETAILS_TAB.PERFORMANCE_AND_RISK,
            dataTestId: FUND_DETAILS_DATA_TEST_ID.PERFORMANCE_AND_RISK,
          },
          {
            title: 'Portfolio',
            component: <Portfolio />,
            tabId: FUND_DETAILS_TAB.PORTFOLIO,
            dataTestId: FUND_DETAILS_DATA_TEST_ID.PORTFOLIO,
          },
        ]
      : [];
    result.push({
      title: 'Significant Changes',
      component: (
        <SignificantChanges
          isins={
            fundDetailsState?.fund?.shareClassDetails.isin
              ? [fundDetailsState.fund?.shareClassDetails.isin]
              : []
          }
        />
      ),
      tabId: FUND_DETAILS_TAB.SIGNIFICANT_CHANGES,
      dataTestId: FUND_DETAILS_DATA_TEST_ID.SIGNIFICANT_CHANGES,
    });

    result.push(
      {
        title: 'Share Classes',
        component: <ShareClasses />,
        fundType: FundType.FUND,
        tabId: FUND_DETAILS_TAB.SHARE_CLASSES,
        dataTestId: FUND_DETAILS_DATA_TEST_ID.SHARE_CLASSES,
      },
      // {
      //   title: 'Announcements',
      //   component: <Announcements />,
      //   fundType: FundType.INVESTMENT_TRUST,
      //   disabled: true,
      //   tabId: FUND_DETAILS_TAB.ANNOUNCEMENTS,
      //   dataTestId: FUND_DETAILS_DATA_TEST_ID.ANNOUNCEMENTS,
      // },
      {
        title: 'Investors',
        component: <Investors />,
        tabId: FUND_DETAILS_TAB.INVESTORS,
        dataTestId: FUND_DETAILS_DATA_TEST_ID.INVESTORS,
      },
      ...(hasPermissionsForInternal
        ? [
            {
              title: 'Internal',
              component: <Internal fund={fundDetailsState?.fund} />,
              tabId: FUND_DETAILS_TAB.INTERNAL,
              dataTestId: FUND_DETAILS_DATA_TEST_ID.INTERNAL,
            },
          ]
        : []),
    );

    return result;
  }, [
    buckets,
    fundDetailsState?.fund?.shareClassDetails.isin,
    hasPermissionsForInternal,
  ]);

  if (isLoading || !fundDetailsState) return null;

  return (
    <div className={style.wrapper}>
      <Tabs
        defaultActiveKey={activeTab}
        activeKey={activeTab}
        // tabBarStyle={{ paddingLeft: 12, paddingRight: 12 }}
        className="[&>.ant-tabs-nav]:mb-4"
        onChange={(tab: string) => {
          history.replace(`${evaluateFundDetailsSubRoute(tab)}`);
        }}
        items={FUND_TABS.filter(
          item =>
            item.fundType ===
              getFundType(fundDetailsState.fund.legalStructure) ||
            !item.fundType,
        ).map(({ title, component, disabled, tabId, dataTestId }) => ({
          label: <span data-test-id={dataTestId}>{title}</span>,
          key: tabId,
          children: component,
          style: {
            height: screenWidthMode[ScreenWidthEnum.MinMd]
              ? `calc(100vh - ${sectionHeight && sectionHeight + HEIGHT}px)`
              : 'auto',
          },
          disabled,
        }))}
      />
    </div>
  );
};
