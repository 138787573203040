import './style.less';

import React from 'react';

import { ReactComponent as GraphicSearch } from '@/assets/svg/graphic-search.svg';
import { Subtitle } from '@/components';

interface EmptySearchProps {
  title: string;
  subTitle?: string;
}

export const EmptySearch: React.FCWithChild<EmptySearchProps> = ({
  title,
  subTitle,
  children,
}) => {
  return (
    <div className="flex flex-row lg:p-4">
      <div className="pt-1 mb-6 section-explore-wrapper bg-white lg:rounded-lg">
        <div className="empty-search flex items-center justify-center text-center px-5 gap-2">
          <GraphicSearch />
          <h1> {title} </h1>
          {subTitle && <Subtitle>{subTitle}</Subtitle>}
          {children}
        </div>
      </div>
    </div>
  );
};
