// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Epeo8m3gWUqoDLL6qMg6 {\n  display: flex;\n  width: 100%;\n  flex-direction: column;\n  align-items: center;\n  justify-content: center;\n  padding: 1.25rem;\n  padding-top: 4rem;\n  padding-bottom: 4rem;\n  text-align: center\n}@media (min-width: 768px) {.Epeo8m3gWUqoDLL6qMg6 {\n    padding-top: 8rem;\n    padding-bottom: 8rem\n  }\n}.KSaZs6cfiwNpsE8CHVPa {\n  margin-top: 1rem;\n  margin-bottom: 0.5rem;\n  font-size: 1.25rem;\n  line-height: 1.75rem;\n  font-weight: 500;\n  color: #181920\n}.mwd3tcp4endlwAOKLbpi {\n  font-size: 0.875rem;\n  line-height: 1.25rem;\n  font-weight: 400;\n  color: #545576\n}\n", "",{"version":3,"sources":["webpack://./src/partials/EmptyStateBase/style.module.less"],"names":[],"mappings":"AAGE;EAAA,aAAA;EAAA,WAAA;EAAA,sBAAA;EAAA,mBAAA;EAAA,uBAAA;EACA,gBAAA;EAAA,iBAAA;EAAA,oBAAA;EAAA;AADA,CACA,2BAAA;IAAA,iBAAA;IAAA;EAAA;AAAA,CAEE;EAAA,gBAAA;EAAA,qBAAA;EAAA,kBAAA;EAAA,oBAAA;EAAA,gBAAA;EACA;AADA,CAIA;EAAA,mBAAA;EAAA,oBAAA;EAAA,gBAAA;EACA;AADA","sourcesContent":["@import '@/less/partials/_variables.less';\n\n.container {\n  @apply flex flex-col items-center justify-center w-full;\n  @apply text-center p-5 py-16 md:py-32;\n  &-title {\n    @apply text-xl font-medium mt-4 mb-2;\n    color: #181920;\n  }\n  &-subtitle {\n    @apply text-sm font-normal;\n    color: #545576;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "Epeo8m3gWUqoDLL6qMg6",
	"container-title": "KSaZs6cfiwNpsE8CHVPa",
	"container-subtitle": "mwd3tcp4endlwAOKLbpi"
};
export default ___CSS_LOADER_EXPORT___;
