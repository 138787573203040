import { CompositeBenchmark } from '@aminsights/contract';
import clsx from 'classnames';
import React, { useState } from 'react';

import { ReactComponent as PlusIcon } from '@/assets/svg/icons/icon-plus.svg';
import {
  useCompositeBenchmarks,
  useDeleteCompositeBenchmark,
} from '@/hooks/query-hooks/benchmark-hooks/useManageBenchmarks';
import CompositeBenchmarkFormModal from '@/partials/CompositeBenchmarks/CompositeBenchmarkFormModal';
import CompositeBenchmarksList from '@/partials/CompositeBenchmarks/CompositeBenchmarksList';
import ConfirmationModalDanger from '@/partials/Modal/ConfirmationModalDanger';

import style from './style.module.less';

const CompositeBenchmarks: React.FCWithChild = () => {
  const [isFormModalOpen, setIsFormModalOpen] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [selectedBenchmark, setSelectedBenchmark] = useState<
    CompositeBenchmark | undefined
  >();

  const { data, isLoading } = useCompositeBenchmarks();
  const deleteBenchmark = useDeleteCompositeBenchmark();

  const handleDeleteBenchmark = async () => {
    if (!selectedBenchmark) return;
    await deleteBenchmark.mutateAsync({
      id: selectedBenchmark._id,
    });
    setIsDeleteModalOpen(false);
    setSelectedBenchmark(undefined);
  };

  return (
    <>
      <div className={style['composite-benchmarks']}>
        <div
          className={style['composite-benchmarks__wrapper']}
          data-test-id="customBenchmarksTable"
        >
          <div>
            <h5 className={style['composite-benchmarks__title']}>
              Custom Benchmarks
            </h5>
            <p className={style['composite-benchmarks__subTitle']}>
              Create benchmarks that you can use across the platform.
            </p>
          </div>
          <CompositeBenchmarksList
            benchmarks={data?.compositeBenchmarks ?? []}
            isLoading={isLoading}
            onClickEditBenchmark={benchmark => {
              setSelectedBenchmark(benchmark);
              setIsFormModalOpen(true);
            }}
            onClickDeleteBenchmark={benchmark => {
              setSelectedBenchmark(benchmark);
              setIsDeleteModalOpen(true);
            }}
          />
          <div className={style['add-composite-benchmark']}>
            <button
              className={style['add-composite-benchmark__button']}
              onClick={() => {
                setSelectedBenchmark(undefined);
                setIsFormModalOpen(true);
              }}
            >
              <PlusIcon
                className={clsx(
                  'icon',
                  style['add-composite-benchmark__button-icon'],
                )}
              />
              <p
                className={style['add-composite-benchmark__button-text']}
                data-test-id="customBenchmarksAddBenchmarkButton"
              >
                Add custom benchmark
              </p>
            </button>
          </div>
        </div>
      </div>
      <CompositeBenchmarkFormModal
        isVisible={isFormModalOpen}
        toggleModal={() => {
          setIsFormModalOpen(prev => !prev);
          setSelectedBenchmark(undefined);
        }}
        benchmarks={
          data?.compositeBenchmarks?.map(cb => ({
            id: cb._id,
            name: cb.name,
          })) ?? []
        }
        initialValue={selectedBenchmark}
      />
      <ConfirmationModalDanger
        modalInfo={{
          title: 'Delete benchmark?',
          description: selectedBenchmark?.shared ? (
            <div className="flex flex-col gap-y-6">
              <p className="text-sm">
                This benchmark is shared within your organisation.
              </p>
              <p className="text-sm">
                Colleagues using this benchmark will no longer be able to view
                it in their buckets or portfolios. This action cannot be undone.
              </p>
            </div>
          ) : (
            <p className="text-sm">
              This will remove the benchmark from any buckets or portfolios
              where it is being used. This action cannot be undone.
            </p>
          ),
          primaryActionLabel: 'Delete',
          succesMessage: 'Successfully deleted a benchmark',
          errorMessage: 'Unable to delete benchmark',
        }}
        width={480}
        isVisible={isDeleteModalOpen}
        toggleModal={() => {
          setIsDeleteModalOpen(false);
          setSelectedBenchmark(undefined);
        }}
        onConfirm={handleDeleteBenchmark}
      />
    </>
  );
};

export default CompositeBenchmarks;
