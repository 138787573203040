import { Fund } from '@aminsights/contract';

import { DataTableRenderedAt } from '@/constants/dataTableRenderedAt';
import TableCheckbox from '@/pages/app/Explore/components/TableCheckbox';

type CheckboxColumnProps = {
  onCheckedRow: (isins: string[]) => void;
  checkedISINs: string[];
  item: Fund;
  dataTableRenderedAt?: DataTableRenderedAt;
  showPrimaryShareClassIndicator?: boolean;
};

const CheckboxColumn = ({
  onCheckedRow,
  checkedISINs,
  item,
  dataTableRenderedAt,
  showPrimaryShareClassIndicator,
}: CheckboxColumnProps) => {
  return (
    <div className="flex items-center">
      <TableCheckbox
        selectedItems={checkedISINs}
        fund={item}
        onClick={onCheckedRow}
        dataTableRenderedAt={dataTableRenderedAt}
        showPrimaryShareClassIndicator={showPrimaryShareClassIndicator}
      />
    </div>
  );
};

export default CheckboxColumn;
