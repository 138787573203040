// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".vvFak56b_TRssZEvWob3 .ant-tabs-nav {\n  width: 100%;\n  border-bottom-width: 1px;\n  border-style: solid;\n  --tw-border-opacity: 1;\n  border-color: rgb(234 234 234 / var(--tw-border-opacity));\n  --tw-bg-opacity: 1;\n  background-color: rgb(255 255 255 / var(--tw-bg-opacity));\n}\n.vvFak56b_TRssZEvWob3 .ant-tabs-tab:nth-last-child(2) {\n  margin-right: 40px;\n}\n@media (min-width: 768px) {\n  .vvFak56b_TRssZEvWob3 .ant-tabs-tabpane {\n    overflow: auto;\n  }\n}\n.X61gFgQAo2vDMePz2owV .ant-progress-inner {\n  border-radius: 0px;\n}\n", "",{"version":3,"sources":["webpack://./src/pages/app/FundAndInvestmentTrust/style.module.less"],"names":[],"mappings":"AAEI;EAAA,WAAA;EAAA,wBAAA;EAAA,mBAAA;EAAA,sBAAA;EAAA,yDAAA;EAAA,kBAAA;EAAA;AAAA;AAFJ;EAMI,kBAAA;AADJ;AAIE;EAAA;IAEI,cAAA;EAFJ;AACF;AASM;EAAA;AAAA","sourcesContent":[".wrapper :global {\n  .ant-tabs-nav {\n    @apply bg-white border-b border-solid border-grey-light-50 w-full;\n  }\n\n  .ant-tabs-tab:nth-last-child(2) {\n    margin-right: 40px;\n  }\n\n  @media (min-width: 768px) {\n    .ant-tabs-tabpane {\n      overflow: auto;\n    }\n  }\n}\n\n.progress-bar {\n  :global {\n    .ant-progress-inner {\n      @apply rounded-none;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": "vvFak56b_TRssZEvWob3",
	"progress-bar": "X61gFgQAo2vDMePz2owV"
};
export default ___CSS_LOADER_EXPORT___;
