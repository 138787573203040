import {
  FundExploreItem,
  PageQueryParametersSortDirectionEnum,
} from '@aminsights/contract';

import { initialState } from '@/pages/app/Explore/context';

import { EXPLORE_ACTIONS, ExploreActionTypes, ExploreState } from './types';

export const exploreReducer = (
  state: ExploreState,
  action: ExploreActionTypes,
): ExploreState => {
  switch (action.type) {
    case EXPLORE_ACTIONS.SET_LOADING: {
      return {
        ...state,
        loading: true,
      };
    }
    case EXPLORE_ACTIONS.GET_DEFAULT_FILTERS:
      return { ...state, defaultfiltersOptions: action.payload };
    case EXPLORE_ACTIONS.SEARCH: {
      const newData: FundExploreItem[] = action.payload.data || [];
      const dataToSet = [];
      if (state.searchParameters.page !== 1) {
        const currentData = state.searchResults?.data || [];
        dataToSet.push(...currentData);
      }
      dataToSet.push(...newData);

      return {
        ...state,
        searchResults: {
          totalCount: action.payload.totalCount,
          data: dataToSet,
        },
        loading: false,
      };
    }
    case EXPLORE_ACTIONS.SET_SEARCH_PARAMETERS:
      return {
        ...state,
        searchParameters: {
          ...state.searchParameters,
          ...action.payload,
        },
      };
    case EXPLORE_ACTIONS.SET_FILTER_PARAMETERS:
      return {
        ...state,
        searchParameters: {
          ...state.searchParameters,
          // It must stay as logical OR (||) not coalescing (??)
          // because sortDirection and sortKey default to '' in initialState
          // once it is refactored, please clean up this part also
          sortDirection:
            state.searchParameters.sortDirection ||
            PageQueryParametersSortDirectionEnum.Desc,
          sortKey: state.searchParameters.sortKey || 'fundSize.value',
          page: 1,
        },
        filterParameters: {
          ...state.filterParameters,
          ...action.payload,
        },
      };
    case EXPLORE_ACTIONS.CLEAR_PARAMETERS: {
      return {
        ...state,
        searchResults: undefined,
        searchParameters: {
          ...initialState.searchParameters,
        },
        filterParameters: {
          ...initialState.filterParameters,
        },
        clearState: Date.now(),
      };
    }
    case EXPLORE_ACTIONS.RESET_CLEAR_STATE: {
      return {
        ...state,
        clearState: undefined,
      };
    }
    default:
      return state;
  }
};
