import { Checkbox, Divider, Dropdown, Menu } from 'antd';
import cx from 'classnames';
import React, { useCallback, useEffect, useMemo, useState } from 'react';

import { ReactComponent as NextArrow } from '@/assets/svg/icons/icon-next-arrow.svg';
import Button from '@/components/Button';
import SelectedDropdownFilter from '@/components/SearchSelectedFilterIndicator';
import useScreenWidth, { screenBreakpoints } from '@/hooks/screenWidth';
import { Search } from '@/pages/app/Explore/components/Search';
import { toCamelCase } from '@/utils/toCamelCase';

import style from './style.module.less';

export type SearchDropdownItems<T = string> = {
  label: string;
  value?: T;

  // @deprecated - check within the component if the value intersects with the option
  checked?: boolean;
};

type SearchDropdownProps = {
  placeholder: string;
  placement?:
    | 'bottomLeft'
    | 'bottomCenter'
    | 'bottomRight'
    | 'topLeft'
    | 'topCenter'
    | 'topRight';
  items: SearchDropdownItems[];
  showSearch?: boolean;
  showSelectAll?: boolean;
  onChange?: (value: string[]) => void;
  selectedFilter?: number;
  dropdownNote?: JSX.Element;
  dataTestId?: string;
};

const SearchDropdown: React.FCWithChild<SearchDropdownProps> = ({
  items,
  placement,
  placeholder,
  showSearch,
  showSelectAll,
  onChange,
  selectedFilter,
  dropdownNote,
  dataTestId = 'searchInput',
}) => {
  const [dataItems, setDataItems] = useState<SearchDropdownItems[]>([]);
  const [checkedItems, setCheckedItems] = useState<string[]>([]);
  const [searchText, setSearchText] = useState<string>('');
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    setDataItems(items);
  }, [items]);

  useEffect(() => {
    const checkedItemsMapped =
      dataItems
        .filter(item => {
          return item.checked;
        })
        .map(item => item.value || item.label)
        .filter(Boolean) || [];
    setCheckedItems(checkedItemsMapped);
  }, [dataItems]);

  // filter the items based on the search text
  const searchedItems = dataItems.filter(item =>
    item.label.toLowerCase().includes(searchText),
  );

  const handleUncheckAll = useCallback(() => {
    setDataItems(
      dataItems.map(item => ({
        label: item.label,
        checked: false,
        value: item.value,
      })),
    );
    setCheckedItems([]);
  }, [dataItems]);

  const onSubmit = useCallback(
    (e: React.MouseEvent<HTMLButtonElement>) => {
      e.preventDefault();
      // pass the checkedItems to the parent component if onChange is provided
      onChange && onChange(checkedItems);
      setIsVisible(false);
    },
    [checkedItems],
  );

  const isEveryItemInViewChecked = useMemo(
    () => searchedItems.every(item => item.checked),
    [searchedItems],
  );

  //scroll to the first checked checkbox on open
  const checkboxWrapperRef = useCallback(
    (node: HTMLDivElement | null) => {
      if (node !== null && isVisible) {
        setTimeout(() => {
          const firstCheckedCheckbox = node.querySelector(
            'input[type="checkbox"]:checked',
          );
          if (firstCheckedCheckbox) {
            firstCheckedCheckbox.scrollIntoView({
              behavior: 'smooth',
              block: 'nearest',
            });
          }
        }, 500);
      }
    },
    [isVisible],
  );

  const { currentWidth } = useScreenWidth();
  const isMobile = currentWidth < screenBreakpoints.sm;

  const mobileStyles = isMobile
    ? {
        left: '0',
        width: '100vw',
      }
    : {};

  const menu = (
    <form
      className={cx(style['button-search-dropdown-form'], 'max-sm:!w-full')}
    >
      <Menu
        className={cx(style['button-search-dropdown-menu'], 'max-sm:!w-full')}
        data-test-id={toCamelCase(`searchDropdownMenu${placeholder}`)}
      >
        {showSearch && (
          <div className={style['button-search-dropdown-menu-input']}>
            <Search
              autoFocus
              onChange={value => {
                setSearchText(value.toLowerCase() || '');
              }}
              visible={isVisible}
              setSearchText={setSearchText}
              input={searchText}
              dataTestId={dataTestId}
            />
          </div>
        )}
        {showSelectAll && (
          <div className="px-2 py-1">
            <div className="flex justify-between items-center">
              <Checkbox
                checked={isEveryItemInViewChecked}
                className="px-2"
                onChange={() => {
                  const clonedDataItems = [...dataItems];
                  searchedItems.forEach(item => {
                    const itemIndex = clonedDataItems.findIndex(
                      ci => ci.label === item.label,
                    );
                    if (clonedDataItems[itemIndex]) {
                      // if all items are checked, uncheck all, else check all
                      clonedDataItems[itemIndex].checked =
                        isEveryItemInViewChecked ? false : true;
                    }
                  });
                  setDataItems(clonedDataItems);
                }}
              >
                Select all
              </Checkbox>
              <span className="font-bold text-sm text-neutral">
                {checkedItems?.length ? `(${checkedItems.length})` : ''}
              </span>
            </div>
            <Divider className="m-0" />
          </div>
        )}
        <div className={style['button-search-dropdown-menu-items']}>
          <div
            className={cx(style['checkbox-scroll-container'], 'px-4')}
            ref={checkboxWrapperRef}
          >
            {searchedItems.map(item => {
              return (
                <Checkbox
                  data-test-id={toCamelCase(item.label)}
                  key={item.label}
                  value={item.checked}
                  checked={item.checked}
                  className={style['search-dropdown']}
                  onChange={event => {
                    const clonedDataItems = [...dataItems];
                    const itemIndex = clonedDataItems.findIndex(
                      ci => ci.label === item.label,
                    );
                    if (clonedDataItems[itemIndex]) {
                      clonedDataItems[itemIndex].checked = event.target.checked;
                    }
                    setDataItems(clonedDataItems);
                  }}
                >
                  {item.label}
                </Checkbox>
              );
            })}
          </div>
        </div>
      </Menu>
      <div className={style['button-search-dropdown-form-footer']}>
        {dropdownNote && (
          <div className={style['button-search-dropdown-form-note']}>
            {dropdownNote}
          </div>
        )}
        <div className={style['button-search-dropdown-form-footer-buttons']}>
          <Button
            data-test-id={toCamelCase(`clear${placeholder}`)}
            size="large"
            type="link"
            htmlType="button"
            className="font-semibold"
            onClick={() => {
              setSearchText('');
              handleUncheckAll();
            }}
            disabled={checkedItems.length === 0}
          >
            Clear
          </Button>
          <Button
            data-test-id={toCamelCase(`apply${placeholder}`)}
            size="large"
            type="primary"
            htmlType="submit"
            className="font-semibold"
            onClick={onSubmit}
            disabled={!selectedFilter && !checkedItems.length}
          >
            Apply
          </Button>
        </div>
      </div>
    </form>
  );

  return (
    <>
      <div className={style['button-search-dropdown']}>
        <Dropdown
          overlay={menu}
          trigger={['click']}
          placement={placement || 'bottomRight'}
          open={isVisible}
          overlayStyle={mobileStyles}
        >
          <Button
            className={style['button-search-dropdown-wrapper']}
            onClick={() => setIsVisible(prev => !prev)}
            data-test-id={toCamelCase(placeholder)}
          >
            <div
              className={cx(style['button-search-dropdown-label'], {
                [style['with-selected']]: selectedFilter ? selectedFilter : 0,
              })}
            >
              <SelectedDropdownFilter
                placeholder={placeholder}
                noOfSelectedFilter={selectedFilter ? selectedFilter : 0}
                className={style['select-dropdown-filter-wrapper']}
              />
            </div>
            <span className={style['icon-arrow-wrapper']}>
              <NextArrow
                className={cx('icon', style['button-search-dropdown-caret'])}
              />
            </span>
          </Button>
        </Dropdown>
      </div>
      {isVisible && (
        <div
          className={style['button-search-dropdown-overlay']}
          onClick={() => setIsVisible(false)}
          onKeyUp={() => setIsVisible(false)}
          role="button"
          tabIndex={0}
        >
          {' '}
        </div>
      )}
    </>
  );
};

export default SearchDropdown;
