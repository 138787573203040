import { Skeleton, Tooltip } from 'antd';
import cx from 'classnames';
import { forwardRef, ReactNode } from 'react';

export interface HeaderProps {
  title: string | React.ReactElement;
  id?: string;
  children: ReactNode;
  width?: number;
  minWidth?: number;
  className?: string;
  align?: string;
  colSpan: number;
  isFixed?: boolean;
  tooltipText?: React.ReactNode;
  loading?: boolean;
  onClick?: () => void;
}

const TableHeaderWithChildren = forwardRef<HTMLTableCellElement, HeaderProps>(
  (
    {
      title,
      id,
      className,
      children,
      colSpan,
      minWidth,
      isFixed,
      width,
      align,
      loading,
      onClick,
      tooltipText,
    },
    ref,
  ) => {
    return (
      <th
        ref={ref}
        id={id}
        className={cx(
          'cursor-default hover:bg-[#eaebec]',
          className,
          isFixed && 'sticky left-0 bg-grey-lighter z-10',
        )}
        style={{ width, minWidth }}
        onClick={() => {
          if (onClick) {
            onClick();
          }
        }}
        colSpan={colSpan}
        data-test-id={`tableHeader-${title}`}
      >
        <div
          className={cx('flex items-center w-full py-2 px-3 sm:px-4', {
            'justify-end': align === 'right',
            'justify-start': align === 'left',
            'justify-center': align === 'center',
          })}
          style={{ width }}
        >
          {!loading && (
            <Tooltip
              placement="bottom"
              title={tooltipText}
              overlayClassName="max-w-60 md:max-w-none"
              overlayInnerStyle={{ borderRadius: 4 }}
              arrowPointAtCenter
            >
              <div className="flex flex-row">
                {title !== '' && (
                  <div className="text-sm text-neutral font-medium whitespace-nowrap mr-1 self-center flex-1">
                    {title}
                  </div>
                )}
                {children}
              </div>
            </Tooltip>
          )}
          {loading && (
            <Skeleton
              title={false}
              paragraph={{ rows: 1, width: '70px' }}
              active
            />
          )}
        </div>
      </th>
    );
  },
);

export default TableHeaderWithChildren;
