import { Dropdown, Menu } from 'antd';
import cx from 'classnames';
import { useEffect, useState } from 'react';

import { ReactComponent as NextArrow } from '@/assets/svg/icons/icon-next-arrow.svg';
import { Button } from '@/components';
import SelectedDropdownFilter from '@/components/SearchSelectedFilterIndicator';
import useScreenWidth, { screenBreakpoints } from '@/hooks/screenWidth';
import { toCamelCase } from '@/utils/toCamelCase';

import useExploreFilters from '../../../hooks/useExploreFilters';
import style from '../../style.module.less';
import CategoriesFilter from './CategoriesFilter';

const CategoriesFiltersDropdownWrapper = () => {
  const [isCategoriesVisible, setIsCategoriesVisible] = useState(false);
  const { syncFilterByPath } = useExploreFilters();

  const {
    tentativeFilters,
    filters,
    getMutableFilters,
    updateTentativeFilters,
  } = useExploreFilters();
  const { selectedMorningStarCategoriesByIndex } =
    filters.morningStarCategories ?? {};

  const [clearDisabled, setClearDisabled] = useState(true);
  const [applyDisabled, setApplyDisabled] = useState(true);

  useEffect(() => {
    setApplyDisabled(
      JSON.stringify(
        tentativeFilters.morningStarCategories
          ?.selectedMorningStarCategoriesByIndex,
      ) ===
        JSON.stringify(
          filters.morningStarCategories?.selectedMorningStarCategoriesByIndex,
        ),
    );
  }, [tentativeFilters, filters]);

  useEffect(() => {
    setClearDisabled(
      applyDisabled &&
        (selectedMorningStarCategoriesByIndex?.length ?? 0) === 0,
    );
  });

  const { currentWidth } = useScreenWidth();
  const shouldUseMobileFilter = currentWidth < screenBreakpoints.lg;

  useEffect(() => {
    if (shouldUseMobileFilter) {
      setIsCategoriesVisible(false);
    }
  }, [shouldUseMobileFilter]);

  return (
    <>
      <div className={style['button-search-dropdown']}>
        <Dropdown
          overlay={
            <form
              className={cx(
                style['button-search-dropdown-form'],
                'max-w-[350px]',
              )}
            >
              <Menu
                className={style['button-search-dropdown-menu']}
                data-test-id={toCamelCase(`searchDropdownMenuM* Category`)}
              >
                <CategoriesFilter />
              </Menu>
              <div className={style['button-search-dropdown-form-footer']}>
                <div className={style['button-search-dropdown-form-note']}>
                  <p>
                    <span className="font-bold">Note:</span> Returns all share
                    classes.
                  </p>
                </div>
                <div
                  className={
                    style['button-search-dropdown-form-footer-buttons']
                  }
                >
                  <Button
                    data-test-id={toCamelCase(`clearM* Category`)}
                    size="large"
                    type="link"
                    htmlType="button"
                    className="font-semibold"
                    onClick={() => {
                      const filters = getMutableFilters();
                      filters.morningStarCategories = undefined;
                      updateTentativeFilters(filters);
                    }}
                    disabled={clearDisabled}
                  >
                    Clear
                  </Button>
                  <Button
                    data-test-id={toCamelCase(`applyM* Category`)}
                    size="large"
                    type="primary"
                    htmlType="submit"
                    className="font-semibold"
                    onClick={e => {
                      e.preventDefault();
                      syncFilterByPath('morningStarCategories');
                      syncFilterByPath(
                        'moreFilters.filterByOldestShareClassOnly',
                      );
                      setIsCategoriesVisible(false);
                    }}
                    disabled={applyDisabled}
                  >
                    Apply
                  </Button>
                </div>
              </div>
            </form>
          }
          trigger={['click']}
          placement={'bottomLeft'}
          open={isCategoriesVisible}
        >
          <Button
            className={style['button-search-dropdown-wrapper']}
            onClick={() => setIsCategoriesVisible(prev => !prev)}
            data-test-id={toCamelCase('M* Category')}
          >
            <div
              className={cx(
                style['button-search-dropdown-label'],
                !!selectedMorningStarCategoriesByIndex?.length &&
                  style['with-selected'],
              )}
            >
              <SelectedDropdownFilter
                placeholder={'M* Category'}
                noOfSelectedFilter={
                  selectedMorningStarCategoriesByIndex
                    ? selectedMorningStarCategoriesByIndex.length
                    : 0
                }
                className={style['select-dropdown-filter-wrapper']}
              />
            </div>
            <span className={style['icon-arrow-wrapper']}>
              <NextArrow
                className={cx('icon', style['button-search-dropdown-caret'])}
              />
            </span>
          </Button>
        </Dropdown>
      </div>
      {isCategoriesVisible && (
        <div
          className={style['button-search-dropdown-overlay']}
          onClick={() => setIsCategoriesVisible(false)}
          onKeyUp={() => setIsCategoriesVisible(false)}
          role="button"
          tabIndex={0}
        >
          {' '}
        </div>
      )}
    </>
  );
};

export default CategoriesFiltersDropdownWrapper;
