import { DataSourceType, FundBroadAssetClass } from '@aminsights/contract';
import {
  DISPLAY_DATE_FORMAT,
  EMPTY_DATA_POINT,
  isEquityBroadAssetClass,
  isFixedIncomeBroadAssetClass,
  SalesPosition,
} from '@aminsights/shared';
import { Tabs } from 'antd';
import dayjs from 'dayjs';
import React, { useMemo, useState } from 'react';

import { FundTitleLabel } from '@/constants';
import {
  useFundByIsInParam,
  useFundIsInvestmentTrust,
} from '@/hooks/query-hooks/fund-hooks/useFundByIsin';
import RenderStyleBox from '@/partials/RenderStyleBox';
import getScreenWidthMode, {
  ScreenWidthEnum,
} from '@/utils/getScreenWidthMode';

import AssetAllocation from './AssetAllocation';
import CreditQualityTable from './CreditQualityTable';
import GeographyTable from './GeographyTable';
import HoldingsTable from './HoldingsTable';
import MarketCapTable from './MarketCapTable';
import MaturityTable from './MaturityTable';
import SectorTable from './SectorTable';

const PortfolioDate: React.FCWithChild<{ date: string }> = ({ date }) => {
  const portfolioFormattedDate = dayjs(date).format(DISPLAY_DATE_FORMAT);
  return (
    <div className="px-4 py-2 text-right md:text-left md:py-0">
      <p
        className="text-xs font-bold text-darkest md:text-sm"
        data-test-id="fundDetailsPortfolioTabDate"
      >
        {date ? portfolioFormattedDate : EMPTY_DATA_POINT}
      </p>
    </div>
  );
};

const Portfolio: React.FCWithChild = () => {
  const screenWidthMode = getScreenWidthMode();
  const isMobile = screenWidthMode[ScreenWidthEnum.MaxMd];
  const { data: state } = useFundByIsInParam();
  const isInvestmentTrust = useFundIsInvestmentTrust();
  const [activeTab, setActiveTab] = useState(FundTitleLabel.HOLDINGS);

  const PORTFOLIO_TAB = useMemo(() => {
    if (!state) {
      return [];
    }
    const tabs = [
      {
        tab: FundTitleLabel.HOLDINGS,
        updateDateComponent: () => (
          <PortfolioDate
            date={state.fund.portfolioHoldingsListUpdateDate || ''}
          />
        ),
        component: () => <HoldingsTable />,
        dataTestId: 'fundDetailsPortfolioTabHoldingsTab',
      },
    ];

    const isEquity = isEquityBroadAssetClass(state.fund.broadAssetClass);
    const isFixedIncome = isFixedIncomeBroadAssetClass(
      state.fund.broadAssetClass,
    );

    const isNotEquityOrFI = !isEquity && !isFixedIncome;
    const isDataSourcePadi =
      state?.fund?.portfolioDataSource === DataSourceType.Padi;

    if (
      isEquity ||
      ((isNotEquityOrFI || isDataSourcePadi) &&
        (state.fund.sectorsEquity?.length ?? 0 !== 0))
    ) {
      tabs.push({
        tab: isDataSourcePadi
          ? FundTitleLabel.SECTORS
          : FundTitleLabel.SECTORS_EQ,
        updateDateComponent: () => (
          <PortfolioDate date={state.fund.portfolioSectorUpdateDate || ''} />
        ),
        component: () => (
          <SectorTable
            broadAssetClass={FundBroadAssetClass.Equity}
            isPadi={isDataSourcePadi}
          />
        ),
        dataTestId: 'fundDetailsPortfolioTabSectorsEqTab',
      });
    }

    if (
      isEquity ||
      (isDataSourcePadi &&
        (state.fund.geographyPortfolio?.length ?? 0 !== 0)) ||
      (isNotEquityOrFI && (state.fund.countriesEquity?.length ?? 0 !== 0))
    ) {
      tabs.push({
        tab: isDataSourcePadi
          ? FundTitleLabel.GEOGRAPHY
          : FundTitleLabel.GEOGRAPHY_EQ,
        updateDateComponent: () => (
          <PortfolioDate date={state.fund.portfolioGeographyUpdateDate || ''} />
        ),
        component: () => (
          <GeographyTable
            broadAssetClass={FundBroadAssetClass.Equity}
            isPadi={isDataSourcePadi}
          />
        ),
        dataTestId: 'fundDetailsPortfolioTabGeographyEqTab',
      });
    }

    if (!isFixedIncome && (state.fund.marketCapsLong?.length ?? 0) !== 0) {
      tabs.push({
        tab: FundTitleLabel.MARKET_CAP,
        updateDateComponent: () => (
          <PortfolioDate date={state.fund.portfolioUpdateDateMs || ''} />
        ),
        component: () => <MarketCapTable />,
        dataTestId: 'fundDetailsPortfolioTabMarketCapTab',
      });
    }

    if (
      !isDataSourcePadi &&
      (isFixedIncome ||
        (isNotEquityOrFI && (state.fund.sectorsFixedIncome?.length ?? 0 !== 0)))
    ) {
      tabs.push({
        tab: FundTitleLabel.SECTORS_FI,
        updateDateComponent: () => (
          <PortfolioDate date={state.fund.portfolioSectorUpdateDate || ''} />
        ),
        component: () => (
          <SectorTable
            broadAssetClass={FundBroadAssetClass.FixedIncome}
            isPadi={isDataSourcePadi}
          />
        ),
        dataTestId: 'fundDetailsPortfolioTabSectorsFITab',
      });
    }

    if (
      !isDataSourcePadi &&
      (isFixedIncome ||
        (isNotEquityOrFI &&
          (state.fund.countriesFixedIncome?.length ?? 0 !== 0)))
    ) {
      tabs.push({
        tab: FundTitleLabel.GEOGRAPHY_FI,
        updateDateComponent: () => (
          <PortfolioDate date={state.fund.portfolioGeographyUpdateDate || ''} />
        ),
        component: () => (
          <GeographyTable
            broadAssetClass={FundBroadAssetClass.FixedIncome}
            isPadi={isDataSourcePadi}
          />
        ),
        dataTestId: 'fundDetailsPortfolioTabGeographyFITab',
      });
    }

    (isFixedIncome || isInvestmentTrust) &&
      (state.fund.broadAssetClassValues?.styleBox ||
        state.fund.bondStatistics?.styleBox) &&
      tabs.push({
        tab: FundTitleLabel.STYLE,
        updateDateComponent: () => (
          <PortfolioDate date={state.fund.portfolioUpdateDateMs || ''} />
        ),
        component: () => (
          <div className="[&>div>div]:!px-5 [&>div>div]:!py-5">
            <RenderStyleBox
              isFixedIncome={isFixedIncome || isInvestmentTrust}
            />
          </div>
        ),
        dataTestId: 'fundDetailsPortfolioTabStyleTab',
      });

    (isFixedIncome ||
      (isNotEquityOrFI &&
        (state.fund.bondMaturityRange?.filter(
          v => v.salePosition === SalesPosition.Long,
        )?.length ??
          0 !== 0))) &&
      tabs.push({
        tab: FundTitleLabel.MATURITY,
        updateDateComponent: () => (
          <PortfolioDate date={state.fund.portfolioUpdateDateMs || ''} />
        ),
        component: () => <MaturityTable />,
        dataTestId: 'fundDetailsPortfolioTabMaturityTab',
      });

    (isFixedIncome ||
      (isNotEquityOrFI &&
        (state.fund.bondCreditQualityBreakdown?.length ?? 0) !== 0)) &&
      tabs.push({
        tab: FundTitleLabel.CREDIT_QUALITY,
        updateDateComponent: () => (
          <PortfolioDate date={state.fund.portfolioUpdateDateMs || ''} />
        ),
        component: () => <CreditQualityTable />,
        dataTestId: 'fundDetailsPortfolioTabCreditQualityTab',
      });

    return tabs;
  }, [state?.fund?.broadAssetClass, state?.fund?.broadAssetClassValues]);

  return (
    <div className="grid grid-cols-4 gap-4 lg:px-4">
      <div className="col-span-4 overflow-hidden lg:rounded-lg lg:col-span-3 [&_.ant-tabs-nav]:!mx-0">
        <Tabs
          defaultActiveKey="Holdings"
          onChange={activeTab => {
            setActiveTab(activeTab as FundTitleLabel);
          }}
          tabBarExtraContent={
            !isMobile
              ? PORTFOLIO_TAB.find(
                  ({ tab }) => tab === activeTab,
                )?.updateDateComponent?.()
              : ''
          }
          items={PORTFOLIO_TAB.map(
            ({ tab, component, updateDateComponent, dataTestId }) => ({
              label: <div data-test-id={dataTestId}>{tab}</div> || '',
              key: tab || '',
              children: (
                <>
                  {isMobile ? updateDateComponent() : ''}
                  {component()}
                </>
              ),
            }),
          )}
        />
      </div>
      <div className="col-span-4 lg:col-span-1">
        <AssetAllocation />
      </div>
    </div>
  );
};

export default Portfolio;
