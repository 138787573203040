import { WATCHLIST_TAB_KEY } from '@aminsights/shared';
import { useContext } from 'react';

import RenderStyleBox from '../Watchlist/RenderStyleBox';
import { TabContext } from '../Watchlist/WatchlistTabWrapper';
import WatchlistEquityStatsTable from '../WatchlistTables/WatchlistEquityStats/WatchlistEquityStatsTable';
import WatchlistGeographyEqTable from '../WatchlistTables/WatchlistGeography/GeographyEquity/WatchlistGeographyEqTable';
import WatchlistMarketCapTable from '../WatchlistTables/WatchlistMarketCap/WatchlistMarketCapTable';
import WatchlistSectorsEqTable from '../WatchlistTables/WatchlistSectors/SectorsEquity/WatchlistSectorsEqTable';

const childrenList = [
  {
    label: (
      <span
        className="is-watchlist-single-tab-item"
        data-test-id="tabEquityStyle"
      ></span>
    ),
    key: WATCHLIST_TAB_KEY.STYLE_EQ,
    children: <RenderStyleBox />,
  },
  {
    label: (
      <span
        className="is-watchlist-single-tab-item"
        data-test-id="tabSectorsEquityEq"
      ></span>
    ),
    key: WATCHLIST_TAB_KEY.SECTORS_EQ,
    children: <WatchlistSectorsEqTable isActive={true} />,
  },
  {
    label: (
      <span
        className="is-watchlist-single-tab-item"
        data-test-id="tabGoegraphyEq"
      ></span>
    ),
    key: WATCHLIST_TAB_KEY.GEOGRAPHY_EQ,
    children: <WatchlistGeographyEqTable isActive={true} />,
  },
  {
    label: (
      <span
        className="is-watchlist-single-tab-item"
        data-test-id="tabMarketCap"
      ></span>
    ),
    key: WATCHLIST_TAB_KEY.MARKET_CAP,
    children: <WatchlistMarketCapTable isActive={true} />,
  },
  {
    label: (
      <span
        className="is-watchlist-single-tab-item"
        data-test-id="tabEquityStats"
      ></span>
    ),
    key: WATCHLIST_TAB_KEY.EQUITY_STATS,
    children: <WatchlistEquityStatsTable isActive={true} />,
  },
];

const EquityChildren = () => {
  const { activeTabMenuItem } = useContext(TabContext);
  return childrenList.find(child => child.key === activeTabMenuItem)?.children;
};

export default EquityChildren;
