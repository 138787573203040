import { Slider } from 'antd';
import cx from 'classnames';
import getSymbolFromCurrency from 'currency-symbol-map';
import { useMemo } from 'react';

import useGetCurrencyRates from '@/hooks/query-hooks/currency-hooks/useGetCurrencyRates';

import {
  fundSizeInitialState,
  generateFundSizeMarks,
  SLIDER_MAX_FUND_SIZE,
} from '../../../hooks/fundSizeSubState';
import { SLIDER_STEP } from '../../../hooks/moreFiltersSubState';
import useExploreFilters from '../../../hooks/useExploreFilters';
import { adjustMarksToAvoidDuplicates } from '../../../utils/adjustMarksToAvoidDuplicates';
import { getRangeSliderClassName } from '../../../utils/getRangeSliderClassName';
import style from '../../style.module.less';

const FundSizeSlider = () => {
  const { data: currencyData } = useGetCurrencyRates();
  const { getMutableFilters, updateTentativeFilters, tentativeFilters } =
    useExploreFilters();

  const fundSizeMarks = useMemo(() => {
    const symbol = currencyData?.currencyCode
      ? getSymbolFromCurrency(currencyData.currencyCode)
      : '£';
    return generateFundSizeMarks(symbol);
  }, [currencyData]);

  const range =
    tentativeFilters.fundSizeFilter?.fundSizeRange ??
    fundSizeInitialState.fundSizeRange;

  return (
    <Slider
      className={cx(
        style[getRangeSliderClassName(!!tentativeFilters.fundSizeFilter)],
        style['range-slider-cutoff-fix'],
      )}
      tooltip={{ open: false }}
      range
      step={SLIDER_STEP}
      max={SLIDER_MAX_FUND_SIZE}
      marks={fundSizeMarks}
      value={[range[0], range[1]]}
      onChange={value => {
        const filters = getMutableFilters();
        filters.fundSizeFilter = filters.fundSizeFilter ?? {};
        filters.fundSizeFilter = {
          fundSizeRange: adjustMarksToAvoidDuplicates(value, fundSizeMarks),
        };
        updateTentativeFilters(filters);
      }}
    />
  );
};

export default FundSizeSlider;
