import { FUND_BOX_X_AND_Y_LABEL_VALUE } from '@aminsights/shared';
import { getHighlightStyleboxColor } from '@aminsights/shared';
import cx from 'classnames';
import React, { useEffect, useState } from 'react';

import { useFundByIsInParam } from '@/hooks/query-hooks/fund-hooks/useFundByIsin';

import style from './style.module.less';

const RenderStyleBox: React.FCWithChild<{
  isFixedIncome?: boolean;
  isBoxClickable?: boolean;
  isClear?: boolean;
  onSelectedBox?: (value: number[]) => void;
  selectedBox?: number[];
}> = ({
  isFixedIncome,
  isBoxClickable,
  onSelectedBox,
  selectedBox,
  isClear,
}) => {
  const { data: state } = useFundByIsInParam();
  const [selectedBoxes, setSelectedBoxes] = useState<number[]>([]);

  const STYLE_BOX_COUNT = 9;

  const getXLabel = (labelData: string[]) => {
    return labelData.map(value => (
      <div className={cx(style['per-box-text'], style['per-box-text-x'])}>
        {value}
      </div>
    ));
  };

  const getYLabel = (labelData: string[]) => {
    return labelData.map(value => (
      <div
        className={cx(
          style['per-box-text'],
          style['per-box-text-y'],
          'relative',
        )}
      >
        <div className={style['per-box-text-y-inner']}>{value}</div>
      </div>
    ));
  };

  const handleBoxClick = (index: number) => {
    if (onSelectedBox) {
      if (selectedBoxes.includes(index)) {
        const updatedSelectedBoxes = selectedBoxes.filter(
          boxIndex => boxIndex !== index,
        );
        setSelectedBoxes(updatedSelectedBoxes);
        onSelectedBox(updatedSelectedBoxes);
      } else {
        const updatedSelectedBoxes = [...selectedBoxes, index];
        setSelectedBoxes(updatedSelectedBoxes);
        onSelectedBox(updatedSelectedBoxes);
      }
    }
  };

  useEffect(() => {
    if (isClear) {
      setSelectedBoxes([]);
    }
  }, [isClear]);

  useEffect(() => {
    if (selectedBox && selectedBox.length > 0) {
      setSelectedBoxes(selectedBox);
    }
  }, [selectedBox]);

  const boxBackgroundColor = () => {
    return getHighlightStyleboxColor(isFixedIncome ?? false);
  };

  const renderBox = () => {
    const boxes = [];
    for (let i = 0; i < STYLE_BOX_COUNT; i += 3) {
      boxes.push(
        <div className={style['box-row-wrapper']} key={i}>
          {[i + 1, i + 2, i + 3].map(index => (
            <div
              data-test-id={`${isFixedIncome ? 'bond' : 'equity'}Index${index}`}
              key={index}
              onClick={() => handleBoxClick(index)}
              className={cx(
                style['box'],
                isFixedIncome ? style['box-fi'] : style['box-eq'],
              )}
              style={{
                backgroundColor: boxBackgroundColor(),
              }}
            >
              <div
                className={cx(style['box-interior'], {
                  [style['box-interior-eq-hover']]:
                    isBoxClickable && !isFixedIncome,
                  [style['box-interior-eq-selected']]:
                    selectedBoxes.includes(index) && !isFixedIncome,
                  [style['box-interior-fi-hover']]:
                    isBoxClickable && isFixedIncome,
                  [style['box-interior-fi-selected']]:
                    selectedBoxes.includes(index) && isFixedIncome,
                })}
              ></div>
              {/* {styleBoxValue} */}
            </div>
          ))}
        </div>,
      );
    }
    return boxes;
  };

  return (
    <div data-test-id="fundDetailsPortfolioTabStyleBox">
      <div className={cx(style['style-wrapper'])}>
        <div className={style['title']}>{state?.fund?.broadAssetClass}</div>
        <div
          className={cx(
            style['box-container'],
            !isBoxClickable && style['box-container-fund-details'],
          )}
        >
          <div
            className={cx(
              isFixedIncome ? 'bg-[#97D17D]' : 'bg-[#99CCFF]',
              'pr-[1px] pt-[1px]',
            )}
          >
            {renderBox()}
          </div>
          <div className="flex flex-col">
            {getYLabel(
              isFixedIncome
                ? FUND_BOX_X_AND_Y_LABEL_VALUE.FI.yName
                : FUND_BOX_X_AND_Y_LABEL_VALUE.EQ.yName,
            )}
          </div>
        </div>
        <div className="flex text-center">
          {getXLabel(
            isFixedIncome
              ? FUND_BOX_X_AND_Y_LABEL_VALUE.FI.xName
              : FUND_BOX_X_AND_Y_LABEL_VALUE.EQ.xName,
          )}
        </div>
      </div>
      {!isBoxClickable && (
        <div className="w-full px-2 py-4">
          <p className="text-xs text-neutral">Only captures long positions</p>
        </div>
      )}
    </div>
  );
};

export default RenderStyleBox;
