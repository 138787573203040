import { Fund, Performance } from '@aminsights/contract';
import { EMPTY_DATA_POINT, isFund } from '@aminsights/shared';
import { TotalReturnPeriod } from '@aminsights/shared';
import { calculatePerformanceTableBackgroundColor } from '@aminsights/shared';
import { Skeleton } from 'antd';
import React from 'react';

import { IDataTableColumns } from '@/components/Table/DataTable';
import { DataTableRenderedAt } from '@/constants/dataTableRenderedAt';
import { TableCheckboxHeader } from '@/pages/app/Explore/components/TableCheckbox';
import getScreenWidthMode, {
  ScreenWidthEnum,
} from '@/utils/getScreenWidthMode';

import FundNameCheckboxWrapper, {
  CheckboxParams,
} from './FundNameCheckboxWrapper';

const AnnualisedPerformanceColumns = (
  checkboxParams: CheckboxParams,
  dataTableRenderedAt: DataTableRenderedAt,
  showPrimaryShareClassIndicator?: boolean,
): IDataTableColumns[] => {
  const screenWidthMode = getScreenWidthMode();
  return [
    {
      headerCheckbox: (): React.ReactNode =>
        dataTableRenderedAt !== DataTableRenderedAt.Watchlist && (
          <div className="">
            <TableCheckboxHeader
              onClick={checkboxParams.onCheckedRow}
              topISINs={checkboxParams.topISINs}
              checked={checkboxParams.isAllCheckboxChecked}
            />
          </div>
        ),
      title: (
        <div className="whitespace-normal text-start">
          Fund/Investment Trust
        </div>
      ),
      dataTestIdSuffix: 'Fund/Investment Trust',
      sortKey: 'shareClassDetails.code',
      render: (item: Fund | Performance): React.ReactNode => {
        return isFund(item) ? (
          <FundNameCheckboxWrapper
            item={item}
            checkboxParams={checkboxParams}
            dataTableRenderedAt={dataTableRenderedAt}
            showPrimaryShareClassIndicator={
              showPrimaryShareClassIndicator ?? false
            }
          />
        ) : (
          // empty ant-avatar-sm is just for to mock avatar
          <div className="flex flex-row">
            <div className="hidden mr-4 ant-avatar-sm sm:block" />
            <div className="font-bold table-cell-value text-darkest">
              {item.name}
            </div>
          </div>
        );
      },
      renderType: 'custom',
      align: 'between',
      width: screenWidthMode[ScreenWidthEnum.MaxMd] ? 176 : 320,
      isColumnFixed: true,
      loader: (
        <Skeleton
          avatar={{ size: 'small' }}
          title={false}
          paragraph={{ rows: 2 }}
          active
        />
      ),
    },
    {
      title: 'YTD',
      idSubText: 'ytd',
      renderType: 'custom',
      align: 'center',
      children: [
        {
          title: 'Return',
          sortKey: 'totalReturnYtdPct',
          render: (item: Fund | Performance): string => {
            const value = item?.totalReturnYtdPct;
            return !!value ? `${Number(value).toFixed(1)}%` : EMPTY_DATA_POINT;
          },
          renderType: 'number',
          width: 85,
        },
        {
          title: 'Pcl',
          sortKey: 'totalReturnYtdMorningstarCategoryPcl',
          render: (item: Fund | Performance): string => {
            const value =
              isFund(item) &&
              item?.annualisedPerformancesPcl?.find(
                a => a.type === TotalReturnPeriod.YTD,
              )?.value;
            return !!value ? Number(value).toFixed(0) : EMPTY_DATA_POINT;
          },
          renderType: 'number',
          width: 85,
          backgroundColor: (item: Fund) => {
            return isFund(item)
              ? calculatePerformanceTableBackgroundColor(
                  item,
                  'annualisedPerformancesPcl',
                  TotalReturnPeriod.YTD,
                )
              : '';
          },
        },
      ],
    },
    {
      title: '3 mos',
      idSubText: '3mos',
      renderType: 'custom',
      align: 'center',
      children: [
        {
          title: 'Return',
          sortKey: 'totalReturn3MonthPct',
          render: (item: Fund | Performance): string => {
            const value = item?.totalReturn3MonthPct;
            return !!value ? `${Number(value).toFixed(1)}%` : EMPTY_DATA_POINT;
          },
          renderType: 'number',
          width: 85,
        },
        {
          title: 'Pcl',
          sortKey: 'totalReturn3MonthMorningstarCategoryPcl',
          render: (item: Fund | Performance): string => {
            const value =
              isFund(item) &&
              item?.annualisedPerformancesPcl?.find(
                a => a.type === TotalReturnPeriod.THREE_MONTHS,
              )?.value;
            return !!value ? Number(value).toFixed(0) : EMPTY_DATA_POINT;
          },
          renderType: 'number',
          width: 85,
          backgroundColor: (item: Fund) => {
            return isFund(item)
              ? calculatePerformanceTableBackgroundColor(
                  item,
                  'annualisedPerformancesPcl',
                  TotalReturnPeriod.THREE_MONTHS,
                )
              : '';
          },
        },
      ],
    },
    {
      title: '6 mos',
      idSubText: '6mos',
      renderType: 'custom',
      align: 'center',
      children: [
        {
          title: 'Return',
          sortKey: 'totalReturn6MonthPct',
          render: (item: Fund | Performance): string => {
            const value = item?.totalReturn6MonthPct;
            return !!value ? `${Number(value).toFixed(1)}%` : EMPTY_DATA_POINT;
          },
          renderType: 'number',
          width: 85,
        },
        {
          title: 'Pcl',
          sortKey: 'totalReturn6MonthMorningstarCategoryPcl',
          render: (item: Fund | Performance): string => {
            const value =
              isFund(item) &&
              item?.annualisedPerformancesPcl?.find(
                a => a.type === TotalReturnPeriod.SIX_MONTHS,
              )?.value;
            return !!value ? Number(value).toFixed(0) : EMPTY_DATA_POINT;
          },
          renderType: 'number',
          width: 85,
          backgroundColor: (item: Fund) => {
            return isFund(item)
              ? calculatePerformanceTableBackgroundColor(
                  item,
                  'annualisedPerformancesPcl',
                  TotalReturnPeriod.SIX_MONTHS,
                )
              : '';
          },
        },
      ],
    },
    {
      title: '1 yr',
      idSubText: '1yr',
      renderType: 'custom',
      align: 'center',
      children: [
        {
          title: 'Return',
          sortKey: 'totalReturn1YearPct',
          render: (item: Fund | Performance): string => {
            const value = item?.totalReturn1YearPct;
            return !!value ? `${Number(value).toFixed(1)}%` : EMPTY_DATA_POINT;
          },
          renderType: 'number',
          width: 85,
        },
        {
          title: 'Pcl',
          sortKey: 'totalReturn1YearMorningstarCategoryPcl',
          render: (item: Fund | Performance): string => {
            const value =
              isFund(item) &&
              item?.annualisedPerformancesPcl?.find(
                a => a.type === TotalReturnPeriod.ONE_YEAR,
              )?.value;
            return !!value ? Number(value).toFixed(0) : EMPTY_DATA_POINT;
          },
          renderType: 'number',
          width: 85,
          backgroundColor: (item: Fund) => {
            return isFund(item)
              ? calculatePerformanceTableBackgroundColor(
                  item,
                  'annualisedPerformancesPcl',
                  TotalReturnPeriod.ONE_YEAR,
                )
              : '';
          },
        },
      ],
    },
    {
      title: '3 yrs',
      idSubText: '3yrs',
      renderType: 'custom',
      align: 'center',
      children: [
        {
          title: 'Return',
          sortKey: 'totalReturn3YearPct',
          render: (item: Fund | Performance): string => {
            const value = item?.totalReturn3YearPct;
            return !!value ? `${Number(value).toFixed(1)}%` : EMPTY_DATA_POINT;
          },
          renderType: 'number',
          width: 85,
        },
        {
          title: 'Pcl',
          sortKey: 'totalReturn3YearMorningstarCategoryPcl',
          render: (item: Fund | Performance): string => {
            const value =
              isFund(item) &&
              item?.annualisedPerformancesPcl?.find(
                a => a.type === TotalReturnPeriod.THREE_YEARS,
              )?.value;
            return !!value ? Number(value).toFixed(0) : EMPTY_DATA_POINT;
          },
          renderType: 'number',
          width: 85,
          backgroundColor: (item: Fund) => {
            return isFund(item)
              ? calculatePerformanceTableBackgroundColor(
                  item,
                  'annualisedPerformancesPcl',
                  TotalReturnPeriod.THREE_YEARS,
                )
              : '';
          },
        },
      ],
    },
    {
      title: '5 yrs',
      idSubText: '5yrs',
      renderType: 'custom',
      align: 'center',
      children: [
        {
          title: 'Return',
          sortKey: 'totalReturn5YearPct',
          render: (item: Fund | Performance): string => {
            const value = item?.totalReturn5YearPct;
            return !!value ? `${Number(value).toFixed(1)}%` : EMPTY_DATA_POINT;
          },
          renderType: 'number',
          width: 85,
        },
        {
          title: 'Pcl',
          sortKey: 'totalReturn5YearMorningstarCategoryPcl',
          render: (item: Fund | Performance): string => {
            const value =
              isFund(item) &&
              item?.annualisedPerformancesPcl?.find(
                a => a.type === TotalReturnPeriod.FIVE_YEARS,
              )?.value;
            return !!value ? Number(value).toFixed(0) : EMPTY_DATA_POINT;
          },
          renderType: 'number',
          width: 85,
          backgroundColor: (item: Fund) => {
            return isFund(item)
              ? calculatePerformanceTableBackgroundColor(
                  item,
                  'annualisedPerformancesPcl',
                  TotalReturnPeriod.FIVE_YEARS,
                )
              : '';
          },
        },
      ],
    },
    {
      title: '',
      render: () => '',
      renderType: 'text',
    },
  ];
};

export default AnnualisedPerformanceColumns;
