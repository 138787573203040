import { APP_ROUTE_PORTFOLIOS, buildFundDetailsPath } from '@aminsights/shared';
import cx from 'classnames';
import { useHistory } from 'react-router-dom';

import { ReactComponent as IconClose } from '@/assets/svg/icons/icon-close.svg';
import { ReactComponent as IconEnter } from '@/assets/svg/icons/icon-enter.svg';
import useSearchWithPortfolios from '@/hooks/query-hooks/search-hooks/useSearchWithPortfolio';
import ExploreSearchV2, {
  renderOptions,
} from '@/partials/ExploreSearchBox/ExploreSearchBoxV2';
import { useSearchBox } from '@/partials/ExploreSearchBox/useExploreSearchbox';

import style from './style.module.less';

const NavSearchBox = ({ onClose }: { onClose: () => void }) => {
  const history = useHistory();
  const searchConfig = useSearchWithPortfolios();
  const searchBoxOpts = useSearchBox({
    onClose,
    defaultValue: '',
    searchConfig,
  });
  const resultOpts = searchBoxOpts.allSuggestions.map(opt =>
    renderOptions({
      value: opt.id,
      title: opt.label,
      highlightTerm: searchBoxOpts.searchValue,
      isPortfolio: opt.isPortfolio,
      primaryShareClass: opt.primaryShareClass,
    }),
  );
  const handleOnSearchResults = async (id?: string) => {
    const optSelected = searchBoxOpts.allSuggestions.find(opt => opt.id === id);
    if (optSelected && id) {
      if (optSelected.isPortfolio) {
        history.push(`/${APP_ROUTE_PORTFOLIOS}/${id}/holdings`);
      } else {
        history.push(buildFundDetailsPath(id));
      }
    } else {
      searchBoxOpts.onSearchResults();
    }
  };
  return (
    <ExploreSearchV2
      dataTestId="navSearchBox"
      className={cx(style['nav-search-box'])}
      value={searchBoxOpts.searchValue}
      onChange={searchBoxOpts.onChange}
      onBlur={onClose}
      onSelect={async id => {
        handleOnSearchResults(id);
      }}
      onKeyEnter={id => {
        handleOnSearchResults(id);
      }}
      onSubmit={searchBoxOpts.onSearchResults}
      options={resultOpts}
      loading={searchBoxOpts.isLoading}
      extraContent={
        <div className={cx(style['search-nav'], style['search-nav--navbar'])}>
          <button type="button" tabIndex={0} onClick={onClose}>
            <IconClose className={cx('icon', style['search-nav--close'])} />
          </button>
          <span className={style['search-nav--break']}>|</span>
          <button
            type="button"
            tabIndex={0}
            onMouseDown={() => {
              searchBoxOpts.setSearchValue(searchBoxOpts.searchValue);
              searchBoxOpts.onSearchResults();
            }}
          >
            <IconEnter className={cx('icon', style['search-nav--accept'])} />
          </button>
        </div>
      }
    />
  );
};

export default NavSearchBox;
