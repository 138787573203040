export const mapToOptionsWithIndex = <T extends { label: string }>(
  defaultOptions?: T[],
) => {
  return (
    defaultOptions?.map((item, index) => ({
      label: item.label,
      value: index.toString(),
    })) ?? []
  );
};

export const mapToOptions = <T extends { label: string }>(
  defaultOptions?: T[],
) => {
  return (
    defaultOptions?.map(item => ({
      label: item.label,
      value: item.label,
    })) ?? []
  );
};
