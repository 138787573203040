import { LEGAL_STRUCTURE } from '@aminsights/shared';

import { Checkbox as MultiCheckBox } from '@/components/Checkbox';

import useExploreFilters from '../../../hooks/useExploreFilters';

const MoreFiltersLegalStructure = () => {
  const { tentativeFilters, getMutableFilters, updateTentativeFilters } =
    useExploreFilters();
  return (
    <MultiCheckBox
      data={[
        {
          label: 'Fund',
          value: LEGAL_STRUCTURE.FUNDS,
        },
        {
          label: 'Investment trust',
          value: LEGAL_STRUCTURE.INVESTMENT_TRUSTS,
        },
        {
          label: 'ETF',
          value: LEGAL_STRUCTURE.ETF,
        },
      ]}
      value={tentativeFilters.moreFilters?.legalStructure}
      onChange={value => {
        const filters = getMutableFilters();
        filters.moreFilters = filters.moreFilters ?? {};
        filters.moreFilters.legalStructure =
          value.length > 0 ? value : undefined;
        updateTentativeFilters(filters);
      }}
    />
  );
};

export default MoreFiltersLegalStructure;
