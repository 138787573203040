export const getChangedMarkValues = (
  values?: number[],
  marks?: Record<number, string>,
): string => {
  const [min, max] = values ?? [];

  if (min === undefined && max === undefined) return '';

  return `${marks?.[min]} - ${marks?.[max]}`;
};
