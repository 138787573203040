import getScreenWidthMode, {
  ScreenWidthEnum,
} from '@/utils/getScreenWidthMode';

const MOBILE_COLUMN_WIDTH = 55;
const DESKTOP_COLUMN_WIDTH = 150;
const TABLET_COLUMN_WIDTH = 170;

const useGetColWidth = () => {
  const screenWidthMode = getScreenWidthMode();
  const isDesktop = screenWidthMode[ScreenWidthEnum.MinLg];
  const isMobile = screenWidthMode[ScreenWidthEnum.MaxSm];
  const isTablet =
    screenWidthMode[ScreenWidthEnum.MaxLg] &&
    screenWidthMode[ScreenWidthEnum.MinMd];
  const getColWidth = (
    desktopWidth?: number | null,
    tabWidth?: number | null,
    mobileWidth?: number | null,
  ) => {
    const isNull = (value?: number | null, defaultValue?: number) =>
      value === null ? undefined : value || defaultValue;
    if (isDesktop) return isNull(desktopWidth, DESKTOP_COLUMN_WIDTH);
    if (isTablet) return isNull(tabWidth, TABLET_COLUMN_WIDTH);
    return isNull(mobileWidth, MOBILE_COLUMN_WIDTH);
  };

  return { isMobile, getColWidth };
};

export default useGetColWidth;
