import { useEffect, useRef, useState } from 'react';

import tailwindConfig from '@/tailwindWrapper';

export enum EScreenSize {
  xs = 'xs',
  sm = 'sm',
  md = 'md',
  lg = 'lg',
  xl = 'xl',
  '2xl' = '2xl',
}

const SIZES = Object.values(EScreenSize);
export type Sizes = keyof typeof tailwindConfig.theme.screens;

export const screenBreakpoints = Object.keys(
  tailwindConfig.theme.screens,
).reduce(
  (prev, key) => ({
    ...prev,
    [key]: Number(
      tailwindConfig.theme.screens[key as Sizes].slice(
        0,
        tailwindConfig.theme.screens[key as Sizes].indexOf('px'),
      ),
    ),
  }),
  {} as Record<EScreenSize, number>,
);

const getWidthFromSize = (size: Exclude<Sizes, 'xs'>) => {
  return Number(
    tailwindConfig.theme.screens[size].slice(
      0,
      tailwindConfig.theme.screens[size].indexOf('px'),
    ),
  );
};

const useScreenWidth = () => {
  const [currWidth, setCurrWidth] = useState(0);
  const timeoutRef = useRef<NodeJS.Timeout>();

  const isCurrentWidth = (value: EScreenSize) => {
    const currValIndex = SIZES.indexOf(value);
    const nextValue = SIZES[currValIndex + 1];
    switch (value) {
      case EScreenSize.xs:
        return currWidth < getWidthFromSize(EScreenSize.sm);
      case EScreenSize.sm:
      case EScreenSize.md:
      case EScreenSize.lg:
      case EScreenSize.xl:
        if (nextValue !== EScreenSize.xs) {
          return (
            currWidth >= getWidthFromSize(value) &&
            currWidth < getWidthFromSize(nextValue)
          );
        }
        return false;
      case EScreenSize['2xl']:
      default:
        return currWidth >= getWidthFromSize(EScreenSize['2xl']);
    }
  };

  useEffect(() => {
    if (window) {
      const listener = () => {
        if (timeoutRef.current) clearTimeout(timeoutRef.current);
        timeoutRef.current = setTimeout(() => {
          setCurrWidth(window.innerWidth);
        }, 50);
      };
      setCurrWidth(window.innerWidth);
      window.addEventListener('resize', listener);
      return () => window.removeEventListener('resize', listener);
    }
  }, []);

  return { isCurrentWidth, currentWidth: currWidth };
};

export default useScreenWidth;
