import { Slider } from 'antd';
import cx from 'classnames';

import { SLIDER_STEP } from '../../../hooks/moreFiltersSubState';
import {
  OCF_SLIDER_MAX,
  ocfInitialState,
  ocfRangeMarks,
} from '../../../hooks/ocfSubState';
import useExploreFilters from '../../../hooks/useExploreFilters';
import { adjustMarksToAvoidDuplicates } from '../../../utils/adjustMarksToAvoidDuplicates';
import { getRangeSliderClassName } from '../../../utils/getRangeSliderClassName';
import style from '../../style.module.less';

const OCFSlider = () => {
  const { getMutableFilters, updateTentativeFilters, tentativeFilters } =
    useExploreFilters();

  const ocfFilter = tentativeFilters.ocfFilter ?? ocfInitialState;

  return (
    <Slider
      className={cx(
        style[getRangeSliderClassName(!!tentativeFilters.ocfFilter)],
        style['range-slider-cutoff-fix'],
      )}
      tooltip={{ open: false }}
      range
      step={SLIDER_STEP}
      max={OCF_SLIDER_MAX}
      marks={ocfRangeMarks}
      value={[ocfFilter.ocfRange[0], ocfFilter.ocfRange[1]]}
      onChange={value => {
        const filters = getMutableFilters();
        filters.ocfFilter = {
          ocfRange: adjustMarksToAvoidDuplicates(value, ocfRangeMarks),
        };
        updateTentativeFilters(filters);
      }}
    />
  );
};

export default OCFSlider;
