// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".so4wovEVNJPkJypn5n85 .ant-tooltip-content {\n  --tw-bg-opacity: 1;\n  background-color: rgb(255 255 255 / var(--tw-bg-opacity))\n}\n.so4wovEVNJPkJypn5n85 .ant-tooltip-inner {\n  border-radius: 0.5rem;\n  padding-top: 1rem;\n  padding-bottom: 1rem;\n  padding-left: 1.5rem;\n  padding-right: 1.5rem;\n  font-size: 0.75rem;\n  line-height: 1rem;\n  max-width: 190px\n}\n", "",{"version":3,"sources":["webpack://./src/partials/SignificantChanges/style.module.less"],"names":[],"mappings":"AAEI;EAAA,kBAAA;EAAA;AAAA;AAGA;EAAA,qBAAA;EAAA,iBAAA;EAAA,oBAAA;EAAA,oBAAA;EAAA,qBAAA;EAAA,kBAAA;EAAA,iBAAA;EACA;AADA","sourcesContent":[".tooltip-rounded :global {\n  .ant-tooltip-content {\n    @apply bg-white;\n  }\n  .ant-tooltip-inner {\n    @apply rounded-lg py-4 px-6 text-xs;\n    max-width: 190px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"tooltip-rounded": "so4wovEVNJPkJypn5n85"
};
export default ___CSS_LOADER_EXPORT___;
