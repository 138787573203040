import {
  getBackgroundStyleboxColor,
  getHighlightStyleboxColor,
  isFixedIncomeBroadAssetClass,
} from '@aminsights/shared';
import cx from 'classnames';
import React from 'react';

type PositionPair = [number, string | undefined] | [number];

interface Props {
  broadAssetClass: string;
  positionPair: PositionPair[];
  defaultCellsColor?: string;
  className?: string;
  fixedIncome?: boolean;
}

const Stylebox: React.FCWithChild<Props> = ({
  broadAssetClass,
  positionPair,
  defaultCellsColor = 'transparent',
  className,
  fixedIncome,
}) => {
  const cells = Array.from(new Array(9), (_, i) => i + 1);
  const cellsWithColor: PositionPair[] = cells.map(i => {
    const cellInPosition = positionPair.find(p => p[0] === i);
    if (cellInPosition) {
      return [i, cellInPosition[1]];
    }
    return [i, defaultCellsColor];
  });

  const isFixedIncome =
    fixedIncome || isFixedIncomeBroadAssetClass(broadAssetClass);
  const backgroundStyleboxColor = getBackgroundStyleboxColor(isFixedIncome);
  const highlightStyleboxColor = getHighlightStyleboxColor(isFixedIncome);

  return (
    <div
      className={cx(
        'h-full w-full grid grid-cols-3 grid-rows-3 border-collapse min-w-5',
        className,
      )}
      style={{
        border: `1px solid ${backgroundStyleboxColor}`,
      }}
    >
      {cellsWithColor.map(c => (
        <div
          key={c[0]}
          className="w-full h-full border"
          style={{
            borderColor: backgroundStyleboxColor,
            backgroundColor: c[1]
              ? highlightStyleboxColor
              : backgroundStyleboxColor,
          }}
        />
      ))}
    </div>
  );
};

export default Stylebox;
