import { Skeleton } from 'antd';
import cx from 'classnames';

import { IDataTableColumns } from '.';
import style from './style.module.less';

interface RowContentProps<T> {
  key: string;
  item?: T;
  column: IDataTableColumns;
  isEndLeft?: boolean;
  hasAction: boolean;
  loading?: boolean;
  disableVerticalPadding?: boolean;
}

const TableRowContent = <T,>({
  item,
  column,
  hasAction,
  loading,
  disableVerticalPadding = false,
}: RowContentProps<T>) => {
  return (
    <td
      className={cx(
        'border-b border-solid border-grey-light-50 bg-inherit',
        column?.isColumnFixed && 'sticky left-0 z-1',
        {
          [style['table-row-item__action']]: column?.type === 'action',
          [style['table-row-item__fixed--has-action']]:
            column?.isColumnFixed && hasAction,
        },
      )}
      style={{
        backgroundColor:
          column.backgroundColor && item && column.backgroundColor(item),
      }}
    >
      <div
        className={cx(
          'break-words whitespace-normal',
          !column?.isColumnFixed ? 'px-2' : 'px-4',
          'text-xs text-[#3B3C53]',
          'h-full flex gap-x-2',
          'table-cell-inner-container',
          {
            'py-3 sm:py-4 ': !disableVerticalPadding,
            'table-action': column.type && column.type === 'action',
          },
          column.renderType === 'text' || column.renderType === 'custom'
            ? 'text-left justify-start'
            : 'text-right justify-end',
        )}
        style={{
          width: column.width,
          wordBreak: 'break-word', // tailwind doesn't have this property
        }}
      >
        {column.render && !loading
          ? column.render(item)
          : column.loader || (
              <Skeleton title={false} paragraph={{ rows: 1 }} active />
            )}
      </div>
    </td>
  );
};

export default TableRowContent;
