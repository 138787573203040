import { EMPTY_DATA_POINT } from '@aminsights/shared';
import React from 'react';

import { useFundByIsInParam } from '@/hooks/query-hooks/fund-hooks/useFundByIsin';

const Summary: React.FCWithChild = () => {
  const { data: state } = useFundByIsInParam();

  return state?.fund?.aiGeneratedDescription ? (
    <div className="mt-4">
      <p
        className="bg-white p-5 text-sm font-normal text-neutral whitespace-pre-line"
        data-test-id="fundDetailsSummaryTabOverviewDescription"
      >
        {state.fund?.aiGeneratedDescription ?? EMPTY_DATA_POINT}
      </p>
      {state.fund?.aiGeneratedDescription && (
        <div className="py-3 bottom-0 w-full">
          <p className="text-xxs text-neutral px-4 lg:px-0">
            ChatGPT does not make any warranties about the completeness,
            reliability and accuracy of this information.
          </p>
        </div>
      )}
    </div>
  ) : (
    <div />
  ); // To ensure that there's a gap even if there's no description (because of row-gap)
};

export default Summary;
