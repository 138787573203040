import { Investor } from '@aminsights/contract';
import { Fund } from '@aminsights/contract';
import { EMPTY_DATA_POINT, shortenLongNumber } from '@aminsights/shared';
import { buildFundDetailsPath } from '@aminsights/shared';
import getSymbolFromCurrency from 'currency-symbol-map';
import { Link } from 'react-router-dom';

import { IDataTableColumns } from '@/components/Table/DataTable';
import useGetCurrencyRates from '@/hooks/query-hooks/currency-hooks/useGetCurrencyRates';
import ManagersColumn from '@/partials/columns/ManagersColumn';

export const InvestorFundDetailsColumns: (
  legalStructureLabel: string,
) => IDataTableColumns[] = (legalStructureLabel: string) => [
  {
    title: legalStructureLabel,
    sortKey: 'name',
    render: (item: Investor) => (
      <Link
        className="font-medium text-neutral font-bold hover:text-primary"
        to={buildFundDetailsPath(item.shareClassLink)}
      >
        {item.name ?? EMPTY_DATA_POINT}
      </Link>
    ),
    isColumnFixed: true,
    renderType: 'text',
  },
  {
    title: 'Provider',
    sortKey: 'providerName',
    render: (item: Investor) => (
      <div className="text-sm text-neutral">
        {item.providerName ?? EMPTY_DATA_POINT}
      </div>
    ),
    isColumnFixed: true,
    renderType: 'text',
  },
  {
    title: 'Manager',
    idSubText: 'manager',
    render: (item: Fund) => <ManagersColumn fund={item} />,
    renderType: 'text',
    className: 'whitespace-nowrap',
    width: 170,
  },
  {
    title: 'Weight',
    sortKey: 'weight',
    render: (item: Investor) => {
      return (
        <span className="text-neutral">
          {!item.weight ? EMPTY_DATA_POINT : `${item.weight.toFixed(1)}%`}
        </span>
      );
    },
    width: 100,
    align: 'right',
    renderType: 'number',
  },
  {
    title: 'Allocation',
    sortKey: 'allocation',
    render: (item: Investor) => {
      const { data: currencyData } = useGetCurrencyRates();

      return (
        <div className="text-sm text-neutral">
          {!item || !item.allocation || !currencyData?.currencyCode
            ? EMPTY_DATA_POINT
            : `${getSymbolFromCurrency(
                currencyData.currencyCode,
              )}${shortenLongNumber(item.allocation)}`}
        </div>
      );
    },
    width: 100,
    align: 'right',
    renderType: 'number',
  },
];
