import { Fund } from '@aminsights/contract';
import { MsTimePeriod } from '@aminsights/shared';
import { FC } from 'react';

import { useFeatureSwitchContext } from '@/context/FeatureSwitchContext';
import { roundOrEmpty } from '@/utils/roundOrEmpty';

interface Props {
  fund?: Fund;
  timePeriod?: MsTimePeriod;
  format?: (value: number | undefined) => string;
}

export const RenderStandardDeviation: FC<Props> = ({
  fund,
  timePeriod = MsTimePeriod.ONE_YR,
  format,
}) => {
  const { state } = useFeatureSwitchContext();
  const { isComputedRiskDetailsTreatmentEnabled } = state;

  const targetRiskDetails = isComputedRiskDetailsTreatmentEnabled
    ? fund?.computedRiskDetails
    : fund?.riskMeasuresDetail;

  const matchingTimePeriod = targetRiskDetails?.find(
    c => c.timePeriod === timePeriod,
  );

  const standardDeviation = matchingTimePeriod?.standardDeviation;

  return format
    ? format(standardDeviation)
    : roundOrEmpty(standardDeviation, 2, '%');
};
