import { Fund } from '@aminsights/contract';
import { EMPTY_DATA_POINT, MaturityRangeTypeCodes } from '@aminsights/shared';
import { FC } from 'react';

import { DataTable } from '@/components';
import { COMPARE_TOOL_DECIMALS } from '@/constants';
import mapMaturity from '@/utils/data-mappers/mapMaturity';

import { getFundTitle } from '../utils/shared';
import { extractFundTitle } from './extractFundTitle';

interface CompareMaturityProps {
  loading?: boolean;
  funds?: Fund[];
}

const CompareMaturity: FC<CompareMaturityProps> = ({
  loading = true,
  funds,
}) => {
  const allMaturites =
    funds
      ?.map(f => mapMaturity(f))
      .reduce((prev, curr) => {
        if (!prev.length) {
          return curr;
        }
        const currCredit = [...curr].sort((a, b) => b.value - a.value);
        let result = [...prev];
        currCredit.forEach(sc => {
          if (!result.find(i => i.maturity === sc.maturity)) {
            result = [...result, sc];
          }
        });
        return result;
      }, [])
      .sort((a, b) => b.value - a.value) || [];

  return (
    <DataTable
      loading={loading}
      enableHeaderWrap
      uniqueKey="maturity"
      className="md:table-fixed"
      columns={[
        {
          title: 'Maturity',
          render: ({ maturity }) => {
            const maturityValue = allMaturites.find(
              i => i.maturity === maturity,
            )?.maturity;
            return <div className="font-bold">{maturityValue}</div>;
          },
          renderType: 'text',
          idSubText: 'maturity',
          isColumnFixed: true,
          width: 130,
        },
        ...(funds || []).map((f, idx) => ({
          title: getFundTitle({
            isin: f.shareClassDetails.isin,
            fallBackTitle: `Fund ${idx + 1}`,
            loading,
            fundName: extractFundTitle(f),
          }),
          render: ({ maturity }: { maturity: string }) => {
            const maturities = mapMaturity(f) || [];
            const item = maturities.find(i => i.maturity === maturity);
            const value = item?.value.toFixed(COMPARE_TOOL_DECIMALS);
            return <div>{value ? `${value}%` : EMPTY_DATA_POINT}</div>;
          },
          renderType: 'number' as const,
          idSubText: `fund-${idx + 1}`,
        })),
      ]}
      // We'll use the data as keys since this table different data types per row
      data={
        allMaturites.length !== 0
          ? MaturityRangeTypeCodes.map(mrtc => ({
              maturity: mrtc.definition,
            }))
          : []
      }
    />
  );
};

export default CompareMaturity;
