import { FundBroadAssetClass } from '@aminsights/contract';
import { Checkbox } from 'antd';

import useExploreFilters from '../../../hooks/useExploreFilters';

const MoreFiltersAssetClass = () => {
  const { tentativeFilters, getMutableFilters, updateTentativeFilters } =
    useExploreFilters();
  const addOrRemoveAssetClass = (assetClass: FundBroadAssetClass) => {
    const filters = getMutableFilters();

    // Initialize values if they don't exist
    filters.moreFilters = filters.moreFilters ?? {};
    filters.moreFilters.selectedBroadAssetClasses =
      filters.moreFilters.selectedBroadAssetClasses ?? [];

    filters.moreFilters.selectedBroadAssetClasses?.includes(assetClass)
      ? (filters.moreFilters.selectedBroadAssetClasses =
          filters.moreFilters.selectedBroadAssetClasses.filter(
            c => c !== assetClass,
          ))
      : filters.moreFilters.selectedBroadAssetClasses.push(assetClass);
    if (filters.moreFilters.selectedBroadAssetClasses.length === 0) {
      filters.moreFilters.selectedBroadAssetClasses = undefined;
    }
    updateTentativeFilters(filters);
  };

  const alternativeAssetClasses = [
    FundBroadAssetClass.Alternative,
    FundBroadAssetClass.Commodities,
    FundBroadAssetClass.Convertibles,
  ];

  return (
    <div className="flex flex-col">
      <Checkbox
        value={FundBroadAssetClass.Equity}
        checked={tentativeFilters.moreFilters?.selectedBroadAssetClasses?.includes(
          FundBroadAssetClass.Equity,
        )}
        onChange={() => addOrRemoveAssetClass(FundBroadAssetClass.Equity)}
        key={FundBroadAssetClass.Equity}
      >
        Equity
      </Checkbox>
      <Checkbox
        value={FundBroadAssetClass.FixedIncome}
        checked={tentativeFilters.moreFilters?.selectedBroadAssetClasses?.includes(
          FundBroadAssetClass.FixedIncome,
        )}
        onChange={() => addOrRemoveAssetClass(FundBroadAssetClass.FixedIncome)}
        key={FundBroadAssetClass.FixedIncome}
      >
        Fixed Income
      </Checkbox>
      <Checkbox
        value={FundBroadAssetClass.Alternative}
        checked={alternativeAssetClasses.some(assetClass =>
          tentativeFilters.moreFilters?.selectedBroadAssetClasses?.includes(
            assetClass,
          ),
        )}
        onChange={() => {
          addOrRemoveAssetClass(FundBroadAssetClass.Alternative);
          addOrRemoveAssetClass(FundBroadAssetClass.Convertibles);
          addOrRemoveAssetClass(FundBroadAssetClass.Commodities);
        }}
        key={FundBroadAssetClass.Alternative}
      >
        Alternatives
      </Checkbox>
      <Checkbox
        value={FundBroadAssetClass.Property}
        checked={tentativeFilters.moreFilters?.selectedBroadAssetClasses?.includes(
          FundBroadAssetClass.Property,
        )}
        onChange={() => addOrRemoveAssetClass(FundBroadAssetClass.Property)}
        key={FundBroadAssetClass.Property}
      >
        Property
      </Checkbox>
      <Checkbox
        value={FundBroadAssetClass.MoneyMarket}
        checked={tentativeFilters.moreFilters?.selectedBroadAssetClasses?.includes(
          FundBroadAssetClass.MoneyMarket,
        )}
        onChange={() => addOrRemoveAssetClass(FundBroadAssetClass.MoneyMarket)}
        key={FundBroadAssetClass.MoneyMarket}
      >
        Money Market
      </Checkbox>
      <Checkbox
        value={FundBroadAssetClass.Miscellaneous}
        checked={tentativeFilters.moreFilters?.selectedBroadAssetClasses?.includes(
          FundBroadAssetClass.Miscellaneous,
        )}
        onChange={() =>
          addOrRemoveAssetClass(FundBroadAssetClass.Miscellaneous)
        }
        key={FundBroadAssetClass.Miscellaneous}
      >
        Miscellaneous
      </Checkbox>
      <Checkbox
        value={FundBroadAssetClass.Allocation}
        checked={tentativeFilters.moreFilters?.selectedBroadAssetClasses?.includes(
          FundBroadAssetClass.Allocation,
        )}
        onChange={() => addOrRemoveAssetClass(FundBroadAssetClass.Allocation)}
        key={FundBroadAssetClass.Allocation}
      >
        Multi Asset
      </Checkbox>
    </div>
  );
};

export default MoreFiltersAssetClass;
