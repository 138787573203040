import { SLIDER_MAX, SLIDER_MIN } from '@/constants';

import { countSelectedFilter } from '../utils/countSelectedFilter';

export interface EquitiesFilterState {
  equityStyle?: number[];
  roicRange?: number[];
  netMarginRange?: number[];
}

export const equitiesInitialState: EquitiesFilterState = {
  equityStyle: [],
  roicRange: [SLIDER_MIN, SLIDER_MAX],
  netMarginRange: [SLIDER_MIN, SLIDER_MAX],
};

export const equitiesFilterMarks: Record<number, string> = {
  0: '0',
  25: '5%',
  50: '10%',
  75: '15%',
  100: '20%>',
};

export const SLIDER_STEPS = 25;

export const countEquitiesFilters = (filterState?: EquitiesFilterState) => {
  if (!filterState) return 0;

  const a = [
    !!filterState.equityStyle,
    !!filterState.roicRange,
    !!filterState.netMarginRange,
  ];

  return countSelectedFilter(a);
};
