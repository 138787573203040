import { Bucket, Fund } from '@aminsights/contract';
import {
  CountryToShow,
  ICalculatedExtremumsFunds,
  LIMIT_FUNDS_FOR_CHARTING,
} from '@aminsights/shared';
import { Checkbox, Skeleton } from 'antd';

import { IDataTableColumns } from '@/components/Table/DataTable';
import { COLOR_FUND_FEATURED } from '@/constants/colors';
import { UseOnCheckedRowReturn } from '@/hooks/useOnCheckedRow';
import FundInvestmentTrustColumn from '@/partials/columns/FundInvestmentTrustColumn';
import { formatPortfolioUpdateDate } from '@/utils/formatPortfolioUpdateDate';
import getScreenWidthMode, {
  ScreenWidthEnum,
} from '@/utils/getScreenWidthMode';

import generateWatchlistGeographyFIColumn from './GenerateWatchlistGeographyFIColumn';

type WatchlistGeographyFIColumnProps = {
  rowSelectionState: UseOnCheckedRowReturn<Fund>;
  countriesWithExtremums?: ICalculatedExtremumsFunds<string>[];
  countriesColumnsInfoFixedIncome: CountryToShow[];
  currentBucket?: Bucket | undefined;
  onSelectAll: (isChecked: boolean) => void;
};

export const WatchlistGeographyFIColumns = ({
  rowSelectionState,
  countriesWithExtremums,
  countriesColumnsInfoFixedIncome,
  currentBucket,
  onSelectAll,
}: WatchlistGeographyFIColumnProps): Array<IDataTableColumns> => {
  const { onCheckedRow, checkedRows, isAllCheckboxChecked } = rowSelectionState;
  const screenWidthMode = getScreenWidthMode();
  const columnDefinitions: Array<IDataTableColumns> = [
    {
      title: (
        <div className="whitespace-normal text-start">
          Fund/Investment Trust
        </div>
      ),
      dataTestIdSuffix: 'Fund/Investment Trust',
      sortKey: 'fundName',
      idSubText: 'fundName',
      headerCheckbox: (): React.ReactNode => (
        <div className="mr-2" onClick={e => e.stopPropagation()}>
          <Checkbox
            onClick={(event: React.MouseEvent<HTMLInputElement>) => {
              const isChecked = event.currentTarget.checked;
              onSelectAll(isChecked);
              event.stopPropagation();
            }}
            checked={isAllCheckboxChecked}
          />
        </div>
      ),
      backgroundColor: (item: Fund) => {
        const isFeaturedRow = currentBucket?.funds.find(
          f => item.shareClassDetails.isin === f?.isin,
        )?.isFeatured;
        return isFeaturedRow ? COLOR_FUND_FEATURED(isFeaturedRow) : '';
      },
      render: (item: Fund) => {
        const { portfolioUpdateDateMs } = item;
        const formattedPortfolioUpdateDate = formatPortfolioUpdateDate(
          portfolioUpdateDateMs,
        );
        return (
          <div className="flex items-center w-full">
            <div className="mr-2 md:mr-7" onClick={e => e.stopPropagation()}>
              <Checkbox
                value={item.shareClassDetails.isin}
                onChange={e => onCheckedRow([e.target.value])}
                onClick={e => e.stopPropagation()}
                checked={checkedRows.includes(item.shareClassDetails.isin)}
                disabled={
                  checkedRows.length >= LIMIT_FUNDS_FOR_CHARTING &&
                  !checkedRows.includes(item.shareClassDetails.isin)
                }
              />
            </div>
            <div className="w-full cursor-pointer">
              <FundInvestmentTrustColumn
                data={{
                  id: item.shareClassDetails.isin,
                  name: item.shareClassDetails.code,
                  type: item.legalStructure,
                  assetClassCode: item.assetClassCode,
                  fundId: item.fundId,
                  legalStructure: item.legalStructure,
                }}
                infoTooltip={`Portfolio as at ${formattedPortfolioUpdateDate}`}
              />
            </div>
          </div>
        );
      },
      renderType: 'custom',
      align: 'between',
      width: screenWidthMode[ScreenWidthEnum.MaxMd] ? 176 : 320,
      isColumnFixed: true,
      loader: (
        <Skeleton
          avatar={{ size: 'small' }}
          title={false}
          paragraph={{ rows: 2 }}
          active
        />
      ),
    },
    ...countriesColumnsInfoFixedIncome.map(data =>
      generateWatchlistGeographyFIColumn(
        data,
        countriesWithExtremums,
        currentBucket,
      ),
    ),
  ];

  return columnDefinitions;
};
