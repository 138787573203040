import { Fund } from '@aminsights/contract';
import { EMPTY_DATA_POINT, isInvestmentTrust } from '@aminsights/shared';
import { Badge, Tooltip } from 'antd';
import React from 'react';

import {
  isManagerListLengthMoreThanTwo,
  isManagerNotDisclosed,
} from '@/utils/manager-check';

const ManagersColumn: React.FCWithChild<{ fund: Fund }> = ({ fund }) => {
  const firstTwoManagers: React.ReactNode[] = [];
  const allManagers: string[] = [];
  if (!fund.managerList || fund.managerList.length < 1) {
    firstTwoManagers.push(
      <div key="emptyDataPoint" className="text-xs text-[#3B3C53]">
        {EMPTY_DATA_POINT}
      </div>,
    );
  } else {
    fund.managerList?.forEach((data, index) => {
      let fullName = `${data.givenName} ${data.familyName}`.trim() || null;

      if (
        isInvestmentTrust(fund.legalStructure) &&
        isManagerNotDisclosed(fullName || '')
      ) {
        fullName = fund.providerName || EMPTY_DATA_POINT;
      }

      if (isManagerNotDisclosed(fullName || '')) {
        fullName = EMPTY_DATA_POINT;
      }

      if (index <= 1) {
        firstTwoManagers.push(
          <div key={index} className="text-xs text-[#3B3C53]">
            {fullName || ''}
          </div>,
        );
      }
      allManagers.push(fullName || '');
    });
  }

  return (
    <Tooltip
      placement="bottom"
      title={
        isManagerListLengthMoreThanTwo(allManagers) && allManagers.join('\n')
      }
      overlayClassName="md:max-w-none whitespace-pre-wrap"
      overlayInnerStyle={{
        borderRadius: 4,
        backgroundColor: 'rgba(0, 0, 0, 0.9)',
      }}
      arrowPointAtCenter
    >
      {firstTwoManagers}
      {isManagerListLengthMoreThanTwo(allManagers) && (
        <Badge
          title=""
          count={
            isManagerListLengthMoreThanTwo(allManagers) &&
            `+${allManagers.length - firstTwoManagers.length}`
          }
          size="small"
          style={{
            backgroundColor: '#F5F5F6',
            color: 'InfoText',
          }}
        />
      )}
    </Tooltip>
  );
};

export default ManagersColumn;
