import { BenchmarkCountry } from '@aminsights/contract';
import { EMPTY_DATA_POINT } from '@aminsights/shared';
import { FC } from 'react';

import { DataTable } from '@/components';
import { IDataTableColumns } from '@/components/Table/DataTable';
import { COMPARE_TOOL_DECIMALS } from '@/constants';
import { CompareToolWithIndexProps } from '@/types/compare';
import getScreenWidthMode, {
  ScreenWidthEnum,
} from '@/utils/getScreenWidthMode';

import { GeographyCompareData } from '../utils/CompareDataCalculations';
import { getFundTitle } from '../utils/shared';
import useGetColWidth from '../utils/useGetColWidth';
import { extractFundTitle } from './extractFundTitle';

type CompareFundBasicsProps = CompareToolWithIndexProps;

export const MAXIMUM_SECTORS_AND_GEOGRAPHY_ROWS = 10;

type CompareGeographyProps = CompareFundBasicsProps & {
  data?: GeographyCompareData;
  benchmarkCountries?: BenchmarkCountry[];
};

const CompareGeography: FC<CompareGeographyProps> = ({
  data,
  funds,
  benchmarkCountries,
  showIndexColumn = false,
  loading = true,
}) => {
  // Manage mobile state here
  const screenWidthMode = getScreenWidthMode();
  const { getColWidth } = useGetColWidth();

  const tableData = data?.data;

  return (
    <DataTable
      loading={loading}
      enableHeaderWrap
      uniqueKey="id"
      className="md:table-fixed"
      columns={[
        {
          title: 'Country',
          render: ({ name }) => {
            return <div className="font-bold">{name ?? EMPTY_DATA_POINT}</div>;
          },
          renderType: 'text',
          idSubText: 'country',
          width: screenWidthMode[ScreenWidthEnum.MinLg] ? 200 : 120,
          isColumnFixed: true,
        },
        ...(funds || []).map((f, idx) => ({
          title: (
            <div className="min-w-17">
              {getFundTitle({
                isin: f.shareClassDetails.isin,
                fallBackTitle: `Fund ${idx + 1}`,
                loading,
                fundName: extractFundTitle(f),
              })}
            </div>
          ),
          render: ({ id }: GeographyCompareData['data'][number]) => {
            const country = data?.geographies[idx].find(i => i.id === id);
            const value = country?.value.toFixed(COMPARE_TOOL_DECIMALS);
            return <div>{value ? `${value}%` : EMPTY_DATA_POINT}</div>;
          },
          renderType: 'number' as const,
          idSubText: `fund-${idx + 1}`,
        })),
        ...(showIndexColumn
          ? [
              {
                title: 'Index',
                render: ({ id }) => {
                  let totalWeight = 0;
                  if (id) {
                    totalWeight =
                      benchmarkCountries?.find(b => b.countryId === id)
                        ?.weighting ?? 0;
                  }
                  const value = totalWeight?.toFixed(COMPARE_TOOL_DECIMALS);
                  return (
                    <div>
                      {totalWeight !== 0 ? `${value}%` : EMPTY_DATA_POINT}
                    </div>
                  );
                },
                width: getColWidth(100, 80, 65),
                renderType: 'number',
                idSubText: 'index',
              } as IDataTableColumns,
            ]
          : []),
      ]}
      // We'll use the data as keys since this table different data types per row
      data={tableData}
    />
  );
};

export default CompareGeography;
