import cx from 'classnames';
import React, { KeyboardEventHandler, useEffect } from 'react';

import { ReactComponent as IconSearch } from '@/assets/svg/icons/icon-search.svg';
import { Input } from '@/components';
// We should remove this dependency and move to tailwind if possible
import style from '@/partials/SearchBox/style.module.less';

interface ISearchProps {
  onChange: (value: string) => void;
  placeholder?: string;
  onKeyDown?: KeyboardEventHandler;
  key?: string;
  autoFocus?: boolean;
  visible?: boolean;
  input?: string;
  setSearchText?: (value: string) => void;
  dataTestId?: string;
}

export const Search: React.FCWithChild<ISearchProps> = ({
  onChange,
  placeholder,
  onKeyDown,
  autoFocus,
  visible,
  input,
  setSearchText,
  dataTestId = 'searchInput',
}) => {
  useEffect(() => {
    if (visible === false) {
      setSearchText?.('');
    }
  }, [visible]);
  return (
    <div className={cx(style['search-wrapper'])}>
      <Input
        autoFocusDeps={[visible]}
        autoFocus={autoFocus}
        placeholder={placeholder || 'Search'}
        className={cx(style['search-dropdown'])}
        type="search"
        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
          onChange(e.target.value)
        }
        onKeyDown={onKeyDown}
        dataTestId={dataTestId}
        value={input}
      />
      <IconSearch className={cx('icon', style['search-icon'])} />
    </div>
  );
};
