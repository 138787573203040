// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".sqKV7miM4YYJnpsNjTgr .dPzQ5zXAaHBXUhq1nD2u {\n  width: 100%;\n  overflow: hidden;\n}\n.sqKV7miM4YYJnpsNjTgr .dPzQ5zXAaHBXUhq1nD2u .lAFFFf_0CW9lEegvjEAu {\n  position: relative;\n  --tw-bg-opacity: 1;\n  background-color: rgb(255 255 255 / var(--tw-bg-opacity));\n  height: 46px;\n}\n@media (max-width: 576px) {\n  .sqKV7miM4YYJnpsNjTgr .dPzQ5zXAaHBXUhq1nD2u .lAFFFf_0CW9lEegvjEAu {\n    padding-top: 10px;\n  }\n}\n.sqKV7miM4YYJnpsNjTgr .dPzQ5zXAaHBXUhq1nD2u .lAFFFf_0CW9lEegvjEAu .ant-tabs-tab-active {\n  font-weight: 400;\n}\n", "",{"version":3,"sources":["webpack://./src/pages/app/SignificantChanges/style.module.less"],"names":[],"mappings":"AAEI;EAAA,WAAA;EAAA;AAAA;AAGE;EAAA,kBAAA;EAAA,kBAAA;EAAA,yDAAA;EACA;AADA;AAGA;EAAA;IACE,iBAAA;EACN;AACF;AAEQ;EAAA;AAAA","sourcesContent":[".section-significant-changes {\n  .significant-changes-wrapper {\n    @apply w-full overflow-hidden;\n\n    .buckets-wrapper {\n      @apply bg-white relative;\n      height: 46px;\n\n      @media (max-width: 576px) {\n        padding-top: 10px;\n      }\n\n      :global .ant-tabs-tab-active {\n        @apply font-normal;\n      }\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"section-significant-changes": "sqKV7miM4YYJnpsNjTgr",
	"significant-changes-wrapper": "dPzQ5zXAaHBXUhq1nD2u",
	"buckets-wrapper": "lAFFFf_0CW9lEegvjEAu"
};
export default ___CSS_LOADER_EXPORT___;
