import { Modal } from 'antd';
import cx from 'classnames';
import React from 'react';
import { useHistory } from 'react-router-dom';

import { ReactComponent as IconCheck } from '@/assets/svg/icons/icon-check-colored.svg';
import Button from '@/components/Button';

import style from './style.module.less';

type ModalProps = {
  isVisible: boolean;
  className?: string;
  onApply?: () => void;
};

const AllFundsAssignedModal: React.FCWithChild<ModalProps> = ({
  isVisible,
  className,
}) => {
  const history = useHistory();
  return (
    <Modal
      centered={true}
      width={720}
      open={isVisible}
      title={
        <div className="flex flex-col text-center items-center gap-y-4">
          <IconCheck className="m-auto" width={64} height={64} />
          <div className="text-xl font-bold">
            All funds/trusts has been assigned to buckets
          </div>
        </div>
      }
      className={cx(
        'max-sm:full-page-modal action-modal-confirmation action-modal [&_.ant-modal-header]:mb-0 [&_.ant-modal-header]:pb-4',
        '[&_.ant-modal-footer]:!justify-center [&_.ant-modal-footer]:!items-center',
        className,
      )}
      closable={false}
      footer={[
        <Button
          size="large"
          type="primary"
          className="font-semibold mb-0"
          onClick={() => {
            history.goBack();
          }}
          key="primary"
        >
          View Buckets
        </Button>,
      ]}
    >
      <div className="text-center">
        <div className={style['modal-info']}>
          If you want to change anything you can do so in your watchlist.
        </div>
      </div>
    </Modal>
  );
};

export default AllFundsAssignedModal;
