import { Checkbox } from '@/components';

import {
  checkboxSelectAllData,
  filterDatesCall,
  SELECT_ALL,
} from '../../../hooks/esgSubState';
import useExploreFilters from '../../../hooks/useExploreFilters';
import styles from '../../style.module.less';

type CheckboxValueType = any;

const EsgF4BFilter = () => {
  const { tentativeFilters, getMutableFilters, updateTentativeFilters } =
    useExploreFilters();
  const esgFilters = tentativeFilters.esgFilters;
  const filterDates = filterDatesCall();
  return (
    <div data-test-id={'ESGLabelF4B'}>
      <div className={styles['column-checkbox']}>
        <div className="ant-checkbox-group flex">
          <Checkbox
            data={checkboxSelectAllData}
            direction="row"
            onChange={(list: Array<CheckboxValueType>) => {
              const filters = getMutableFilters();
              filters.esgFilters = filters.esgFilters ?? {};
              if (list.includes(SELECT_ALL)) {
                filters.esgFilters.financeForBiodiversityDates =
                  filterDates.options.map(option => option.value);
                updateTentativeFilters(filters);
              } else {
                filters.esgFilters.financeForBiodiversityDates = undefined;
                updateTentativeFilters(filters);
              }
            }}
            value={
              (esgFilters?.financeForBiodiversityDates ?? []).length >=
              filterDates.options.length
                ? [SELECT_ALL]
                : []
            }
          />
          <Checkbox
            data={filterDates.options}
            direction="row"
            onChange={(checkedItems: string[]) => {
              const filters = getMutableFilters();
              filters.esgFilters = filters.esgFilters ?? {};
              filters.esgFilters.financeForBiodiversityDates =
                checkedItems.length > 0 ? checkedItems : undefined;
              updateTentativeFilters(filters);
            }}
            value={esgFilters?.financeForBiodiversityDates}
          />
        </div>
      </div>
    </div>
  );
};

export default EsgF4BFilter;
