import { ExploreApi } from '@aminsights/contract';
import {
  ExploreFilterParameters,
  ExploreSearchParameters,
  minifiedParamKeysMap,
  URLParamKeys,
  URLParamState,
} from '@aminsights/shared';

import { openApiConfig } from '@/utils';

import { useExploreContext } from './context';
import { EXPLORE_ACTIONS, IndexBasedParamArrays, paramKeysType } from './types';

const isArray = (inp: unknown): inp is any[] => {
  if (Array.isArray(inp)) {
    return true;
  }
  return false;
};

const transformIndexBasedParams = <T>(
  indices: string,
  source: T[],
  accessor: keyof T,
) => {
  return indices.split(',').map(i => source[parseInt(i)]?.[accessor]);
};

export const convertParamsToState = (
  searchParams: URLSearchParams,
  indexArrays?: IndexBasedParamArrays | null,
  indexAsValue?: boolean,
) => {
  const { categories, providers, iaSectors } = indexArrays || {
    categories: [],
    iaSectors: [],
    providers: [],
  };
  let state: Partial<URLParamState> = {};
  (Object.keys(minifiedParamKeysMap) as URLParamKeys[]).forEach(k => {
    const key = minifiedParamKeysMap[k];
    if (searchParams.has(key)) {
      const value = (() => {
        const val = searchParams.get(key);
        if (val) {
          if (!indexAsValue) {
            if (k === 'categories') {
              return transformIndexBasedParams(val, categories, 'label');
            }
            if (k === 'managementGroup') {
              return transformIndexBasedParams(val, providers, 'label');
            }
            if (k === 'iaSectors') {
              return transformIndexBasedParams(val, iaSectors, 'label');
            }
          }
          if (paramKeysType.numberArray.includes(key)) {
            return val.split(',').map(i => Number.parseFloat(i));
          }
          if (paramKeysType.boolean.includes(key)) {
            return JSON.parse(val);
          }
          if (paramKeysType.stringArray.includes(key)) {
            return val.split(',').map(decodeURIComponent);
          }
          if (paramKeysType.number.includes(key)) {
            return Number.parseFloat(val);
          }
          return val;
        }
        return undefined;
      })();
      state = {
        ...state,
        [k]: value,
      };
    }
  });
  return state;
};

export const convertStateToString = (
  state: Partial<ExploreFilterParameters & ExploreSearchParameters>,
) => {
  return (Object.keys(state) as URLParamKeys[])
    .map(k => {
      const currVal = state[k];
      const key = minifiedParamKeysMap[k];
      // omit size and page as we're doing infinite loading
      // but we still include them when parsing based solely on state
      if (key === 'p' || key === 's') {
        return null;
      }
      if (isArray(currVal)) {
        if (currVal.length) {
          return `${key}=${currVal.map(v => encodeURIComponent(v)).join(',')}`;
        }
        return '';
      }
      if (currVal) {
        return `${key}=${encodeURIComponent(currVal)}`;
      }
      return '';
    })
    .filter(Boolean)
    .join('&');
};

const useProvideExplore = () => {
  const { state, dispatch } = useExploreContext();
  const api = new ExploreApi(openApiConfig());

  const fetchDefaultExploreFilters = async () => {
    const result = await api.getExploreFilters();
    dispatch({
      type: EXPLORE_ACTIONS.GET_DEFAULT_FILTERS,
      payload: result.data,
    });
  };

  const setSearchParameters = (params: Partial<ExploreSearchParameters>) => {
    dispatch({
      type: EXPLORE_ACTIONS.SET_SEARCH_PARAMETERS,
      payload: {
        ...params,
      },
    });
  };

  const setFilterParameters = (params: Partial<ExploreFilterParameters>) => {
    dispatch({
      type: EXPLORE_ACTIONS.SET_FILTER_PARAMETERS,
      payload: {
        ...params,
      },
    });
  };

  const anyFiltersSelected = () => {
    return !Object.values(state.filterParameters).every(
      o => o === undefined || o === false || o === '' || o.length === 0,
    );
  };

  const clearSearchParameters = () => {
    dispatch({
      type: EXPLORE_ACTIONS.CLEAR_PARAMETERS,
    });
  };

  const resetClearState = () => {
    dispatch({
      type: EXPLORE_ACTIONS.RESET_CLEAR_STATE,
    });
  };

  return {
    state,
    fetchDefaultExploreFilters,
    setSearchParameters,
    setFilterParameters,
    anyFiltersSelected,
    clearSearchParameters,
    resetClearState,
  };
};

export default useProvideExplore;
