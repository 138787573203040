// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".HzqjqPmQXlPoh56fog2Y::-webkit-outer-spin-button,\n.HzqjqPmQXlPoh56fog2Y::-webkit-inner-spin-button {\n  -webkit-appearance: none;\n  margin: 0;\n}\n.HzqjqPmQXlPoh56fog2Y[type='number'] {\n  -moz-appearance: textfield;\n}\n", "",{"version":3,"sources":["webpack://./src/partials/columns/EditableColumns/style.module.less"],"names":[],"mappings":"AAEA;;EAEE,wBAAA;EACA,SAAA;AADF;AAIA;EACE,0BAAA;AAFF","sourcesContent":["// No utility classes for tailwind\n\n.editable-input::-webkit-outer-spin-button,\n.editable-input::-webkit-inner-spin-button {\n  -webkit-appearance: none;\n  margin: 0;\n}\n\n.editable-input[type='number'] {\n  -moz-appearance: textfield;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"editable-input": "HzqjqPmQXlPoh56fog2Y"
};
export default ___CSS_LOADER_EXPORT___;
