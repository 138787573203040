import { YesNoOption } from '@aminsights/contract';

import { Checkbox } from '@/components';

import { EsgFilterState } from '../../../hooks/esgSubState';
import useExploreFilters from '../../../hooks/useExploreFilters';

interface FilterComponentProps {
  filterKey: keyof Pick<
    EsgFilterState,
    'netZeroAssetManager' | 'stewardshipCode'
  >;
}

const EsgYesNoFilter: React.FC<FilterComponentProps> = ({ filterKey }) => {
  const { getMutableFilters, updateTentativeFilters, tentativeFilters } =
    useExploreFilters();

  const handleChange = (option: YesNoOption | undefined) => {
    const filters = getMutableFilters();
    filters.esgFilters = filters.esgFilters ?? {};
    filters.esgFilters[filterKey] = option;
    updateTentativeFilters(filters);
  };

  return (
    <Checkbox
      data={Object.values(YesNoOption).map(i => ({ label: i, value: i }))}
      direction="row"
      onChange={handleChange}
      value={tentativeFilters.esgFilters?.[filterKey]}
      isRadio
    />
  );
};

export default EsgYesNoFilter;
