import { Dropdown, Menu } from 'antd';
import cx from 'classnames';
import React, { useEffect, useState } from 'react';

import { ReactComponent as NextArrow } from '@/assets/svg/icons/icon-next-arrow.svg';
import Button from '@/components/Button';
import SelectedDropdownFilter from '@/components/SearchSelectedFilterIndicator';
import useScreenWidth, { screenBreakpoints } from '@/hooks/screenWidth';

import { ocfRangeMarks } from '../../../hooks/ocfSubState';
import useExploreFilters from '../../../hooks/useExploreFilters';
import { getChangedMarkValues } from '../../../utils/getChangedMarkValues';
import OCFSlider from './OCFSlider';
import style from './style.module.less';

const OCFDropdown = () => {
  const {
    syncFilterByPath,
    tentativeFilters,
    filters,
    getMutableFilters,
    updateTentativeFilters,
  } = useExploreFilters();
  const [isVisible, setIsVisible] = useState(false);

  const onSubmit = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    syncFilterByPath('ocfFilter');
    setIsVisible(false);
  };

  const [clearDisabled, setClearDisabled] = useState(true);
  const [applyDisabled, setApplyDisabled] = useState(true);

  useEffect(() => {
    setApplyDisabled(
      JSON.stringify(tentativeFilters.ocfFilter?.ocfRange) ===
        JSON.stringify(filters.ocfFilter?.ocfRange),
    );
  }, [tentativeFilters, filters]);

  useEffect(() => {
    setClearDisabled(applyDisabled && !filters.ocfFilter?.ocfRange);
  });

  const { currentWidth } = useScreenWidth();
  const shouldUseMobileFilter = currentWidth < screenBreakpoints.lg;

  useEffect(() => {
    if (shouldUseMobileFilter) {
      setIsVisible(false);
    }
  }, [shouldUseMobileFilter]);

  const menu = (
    <form className={style['button-search-dropdown-form']}>
      <Menu className={style['button-search-dropdown-menu']}>
        <div className={style['button-search-dropdown-menu-items']}>
          <div>
            <div className="flex flex-row justify-between ">
              <div className="flex-column">
                <p>OCF</p>
              </div>
              <div className="flex-column">
                <p className="font-semibold">
                  {getChangedMarkValues(
                    tentativeFilters?.ocfFilter?.ocfRange,
                    ocfRangeMarks,
                  )}
                </p>
              </div>
            </div>

            <div data-test-id={`OCF`} className={style['range-slider']}>
              <OCFSlider />
            </div>
          </div>
        </div>
      </Menu>
      <div className={style['button-search-dropdown-form-footer']}>
        <Button
          data-test-id={`clearOCF`}
          size="large"
          type="link"
          htmlType="button"
          className="font-semibold"
          onClick={() => {
            const filters = getMutableFilters();
            filters.ocfFilter = undefined;
            updateTentativeFilters(filters);
          }}
          disabled={clearDisabled}
        >
          Clear
        </Button>
        <Button
          data-test-id={`applyOCF`}
          size="large"
          type="primary"
          htmlType="submit"
          className="font-semibold"
          onClick={onSubmit}
          disabled={applyDisabled}
        >
          Apply
        </Button>
      </div>
    </form>
  );
  return (
    <>
      <div className={style['button-search-dropdown']}>
        <Dropdown
          overlay={menu}
          trigger={['click']}
          placement={'bottomLeft' || 'bottomRight'}
          open={isVisible}
        >
          <Button
            className={style['button-search-dropdown-wrapper']}
            onClick={() => setIsVisible(prev => !prev)}
            data-test-id={'OCF'}
          >
            <div
              className={cx(style['button-search-dropdown-label'], {
                [style['with-selected']]: filters.ocfFilter?.ocfRange,
              })}
            >
              <SelectedDropdownFilter
                placeholder={'OCF'}
                noOfSelectedFilter={filters.ocfFilter ? 1 : 0}
                className={style['select-dropdown-filter-wrapper']}
              />
            </div>
            <span className={style['icon-arrow-wrapper']}>
              <NextArrow
                className={cx('icon', style['button-search-dropdown-caret'])}
              />
            </span>
          </Button>
        </Dropdown>
      </div>
      {isVisible && (
        <div
          className={style['button-search-dropdown-overlay']}
          onClick={() => setIsVisible(false)}
          onKeyUp={() => setIsVisible(false)}
          role="button"
          tabIndex={0}
        >
          {' '}
        </div>
      )}
    </>
  );
};

export default OCFDropdown;
