import { useBenchmarkOptions } from '@/hooks/query-hooks/benchmark-hooks/useManageBenchmarks';
import ChartEmptyState from '@/partials/Charts/ChartEmptyState';
import RelativeChartTitle from '@/partials/Charts/relative/RelativeChartTitle';
import RelativeChartV2WithPortfolio from '@/partials/Charts/relative/RelativeChartV2WithPortfolio';

import useChartTrustFundsFromSearch from '../utils/useChartFundsFromSearch';
import { useChartingContext } from './ChartingContext';

const RelativeChartingToolPage = () => {
  const {
    isins,
    benchmark,
    setBenchmark,
    setIsins,
    hasInvestmentTrust,
    portfolios,
    setPortfolios,
  } = useChartTrustFundsFromSearch();

  const chartingCtx = useChartingContext();
  const { data, isLoading } = useBenchmarkOptions();
  const legendData = chartingCtx.chartLegend;
  const chartColors = chartingCtx.chartColors;

  if (!(isins.length || portfolios.length) || !benchmark) {
    const title = !!isins.length
      ? 'Relative performance not available'
      : 'No added funds/trusts/portfolios to chart';
    const subTitle = !!isins.length
      ? 'Select an index'
      : 'Search funds/portfolios to chart';
    return (
      <ChartEmptyState
        title={title}
        subtitle={subTitle}
        isDashboardPage={true}
      />
    );
  }

  const matchingBenchmark = data?.find(b => b.secId === benchmark)?.name;

  return (
    <>
      <RelativeChartTitle
        hasInvestmentTrust={hasInvestmentTrust}
        benchmarkName={isLoading ? '' : matchingBenchmark}
        showDropdown={true}
      />
      <RelativeChartV2WithPortfolio
        emptyState={{
          title: 'No added funds/trusts/portfolios to chart',
          subTitle: 'Search funds/portfolios to chart',
        }}
        onRemoveFund={(id, isBenchmark, isPortfolio) => {
          if (isBenchmark) {
            setBenchmark('');
            return;
          }
          if (isPortfolio) {
            setPortfolios([id], true);
            const targetId =
              legendData.find(ld => ld.id?.includes(id))?.id || '';
            const targetColor = chartColors[targetId];
            targetColor && chartingCtx.setColorsRemoved(targetColor);
            return;
          }
          const targetId = legendData.find(ld => ld.isin === id)?.id || '';
          const targetColor = chartColors[targetId];
          targetColor && chartingCtx.setColorsRemoved(targetColor);
          setIsins([id], true);
        }}
        showDropdown={true}
        isins={isins}
        benchmarkId={benchmark}
        hasInvestmentTrust={hasInvestmentTrust}
        resetInvReturnFilterOnChange={false}
        portfolios={portfolios}
        chartColors={chartColors}
      />
    </>
  );
};

export default RelativeChartingToolPage;
