import { Fund } from '@aminsights/contract';
import { EMPTY_DATA_POINT } from '@aminsights/shared';
import { DISPLAY_DATE_FORMAT } from '@aminsights/shared';
import dayjs from 'dayjs';
import React, { useEffect, useState } from 'react';

import PieChart from '@/components/Charts/PieChart';
import { useFundByIsInParam } from '@/hooks/query-hooks/fund-hooks/useFundByIsin';
import { roundOrEmpty } from '@/utils/roundOrEmpty';

interface IAsset {
  type: string;
  value: number;
  color: string;
}

const COLOR_EQUITIES = '#1ADBA3';
const COLOR_FIXED_INCOME = '#01A299';
const COLOR_CASH = '#FF5F91';
const COLOR_OTHER = '#2FBBFB';
const COLOR_CONVERTIBLES = '#8075FF';
const COLOR_PREFERRED = '#1681F6';

const formFundAssets = (fund: Fund) => [
  {
    type: 'Equities',
    value: (fund.aaLongPctEquity ?? 0) - (fund.aaShortPctEquity ?? 0),
    color: COLOR_EQUITIES,
  },
  {
    type: 'Fixed Income',
    value: (fund.aaLongPctBond ?? 0) - (fund.aaShortPctBond ?? 0),
    color: COLOR_FIXED_INCOME,
  },
  {
    type: 'Cash',
    value: (fund.aaLongPctCash ?? 0) - (fund.aaShortPctCash ?? 0),
    color: COLOR_CASH,
  },
  {
    type: 'Others',
    value: (fund.aaLongPctOther ?? 0) - (fund.aaShortPctOther ?? 0),
    color: COLOR_OTHER,
  },
  {
    type: 'Convertibles',
    value: (fund.aaLongPctConvertible ?? 0) - (fund.aaShortPctConvertible ?? 0),
    color: COLOR_CONVERTIBLES,
  },
  {
    type: 'Preferred',
    value: (fund.aaLongPctPreferred ?? 0) - (fund.aaShortPctPreferred ?? 0),
    color: COLOR_PREFERRED,
  },
];

const AssetAllocation: React.FCWithChild = () => {
  const { data: state } = useFundByIsInParam();
  const [fundAssets, setFundAssets] = useState<IAsset[]>([]);

  useEffect(() => {
    if (state?.fund) {
      setFundAssets(formFundAssets(state.fund));
    }
  }, [state]);

  return (
    <div
      className="pt-6 bg-white lg:rounded-lg"
      data-test-id="fundDetailsAssetAllocationTable"
    >
      <h2 className="font-bold text-base mb-5 px-4">Asset Allocation</h2>

      <div className="px-3">
        <PieChart
          title="% of total assets"
          data={fundAssets}
          colors={fundAssets.map(fa => fa.color)}
        />
      </div>

      <div className="text-center pb-4 border-b border-solid border-grey-light-50 mt-4">
        <p className="font-normal text-neutral text-xs">Assets</p>
        <h5 className="text-sm font-medium text-darkest">
          {state?.fund?.portfolioUpdateDateMs
            ? dayjs(state.fund?.portfolioUpdateDateMs).format(
                DISPLAY_DATE_FORMAT,
              )
            : EMPTY_DATA_POINT}
        </h5>
      </div>

      {fundAssets?.length
        ? fundAssets.map(({ type, value, color }) => (
            <div
              className="border-t border-solid border-grey-light-50 flex p-4 bt-0"
              key={type}
            >
              <p className="flex items-center left-content">
                <span
                  className="w-2.5 h-2.5 rounded-full mr-3.5"
                  style={{ backgroundColor: color }}
                />
                <span className="type font-bold">{type}</span>
              </p>
              <p className="ml-auto font-bold">{roundOrEmpty(value, 1, '%')}</p>
            </div>
          ))
        : ''}
    </div>
  );
};

export default AssetAllocation;
