import { WATCHLIST_TAB_KEY } from '@aminsights/shared';
import { useContext } from 'react';

import { TabContext } from '../Watchlist/WatchlistTabWrapper';
import WatchlistAnnualisedPerformanceTable from '../WatchlistTables/WatchlistAnnualisedPerformance/WatchlistAnnualisedPerformanceTable';
import WatchlistCalendarPerformanceTable from '../WatchlistTables/WatchlistCalendarPerformance/WatchlistCalendarPerformanceTable';
import WatchlistRiskTable from '../WatchlistTables/WatchlistRisk/WatchlistRiskTable';

const childrenList = [
  {
    label: (
      <span
        className="is-watchlist-single-tab-item"
        data-test-id="tabAnnualisedPerformance"
      ></span>
    ),
    key: WATCHLIST_TAB_KEY.ANNUALISED_PERFORMANCE,
    children: <WatchlistAnnualisedPerformanceTable isActive={true} />,
  },
  {
    label: (
      <span
        className="is-watchlist-single-tab-item"
        data-test-id="tabCalendarYearPerformance"
      ></span>
    ),
    key: WATCHLIST_TAB_KEY.CALENDAR_YEAR_PERFORMANCE,
    children: <WatchlistCalendarPerformanceTable isActive={true} />,
  },
  {
    label: (
      <span
        className="is-watchlist-single-tab-item"
        data-test-id="tabRisk"
      ></span>
    ),
    key: WATCHLIST_TAB_KEY.RISK,
    children: <WatchlistRiskTable isActive={true} />,
  },
];

const PerfRiskChildren = () => {
  const { activeTabMenuItem } = useContext(TabContext);
  return childrenList.find(child => child.key === activeTabMenuItem)?.children;
};

export default PerfRiskChildren;
