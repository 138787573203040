// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "@media (max-width: 767px) {\n  .v4vmrpcJlGVAisHrL3tQ .ant-alert {\n    width: 100%;\n    flex-wrap: wrap;\n    height: 92px;\n  }\n  .v4vmrpcJlGVAisHrL3tQ .ant-alert .ant-alert-content {\n    flex: 0 0 100%;\n  }\n  .v4vmrpcJlGVAisHrL3tQ .ant-alert .ant-alert-action {\n    margin: 0px;\n    flex: 1 1 0%;\n  }\n  .v4vmrpcJlGVAisHrL3tQ .ant-alert .ant-alert-action .ant-btn {\n    margin-right: 1.5rem;\n    padding: 0px;\n  }\n  .v4vmrpcJlGVAisHrL3tQ .ant-alert .ant-alert-action .icon {\n    height: 1.5rem;\n    width: 1.5rem;\n  }\n}\n", "",{"version":3,"sources":["webpack://./src/partials/Toast/TablesActionToast/style.module.less"],"names":[],"mappings":"AAEI;EACE;IAAA,WAAA;IAAA,eAAA;IACA;EADA;EADF;IAKI,cAAA;EAAN;EAIM;IAAA,WAAA;IAAA;EAAA;EAGE;IAAA,oBAAA;IAAA;EAAA;EAIA;IAAA,cAAA;IAAA;EAAA;AADV","sourcesContent":[".funds-table-toast {\n  :global .ant-alert {\n    @media (max-width: 767px) {\n      @apply flex-wrap w-full;\n      height: 92px;\n\n      .ant-alert-content {\n        flex: 0 0 100%;\n      }\n\n      .ant-alert-action {\n        @apply flex-1 m-0;\n\n        .ant-btn {\n          @apply p-0 mr-6;\n        }\n\n        .icon {\n          @apply w-6 h-6;\n        }\n      }\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"funds-table-toast": "v4vmrpcJlGVAisHrL3tQ"
};
export default ___CSS_LOADER_EXPORT___;
