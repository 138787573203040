import { Checkbox } from 'antd';

import useExploreFilters from '../../../hooks/useExploreFilters';
import style from '../../style.module.less';

const MoreFiltersOldestShareclass = () => {
  const { tentativeFilters, getMutableFilters, updateTentativeFilters } =
    useExploreFilters();
  return (
    <>
      <div className={style['oldest-shareclass-checkbox']}>
        <Checkbox
          checked={tentativeFilters.moreFilters?.filterByOldestShareClassOnly}
          onChange={() => {
            const filters = getMutableFilters();
            filters.moreFilters = filters.moreFilters ?? {};
            filters.moreFilters.filterByOldestShareClassOnly =
              !tentativeFilters.moreFilters?.filterByOldestShareClassOnly;
            updateTentativeFilters(filters);
          }}
        >
          Filter by oldest shareclass only
        </Checkbox>
      </div>
      <div className={style['oldest-shareclass-subtext']}>
        <p>
          <span className={style['oldest-shareclass-subtext-bold']}>Pro:</span>
          <span> Limits results to 1 row per fund</span>
        </p>
        <p>
          <span className={style['oldest-shareclass-subtext-bold']}>Con:</span>
          <span> OCF filter only searches oldest shareclasses</span>
        </p>
      </div>
    </>
  );
};
export default MoreFiltersOldestShareclass;
