import { Bucket, Portfolio } from '@aminsights/contract';
import { Modal } from 'antd';
import cx from 'classnames';
import React, { useRef, useState } from 'react';

import { Button } from '@/components';
import { APP_ACTIONS } from '@/constants';
import { useAppContext } from '@/context/AppContext';
import { UpsertPortfolioForm } from '@/partials/Forms/Portfolio';
import { UpsertPortfolioFields } from '@/partials/Forms/Portfolio/type';
import {
  emptyPortfolioForm,
  UpsertPortfolioFormRef,
} from '@/partials/Forms/Portfolio/UpsertPortfolioForm';

import LimitReachModal, { StaticDataForLimitModal } from '../LimitReachModal';

export interface StaticDataForAddNewEntityModal
  extends StaticDataForLimitModal {
  limitMessage?: string;
}
interface ModalProps {
  staticModalData: StaticDataForAddNewEntityModal;
  entities: (Portfolio | Bucket)[];
  onSaveClick: (payload: UpsertPortfolioFields) => Promise<any>;
  isVisible: boolean;
  className?: string;
  toggleModal: () => void;
}
const AddPortfolioModal: React.FCWithChild<ModalProps> = ({
  entities,
  staticModalData,
  onSaveClick,
  isVisible,
  className,
  toggleModal,
}) => {
  const { dispatch: dispatchApp } = useAppContext();
  const [isLimitReachModalOpen, setIsLimitReachModalOpen] = useState(false);
  const [saveButtonDisabled, setSaveButtonDisabled] = useState(false);
  const limitEntities = staticModalData.limitForEntities;
  const portfolioFormRef = useRef<UpsertPortfolioFormRef>(null);

  const onSubmit = (values: UpsertPortfolioFields) => {
    if (entities.length >= limitEntities) {
      setIsLimitReachModalOpen(true);
    } else {
      onSaveClick(values)
        .then(() => {
          dispatchApp({
            type: APP_ACTIONS.SET_SUCCESS_MESSAGE,
            payload: {
              text: `Successfully added a new ${staticModalData.entityName.toLowerCase()}`,
            },
          });
        })
        .catch(() => {
          dispatchApp({
            type: APP_ACTIONS.SET_ERROR_MESSAGE,
            payload: `${staticModalData.entityName} not added`,
          });
        })
        .finally(() => {
          toggleModal();
        });
    }
  };
  const handleLimitReachModalOpen = () => {
    setIsLimitReachModalOpen(prev => !prev);
  };
  const entityNameText = staticModalData.entityName;
  const infoText = staticModalData.limitMessage ?? '';
  return (
    <>
      <Modal
        centered={true}
        destroyOnClose
        width={720}
        open={isVisible}
        onCancel={toggleModal}
        className={cx('max-sm:full-page-modal sm:information-modal', className)}
        footer={[
          <Button type="link" onClick={toggleModal}>
            Cancel
          </Button>,
          <Button
            type="primary"
            disabled={saveButtonDisabled}
            onClick={() => {
              portfolioFormRef?.current?.onSave?.();
            }}
          >
            Create
          </Button>,
        ]}
        title={`Add New ${entityNameText}`}
      >
        <div className="[&_.portfolio-save-btn]:hidden [&_form]:!p-0">
          <div className="font-normal text-[#6f707a]">{infoText}</div>

          <UpsertPortfolioForm
            portfolio={emptyPortfolioForm}
            submitting={false}
            onSubmit={onSubmit}
            error={null}
            ref={portfolioFormRef}
            onButtonDisabled={isDisabled => setSaveButtonDisabled(!!isDisabled)}
          />
        </div>
      </Modal>
      <LimitReachModal
        isFundTrust={false}
        staticData={staticModalData}
        isVisible={isLimitReachModalOpen}
        toggleModal={handleLimitReachModalOpen}
      />
    </>
  );
};
export default AddPortfolioModal;
