import { Tooltip } from 'antd';
import cx from 'classnames';
import React, { ReactElement } from 'react';

import style from './style.module.less';

type CustomTooltipProps = {
  title?: string | ReactElement;
  placement?:
    | 'topLeft'
    | 'top'
    | 'topRight'
    | 'leftTop'
    | 'left'
    | 'leftBottom'
    | 'rightTop'
    | 'right'
    | 'rightBottom'
    | 'bottomLeft'
    | 'bottom'
    | 'bottomRight';
  color?: string;
  zIndex?: number;
  mouseEnterDelay?: number;
  mouseLeaveDelay?: number;
  visible?: boolean;
  overlayClassName?: string;
  defaultOpen?: boolean;
} & React.ComponentProps<typeof Tooltip>;

const CustomTooltip: React.FCWithChild<CustomTooltipProps> = ({
  children,
  title,
  placement,
  className,
  zIndex,
  color,
  mouseEnterDelay,
  mouseLeaveDelay,
  visible,
  overlayClassName,
  defaultOpen,
  ...tooltipProps
}) => {
  return (
    <Tooltip
      title={title}
      placement={placement}
      zIndex={zIndex}
      color={color}
      mouseEnterDelay={mouseEnterDelay}
      mouseLeaveDelay={mouseLeaveDelay}
      open={visible}
      overlayClassName={cx(overlayClassName, style.tooltip)}
      defaultOpen={defaultOpen}
      {...tooltipProps}
    >
      <div className={cx('w-fit flex justify-center items-center', className)}>
        {children}
      </div>
    </Tooltip>
  );
};

export default CustomTooltip;
