import { SLIDER_MIN } from '@/constants';

export const SLIDER_MAX_FUND_SIZE = 96;
export const STEP_FUND_SIZE = 16;

export const generateFundSizeMarks = (symbol?: string) => {
  return {
    0: `${symbol || ''}0m`,
    16: `${symbol || ''}50m`,
    32: `${symbol || ''}100m`,
    48: `${symbol || ''}250m`,
    64: `${symbol || ''}500m`,
    80: `${symbol || ''}1bn`,
    96: `${symbol || ''}1.5bn>`,
  };
};

export interface FundSizeState {
  fundSizeRange?: number[];
}

export const fundSizeInitialState = {
  fundSizeRange: [SLIDER_MIN, SLIDER_MAX_FUND_SIZE],
} as const;

interface fundSizeMap {
  [key: number]: number;
}
export const convertFundSize: fundSizeMap = {
  0: 0,
  16: 50000000,
  32: 100000000,
  48: 250000000,
  64: 500000000,
  80: 1000000000,
  96: Number.MAX_SAFE_INTEGER,
};

export function getFundSizeFromValues(values: number[]): number[] {
  const getKeyFromValue = (value: number): number => {
    const entry = Object.entries(convertFundSize).find(
      ([_, val]) => val === value,
    );
    return entry ? Number(entry[0]) : 0; // Default to 0 if no match is found
  };

  const [value1, value2] = values;
  const key1 = getKeyFromValue(value1);
  const key2 = getKeyFromValue(value2);

  return [key1, key2];
}
