import {
  Bucket,
  PageQueryParametersSortDirectionEnum,
} from '@aminsights/contract';
import cx from 'classnames';
import * as _ from 'lodash';
import React, { useEffect, useRef, useState } from 'react';
import {
  DragDropContext,
  Draggable,
  Droppable,
  DropResult,
} from 'react-beautiful-dnd';

import { IDataTableColumns } from '../DataTable';
import { tableLoaderData } from '../DataTable/data';
import style from '../DataTable/style.module.less';
import TableHeader from '../DataTable/TableHeader';
import TableRow from '../DataTable/TableRow';
import DraggableTableRow from './DraggableTableRow';

interface Props {
  columns: Array<IDataTableColumns>;
  loading?: boolean;
  onSort?: (sortDirection: PageQueryParametersSortDirectionEnum) => void;
  uniqueKey: string;
  className?: string;
  loaderSize?: number;

  tableBodyClassName?: string;
  tableRowItemClassName?: string;
  lastElementRef?: (node: HTMLTableSectionElement) => void;
  tempItems: Bucket[];
  setTempItems: (buckets: Bucket[]) => void;
}

const getColumnAlignment = (
  renderType: IDataTableColumns['renderType'],
  alignment?: string,
) => {
  if (renderType === 'custom') {
    return alignment;
  }
  return renderType === 'number' ? 'right' : 'left';
};

const DraggableDataTable = ({
  columns,
  loading,
  onSort,
  uniqueKey,
  className,
  loaderSize,
  tableBodyClassName,
  tableRowItemClassName,
  lastElementRef,
  tempItems,
  setTempItems,
}: Props): React.ReactElement => {
  const [mapColumns, setMapColumns] = useState<Array<IDataTableColumns>>([]);

  const tableWrapperRef = useRef<HTMLDivElement>(null);
  const fixedColumnRef = useRef<HTMLTableCellElement>(null);
  const [currentSort, setCurrentSort] = useState<string | undefined>(undefined);

  const handleColumns = () => {
    setMapColumns(columns);
  };

  const handleOnDragEnd = (result: DropResult) => {
    if (!result.destination) {
      return;
    }

    const items = Array.from(tempItems);
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);

    setTempItems(items);
  };

  useEffect(() => {
    handleColumns();
  }, [columns]);

  return (
    <div className={cx('w-full overflow-hidden relative')}>
      <div
        id="data-table"
        ref={tableWrapperRef}
        className={cx('w-full overflow-auto', {
          [style['table-wrapper']]: !!lastElementRef,
        })}
        data-test-id="dataTable"
      >
        <table
          className={cx(
            'w-full overflow-auto table-fixed border-x-[1px] border-grey-lighter',
            style['table-custom'],
            className,
          )}
        >
          <thead className={cx('h-10 bg-grey-lighter')}>
            <tr>
              {mapColumns.map(column => (
                <TableHeader
                  ref={column?.isColumnFixed ? fixedColumnRef : undefined}
                  key={
                    !!column.idSubText
                      ? `table-row-subheader-wrapper-${column.title}-${
                          column.idSubText ?? 'end'
                        }`
                      : `table-row-subheader-wrapper-${column.title}`
                  }
                  isSortable={Boolean(column?.sortKey)}
                  title={column.title}
                  width={column?.width}
                  className={column?.className}
                  align={getColumnAlignment(column.renderType, column.align)}
                  colSpan={column?.children?.length || 1}
                  isFixed={column?.isColumnFixed}
                  type={column?.type}
                  tooltipText={column?.tooltipText}
                  hasAction={false}
                  onSort={(
                    sortDirection: PageQueryParametersSortDirectionEnum,
                  ) => {
                    if (onSort && Boolean(column?.sortKey)) {
                      onSort(sortDirection);
                      setCurrentSort(column.sortKey);
                    }
                  }}
                  loading={loading}
                  isSorting={currentSort === column.sortKey}
                  minWidth={column?.minWidth}
                  headerCheckbox={
                    column.headerCheckbox && column.headerCheckbox()
                  }
                />
              ))}
            </tr>
          </thead>
          {loading && (
            <tbody className={tableBodyClassName}>
              {tableLoaderData.slice(0, loaderSize).map(key => (
                <TableRow
                  key={`table-row-loading-${key}`}
                  uniqueKey={`${key}`}
                  item={null}
                  columns={mapColumns}
                  loading={loading}
                  hasAction={false}
                />
              ))}
            </tbody>
          )}
          {!loading && (
            <DragDropContext onDragEnd={handleOnDragEnd}>
              <Droppable droppableId="droppableTable">
                {provided => (
                  <tbody
                    className={tableBodyClassName}
                    ref={provided.innerRef}
                    {...provided.droppableProps}
                  >
                    {tempItems.map((item, index) => {
                      let key = '';
                      const uniqueKeys = uniqueKey.split(' || ');
                      uniqueKeys.forEach(k => {
                        const keyToSearchFor = k.trim();
                        const tempValue = _.get(item, keyToSearchFor);
                        if (tempValue) {
                          key = tempValue;
                        }
                      });

                      return (
                        <Draggable
                          key={`table-row-${key}`}
                          draggableId={item.id}
                          index={index}
                        >
                          {(provided, snapshot) => (
                            <>
                              <DraggableTableRow
                                draggableProps={provided.draggableProps}
                                dragHandleProps={
                                  provided.dragHandleProps || undefined
                                }
                                provided={provided}
                                id={`table-row-${key}`}
                                uniqueKey={key}
                                item={item}
                                columns={mapColumns}
                                className={tableRowItemClassName}
                                isDragging={snapshot.isDragging}
                              />
                            </>
                          )}
                        </Draggable>
                      );
                    })}
                    {provided.placeholder}
                  </tbody>
                )}
              </Droppable>
            </DragDropContext>
          )}
          {lastElementRef && (
            <tfoot className={style['last-elementRef']} ref={lastElementRef} />
          )}
        </table>
      </div>
    </div>
  );
};

export default DraggableDataTable;
