// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".TcK6kEmUtu0McNooTktg {\n  display: flex;\n  flex-direction: column;\n}\n@media (min-width: 576px) {\n  .TcK6kEmUtu0McNooTktg {\n    flex-direction: row;\n    align-items: center;\n    justify-content: space-between;\n  }\n}\n.TcK6kEmUtu0McNooTktg {\n  margin-top: 0.5rem;\n  margin-bottom: 0.5rem;\n}\n.TcK6kEmUtu0McNooTktg .dropdown {\n  width: 100%;\n  padding-top: 1rem;\n}\n@media (min-width: 768px) {\n  .TcK6kEmUtu0McNooTktg .dropdown {\n    width: auto;\n    padding-top: 0px;\n  }\n}\n.xh0LETLdad47Csw6s5NJ {\n  display: flex;\n  flex-direction: row;\n}\n.HF9mnzu0oLxxdrwOcp6U.dropdown-button {\n  width: 100%;\n}\n@media (min-width: 768px) {\n  .HF9mnzu0oLxxdrwOcp6U.dropdown-button {\n    width: 9.5rem;\n  }\n}\n.rz_j9JN_LGROt1NkiI7w {\n  font-weight: 300;\n}\n.Ir3IhZkQ465L8JXGJy2V {\n  margin: 30px 0 30px;\n  border-bottom: 1px dashed #ccc;\n}\n", "",{"version":3,"sources":["webpack://./src/partials/Charts/relative/style.module.less"],"names":[],"mappings":"AACE;EAAA,aAAA;EAAA;AAAA;AAAA;EAAA;IAAA,mBAAA;IAAA,mBAAA;IAAA;EAAA;AAAA;AACA;EAAA,kBAAA;EAAA;AAAA;AAGI;EAAA,WAAA;EAAA;AAAA;AAAA;EAAA;IAAA,WAAA;IAAA;EAAA;AAAA;AAMJ;EAAA,aAAA;EAAA;AAAA;AAMI;EAAA;AAAA;AAAA;EAAA;IAAA;EAAA;AAAA;AAKN;EACE,gBAAA;AARF;AAWA;EACE,mBAAA;EACA,8BAAA;AATF","sourcesContent":[".header {\n  @apply flex flex-col sm:flex-row sm:items-center sm:justify-between;\n  @apply my-2;\n  :global {\n    .dropdown {\n      @apply pt-4 w-full md:w-auto md:pt-0;\n    }\n  }\n}\n\n.subHeader {\n  @apply flex flex-row;\n}\n\n:global {\n  :local(.dropdown) {\n    &.dropdown-button {\n      @apply w-full md:w-38;\n    }\n  }\n}\n\n.benchmarkTitle {\n  font-weight: 300;\n}\n\n.divider {\n  margin: 30px 0 30px;\n  border-bottom: 1px dashed #ccc;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"header": "TcK6kEmUtu0McNooTktg",
	"subHeader": "xh0LETLdad47Csw6s5NJ",
	"dropdown": "HF9mnzu0oLxxdrwOcp6U",
	"benchmarkTitle": "rz_j9JN_LGROt1NkiI7w",
	"divider": "Ir3IhZkQ465L8JXGJy2V"
};
export default ___CSS_LOADER_EXPORT___;
