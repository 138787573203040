import { FundBroadAssetClass } from '@aminsights/contract';
import { PLATFORMS_FILTERS } from '@aminsights/shared';
import { createRef, useCallback, useMemo } from 'react';

import Accordion, { Panel } from '@/components/Accordion';

import { useExploreDefaultFiltersQuery } from '../context';
import { noOfHoldingMarks, yieldMarks } from '../hooks/moreFiltersSubState';
import useExploreFilters from '../hooks/useExploreFilters';
import { getChangedMarkValues } from '../utils/getChangedMarkValues';
import lengthOrEmpty from '../utils/lengthOrEmpty';
import {
  mapToOptions,
  mapToOptionsWithIndex,
} from '../utils/mapDefaultOptionsToListItems';
import MoreFiltersActivePassive from './IndividualFilters/MoreFilters/MoreFiltersActivePassive';
import MoreFiltersAssetClass from './IndividualFilters/MoreFilters/MoreFiltersAssetClass';
import MoreFiltersCurrencyHedged from './IndividualFilters/MoreFilters/MoreFiltersCurrencyHedged';
import MoreFiltersLegalStructure from './IndividualFilters/MoreFilters/MoreFiltersLegalStructure';
import MoreFiltersManagerGender from './IndividualFilters/MoreFilters/MoreFiltersManagerGender';
import MoreFiltersNumberOfHoldings from './IndividualFilters/MoreFilters/MoreFiltersNumberOfHoldings';
import MoreFiltersOldestShareclass from './IndividualFilters/MoreFilters/MoreFiltersOldestShareclass';
import MoreFiltersSearchWithCheckboxes from './IndividualFilters/MoreFilters/MoreFiltersSearchWithCheckboxes';
import MoreFiltersYield from './IndividualFilters/MoreFilters/MoreFiltersYield';
import style from './style.module.less';

const MoreFiltersPanels = () => {
  const { tentativeFilters } = useExploreFilters();

  // Values from the server as options
  const { data } = useExploreDefaultFiltersQuery();

  const providerNames = useMemo(() => {
    return mapToOptionsWithIndex(data.providerNames);
  }, [data.providerNames]);

  const domiciles = useMemo(() => {
    return mapToOptions(data.domiciles);
  }, [data.domiciles]);

  const currencies = useMemo(() => {
    return mapToOptions(data.currencies);
  }, [data.currencies]);

  const platforms = useMemo(() => {
    return (
      data.platforms
        ?.map((platform: { value: string }) => {
          const platformFilter = PLATFORMS_FILTERS.find(
            item => item.key === platform.value,
          );
          return {
            label: platformFilter ? platformFilter.label : '',
            value: platformFilter ? platformFilter.key : '',
          };
        })
        .sort((a, b) => a.label.localeCompare(b.label)) || []
    );
  }, [data.platforms]);

  /* Adjust selected count when Alternative asset class is selected. */
  const adjustedSelectedBroadAssetClassesCount =
    tentativeFilters.moreFilters?.selectedBroadAssetClasses?.includes(
      FundBroadAssetClass.Alternative,
    )
      ? tentativeFilters.moreFilters.selectedBroadAssetClasses.length - 2
      : tentativeFilters.moreFilters?.selectedBroadAssetClasses?.length ?? 0;

  const scrollToCheckedCheckbox = useCallback((node: HTMLDivElement | null) => {
    if (node !== null) {
      setTimeout(() => {
        const firstCheckedCheckbox = node.querySelector(
          'input[type="checkbox"]:checked',
        );
        if (firstCheckedCheckbox) {
          firstCheckedCheckbox.scrollIntoView({
            behavior: 'smooth',
            block: 'nearest',
          });
        }
      }, 500);
    }
  }, []);
  const managementGroupRef = createRef<HTMLDivElement>();
  const domicileRef = createRef<HTMLDivElement>();
  const currencyRef = createRef<HTMLDivElement>();
  const platformRef = createRef<HTMLDivElement>();

  return (
    <div data-test-id={'moreFilters'} className="modal-more-filters">
      <Accordion
        defaultActiveKey="1"
        onChange={activeKey => {
          if (activeKey.includes('6')) {
            scrollToCheckedCheckbox(managementGroupRef.current);
          } else if (activeKey[0] === '9') {
            scrollToCheckedCheckbox(domicileRef.current);
          } else if (activeKey[0] === '10') {
            scrollToCheckedCheckbox(currencyRef.current);
          } else if (activeKey[0] === '12') {
            scrollToCheckedCheckbox(platformRef.current);
          }
        }}
      >
        <Panel
          header="Oldest Shareclass"
          dataTestId={'moreFiltersLabelOldestShareclass'}
          key="1"
          extra={
            <span>
              {tentativeFilters.moreFilters?.filterByOldestShareClassOnly
                ? `(1)`
                : ''}
            </span>
          }
        >
          <MoreFiltersOldestShareclass />
        </Panel>
        <Panel
          header="Number of Holdings"
          dataTestId={'moreFiltersLabelNumberOfHoldings'}
          key="2"
          extra={
            <span>
              {getChangedMarkValues(
                tentativeFilters.moreFilters?.noOfHoldingsRange,
                noOfHoldingMarks,
              )}
            </span>
          }
        >
          <MoreFiltersNumberOfHoldings />
        </Panel>
        <Panel
          header="Active/Passive"
          dataTestId={'moreFiltersLabelActive/Passive'}
          key="3"
          extra={
            <span>
              {lengthOrEmpty(
                tentativeFilters.moreFilters?.filterByActiveOrPassive,
              )}
            </span>
          }
        >
          <MoreFiltersActivePassive />
        </Panel>
        <Panel
          header="Manager Gender"
          dataTestId={'moreFiltersLabelManagerGender'}
          key="4"
          extra={
            <span>{lengthOrEmpty(tentativeFilters.moreFilters?.genders)}</span>
          }
        >
          <MoreFiltersManagerGender />
        </Panel>
        <Panel
          header="Yield"
          dataTestId={'moreFiltersLabelYield'}
          key="5"
          extra={
            <span>
              {getChangedMarkValues(
                tentativeFilters.moreFilters?.yieldRange,
                yieldMarks,
              )}
            </span>
          }
        >
          <MoreFiltersYield />
        </Panel>
        <Panel
          header="Management Group"
          dataTestId={'moreFiltersLabelManagementGroup'}
          key="6"
          extra={
            <span>
              {lengthOrEmpty(
                tentativeFilters.moreFilters?.selectedManagementGroup,
              )}
            </span>
          }
        >
          <MoreFiltersSearchWithCheckboxes
            filterKey="selectedManagementGroup"
            options={providerNames}
          />
        </Panel>
        <Panel
          header="Asset Class"
          dataTestId={'moreFiltersLabelAssetClass'}
          key="7"
          extra={
            <span>
              {adjustedSelectedBroadAssetClassesCount > 0
                ? `(${adjustedSelectedBroadAssetClassesCount})`
                : ''}
            </span>
          }
        >
          <MoreFiltersAssetClass />
        </Panel>
        <Panel
          header="Legal Structure"
          dataTestId={'moreFiltersLabelLegalStructure'}
          key="8"
          extra={
            <span>
              {lengthOrEmpty(tentativeFilters.moreFilters?.legalStructure)}
            </span>
          }
        >
          <MoreFiltersLegalStructure />
        </Panel>
        <Panel
          header="Domicile"
          dataTestId={'moreFiltersLabelDomicile'}
          key="9"
          extra={
            <span>
              {lengthOrEmpty(tentativeFilters.moreFilters?.selectedDomiciles)}
            </span>
          }
        >
          <MoreFiltersSearchWithCheckboxes
            filterKey="selectedDomiciles"
            options={domiciles}
          />
        </Panel>
        <Panel
          header="Currency"
          dataTestId={'moreFiltersLabelCurrencies'}
          key="10"
          extra={
            <span>
              {lengthOrEmpty(tentativeFilters.moreFilters?.selectedCurrencies)}
            </span>
          }
        >
          <MoreFiltersSearchWithCheckboxes
            filterKey="selectedCurrencies"
            options={currencies}
          />
        </Panel>
        <Panel
          header="Currency Hedged"
          dataTestId={'moreFiltersLabelCurrencyHedged'}
          key="11"
          extra={
            <span>
              {lengthOrEmpty(tentativeFilters.moreFilters?.currencyHedgedType)}
            </span>
          }
        >
          <MoreFiltersCurrencyHedged />
        </Panel>
        <Panel
          header="Platform Availability"
          dataTestId={'moreFiltersLabelPlatformAvailability'}
          key="12"
          extra={
            <span>
              {lengthOrEmpty(tentativeFilters.moreFilters?.selectedPlatforms)}
            </span>
          }
        >
          <MoreFiltersSearchWithCheckboxes
            filterKey="selectedPlatforms"
            options={platforms}
          />
          <div className={style['platform-note-wrapper']}>
            <p className={style['platform-note-title']}>Note:</p>
            <p className={style['platform-note-text']}>
              These checkboxes are AND conditions <br />
              (i.e. a fund must be present on Wealthtime AND Parmenion)
            </p>
          </div>
        </Panel>
      </Accordion>
    </div>
  );
};

export default MoreFiltersPanels;
