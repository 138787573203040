import { APP_ROUTE_WATCHLIST, WATCHLIST_TAB_KEY } from '@aminsights/shared';
import { LoadingOutlined } from '@ant-design/icons';
import { Tabs } from 'antd';
import cx from 'classnames';
import React, {
  createContext,
  Dispatch,
  SetStateAction,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useParams } from 'react-router-dom';

import { useFeatureSwitchContext } from '@/context/FeatureSwitchContext';
import useTabsToShowOnWatchlist from '@/hooks/query-hooks/watchlist-hooks/useTabsToShowOnWatchlist';
import {
  useCurrentBucketId,
  useCurrentWatchlist,
} from '@/hooks/query-hooks/watchlist-hooks/useWatchlists';

import {
  getWatchlistTabItems,
  WATCHLIST_TAB_GROUP,
  watchlistTabGroup,
  watchlistTabGroupMappings,
} from '../WatchlistTabItems';
import { EmptyTab } from './components/EmptyTab';
import { Heading } from './components/Heading';
import style from './style.module.less';

type TabContextType = {
  activeTab: WATCHLIST_TAB_KEY | WATCHLIST_TAB_GROUP;
  setActiveTab: Dispatch<
    SetStateAction<WATCHLIST_TAB_KEY | WATCHLIST_TAB_GROUP>
  >;
  activeTabMenuItem?: WATCHLIST_TAB_KEY;
  setActiveTabMenuItem: Dispatch<SetStateAction<WATCHLIST_TAB_KEY | undefined>>;
};

export const TabContext = createContext<TabContextType>({
  activeTab: WATCHLIST_TAB_KEY.SUMMARY,
  setActiveTab: () => {
    undefined;
  },
  setActiveTabMenuItem: () => {},
});

const WatchlistTabWrapper: React.FC = () => {
  const currentWatchlist = useCurrentWatchlist();
  const currentBucketId = useCurrentBucketId();
  const { state: featureSwitchState } = useFeatureSwitchContext();

  const [activeTab, setActiveTab] = useState<
    WATCHLIST_TAB_KEY | WATCHLIST_TAB_GROUP
  >(WATCHLIST_TAB_KEY.SUMMARY);
  // Used for grouped tabs with dropdown
  const [activeTabMenuItem, setActiveTabMenuItem] = useState<
    WATCHLIST_TAB_KEY | undefined
  >();
  const { slug } = useParams<{ slug: string }>();
  const isAllBucketsSelected = useMemo(() => {
    return !slug || slug.toLowerCase() === 'all';
  }, [slug]);

  const { data: tabsToShow, isLoading: isTabsToShowLoading } =
    useTabsToShowOnWatchlist(isAllBucketsSelected ? 'all' : currentBucketId);

  const currentBucket = currentWatchlist.data?.buckets.find(
    b => b.id === currentBucketId,
  );

  const tabItems = useMemo(() => {
    const tabs = getWatchlistTabItems(activeTab).filter(
      tabItem =>
        tabsToShow?.includes(tabItem.key) ||
        (watchlistTabGroup.includes(tabItem.key as WATCHLIST_TAB_GROUP) &&
          watchlistTabGroupMappings[tabItem.key as WATCHLIST_TAB_GROUP]?.some(
            groupedItem => tabsToShow?.includes(groupedItem),
          )),
    );

    return tabs;
  }, [tabsToShow, activeTab, featureSwitchState]);

  useEffect(() => {
    // Default to true if item is not in a group
    const activeTabInGroup =
      !watchlistTabGroup.includes(activeTab as WATCHLIST_TAB_GROUP) ||
      tabsToShow?.includes(activeTabMenuItem as WATCHLIST_TAB_KEY);
    const activeTabInItemsToShow = tabItems.some(t => t.key === activeTab);
    if (
      (!activeTabInItemsToShow || !activeTabInGroup) &&
      !isTabsToShowLoading
    ) {
      setActiveTab(WATCHLIST_TAB_KEY.SUMMARY);
      setActiveTabMenuItem(undefined);
    }
  }, [tabItems, isTabsToShowLoading]);

  if (currentBucket?.funds.length === 0) {
    return (
      <EmptyTab
        title="No funds in this bucket"
        subTitle="Add funds to your bucket"
        redirectLink={`/${APP_ROUTE_WATCHLIST}/manage-buckets`}
      />
    );
  }
  if (
    isAllBucketsSelected &&
    currentWatchlist.data?.buckets.every(b => b.funds.length === 0)
  ) {
    return (
      <EmptyTab
        title="No added funds/trusts"
        subTitle="Add funds to your watchlist"
        redirectLink={`/${APP_ROUTE_WATCHLIST}/manage-buckets`}
      />
    );
  }
  const tabsWithoutPageResults = [
    WATCHLIST_TAB_KEY.CHARTS,
    WATCHLIST_TAB_KEY.STYLE,
    WATCHLIST_TAB_KEY.CORRELATION_MATRIX,
    WATCHLIST_TAB_GROUP.EQUITY,
    WATCHLIST_TAB_GROUP.FIXED_INCOME,
    WATCHLIST_TAB_GROUP.PERF_AND_RISK,
  ];
  return (
    <div className={cx(style['watchlist-body'], 'lg:rounded-lg')}>
      <Heading
        isAllBucketsSelected={isAllBucketsSelected}
        bucketIndex={currentBucket?.index}
        bucketName={currentBucket?.name}
      />
      <div
        className={cx(style['watchlist-tabs'], 'relative', 'watchlist-tabs')}
      >
        <TabContext.Provider
          value={{
            activeTab,
            setActiveTab,
            activeTabMenuItem,
            setActiveTabMenuItem,
          }}
        >
          <Tabs
            activeKey={activeTab}
            onChange={(tab: string) => {
              if (
                Object.values(WATCHLIST_TAB_GROUP).includes(
                  tab as WATCHLIST_TAB_GROUP,
                )
              ) {
                return;
              }
              setActiveTabMenuItem(undefined);
              setActiveTab(tab as WATCHLIST_TAB_KEY);
            }}
            tabBarExtraContent={
              tabsWithoutPageResults.includes(activeTab) ? (
                <></>
              ) : (
                <div id="total-isins-portal"></div>
              )
            }
            //tabBarStyle={{ paddingLeft: 12, paddingRight: 12 }}
            defaultActiveKey={WATCHLIST_TAB_KEY.SUMMARY}
            items={tabItems}
          />
        </TabContext.Provider>

        {isTabsToShowLoading && (
          <LoadingOutlined
            className="py-2"
            style={{ fontSize: 60, color: '#f0f2f5', width: '98%' }}
            spin
          />
        )}
      </div>
    </div>
  );
};

export default WatchlistTabWrapper;
