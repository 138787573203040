import { PageQueryParametersSortDirectionEnum } from '@aminsights/contract';
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

import Button from '@/components/Button';
import DraggableDataTable from '@/components/Table/DraggableTable';
import { APP_ACTIONS } from '@/constants';
import { useAppContext } from '@/context/AppContext';
import { useChangeBucketsOrder } from '@/hooks/query-hooks/bucket-hooks/useManageBuckets';
import { useCurrentWatchlist } from '@/hooks/query-hooks/watchlist-hooks/useWatchlists';
import BasePageWithMetadata from '@/pages/app/BasePageWithMetadata';
import SectionBackButton from '@/partials/Sections/SectionBackButton';

import { ChangeBucketOrderColumns } from './ChangeBucketOrderColumns';

const ChangeBucketOrder: React.FC = () => {
  const history = useHistory();
  const currentWatchlist = useCurrentWatchlist();
  const changeBucketsOrder = useChangeBucketsOrder();
  const { dispatch } = useAppContext();

  const { buckets, watchlist } = currentWatchlist.data ?? {};
  const [tempItems, setTempItems] = useState(buckets);

  useEffect(() => {
    if (currentWatchlist.isLoading && buckets && buckets.length === 0) {
      history.push('/watchlist/manage-buckets');
      return;
    }
    setTempItems(buckets);
  }, [currentWatchlist.isLoading]);

  const columnDefinitions = ChangeBucketOrderColumns();

  if (!tempItems || !watchlist) {
    return null;
  }

  const disableSaveButton = () => {
    // Check if both arrays have the same length
    if (buckets?.length !== tempItems.length) {
      return false;
    }
    for (let i = 0; i < buckets.length; i++) {
      if (buckets[i].name !== tempItems[i].name) {
        return false;
      }
    }
    return true;
  };

  return (
    <>
      <BasePageWithMetadata title="Change bucket order">
        <SectionBackButton previousLabel="Back" />
        <div className="w-auto lg:p-4 max-sm:min-h-screen">
          <div className="bg-white lg:rounded-lg p-4">
            <h3 className="text-xl font-bold text-darkest pb-2 select-none">
              Change bucket order
            </h3>
            <p className="text-neutral-700 font-normal pb-6 sm:pb-4">
              You can move the order of buckets by dragging and placing it on
              the desired spot.
            </p>
            <DraggableDataTable
              uniqueKey={'name'}
              columns={columnDefinitions}
              tempItems={tempItems}
              setTempItems={setTempItems}
              onSort={async (
                direction: PageQueryParametersSortDirectionEnum,
              ) => {
                const sortedItems = [...tempItems].sort((a, b) => {
                  if (direction === 'asc') {
                    return a.funds.length - b.funds.length;
                  } else {
                    return b.funds.length - a.funds.length;
                  }
                });
                setTempItems(sortedItems);
              }}
              tableBodyClassName={'table-fixed'}
              tableRowItemClassName="relative"
              className="w-full"
            />
            <div className="flex flex-col-reverse gap-2 sm:flex-row w-full sm:justify-end pt-4">
              <Button
                size="large"
                type="link"
                key="secondary"
                onClick={() => history.goBack()}
              >
                Cancel
              </Button>
              <Button
                size="large"
                type="primary"
                key="primary"
                disabled={disableSaveButton()}
                onClick={async () => {
                  try {
                    await changeBucketsOrder.mutateAsync({
                      bucketIds: tempItems.map(item => item.id),
                      watchlistId: watchlist._id,
                    });
                    dispatch({
                      type: APP_ACTIONS.SET_SUCCESS_MESSAGE,
                      payload: { text: 'Successfully changed bucket order' },
                    });
                    history.goBack();
                  } catch (error) {
                    dispatch({
                      type: APP_ACTIONS.SET_ERROR_MESSAGE,
                      payload: 'Bucket order not changed',
                    });
                  }
                }}
              >
                Save
              </Button>
            </div>
          </div>
        </div>
      </BasePageWithMetadata>
    </>
  );
};

export default ChangeBucketOrder;
