// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".kBv0mG4hXDALrc6zQZuW {\n  color: rgb(255 255 255 / var(--tw-text-opacity));\n  transform: rotate(90deg);\n  height: 0.75rem;\n  width: 0.75rem;\n  fill: currentColor;\n  --tw-text-opacity: 1;\n  color: rgba(84, 85, 118, var(--tw-text-opacity));\n  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);\n}\n.VXvstFlpaWQNiGaaesaX {\n  column-gap: 10px;\n}\n.VXvstFlpaWQNiGaaesaX:hover {\n  color: #0072e6;\n}\n.KINIdvC2cUdyQz_i_t9m {\n  color: #0072e6;\n}\n.CrjChek5G7oQPr9WA5Gr {\n  padding: 8px 8px;\n}\n.CrjChek5G7oQPr9WA5Gr.D5Sc7I8hCKPnRSsLwi3H .VXvstFlpaWQNiGaaesaX {\n  color: #0072e6;\n  font-weight: 600;\n  height: 34px;\n  padding-top: 2px;\n  padding-left: 8px;\n  padding-right: 8px;\n}\n", "",{"version":3,"sources":["webpack://./src/pages/app/Watchlist/Group/style.module.less"],"names":[],"mappings":"AACE;EAAA,gDAAA;EACA,wBAAA;EACA,eAAA;EACA,cAAA;EACA,kBAAA;EACA,oBAAA;EACA,gDAAA;EACA;AAPA;AAUF;EACE,gBAAA;AAAF;AACE;EACE,cAAA;AACJ;AAGA;EACE,cAAA;AADF;AAIA;EACE,gBAAA;AAFF;AAKA;EAEI,cAAA;EACA,gBAAA;EACA,YAAA;EACA,gBAAA;EACA,iBAAA;EACA,kBAAA;AAJJ","sourcesContent":[".button-search-dropdown-caret {\n  @apply h-3 w-3 fill-current text-white;\n  transform: rotate(90deg);\n  height: 0.75rem;\n  width: 0.75rem;\n  fill: currentColor;\n  --tw-text-opacity: 1;\n  color: rgba(84, 85, 118, var(--tw-text-opacity));\n  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);\n}\n\n.tabLabel {\n  column-gap: 10px;\n  &:hover {\n    color: #0072e6;\n  }\n}\n\n.activeLabel {\n  color: #0072e6;\n}\n\n.watchlist-dropdown {\n  padding: 8px 8px;\n}\n\n.watchlist-dropdown.active-item {\n  .tabLabel {\n    color: #0072e6;\n    font-weight: 600;\n    height: 34px;\n    padding-top: 2px;\n    padding-left: 8px;\n    padding-right: 8px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"button-search-dropdown-caret": "kBv0mG4hXDALrc6zQZuW",
	"tabLabel": "VXvstFlpaWQNiGaaesaX",
	"activeLabel": "KINIdvC2cUdyQz_i_t9m",
	"watchlist-dropdown": "CrjChek5G7oQPr9WA5Gr",
	"active-item": "D5Sc7I8hCKPnRSsLwi3H"
};
export default ___CSS_LOADER_EXPORT___;
